<template>
  <template-fullscreen>
    <login-form
      :external-error="error"
    />
  </template-fullscreen>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import TemplateFullscreen from '@theme/router/views/templates/TemplateFullscreen';
import LoginForm from '@components/LoginForm/LoginForm';

export default {
  name: 'Login',
  components: {
    TemplateFullscreen,
    LoginForm
  },
  computed: {
    ...mapState({
      redirected: state => state.user.redirected
    }),
    ...mapGetters({
      error: 'user/getError'
    })
  },
  watch: {
    redirected() {
      this.$notify({
        text: this.$t('general.redirection_msg'),
        data: {
          type: 'warning'
        }
      });
    }
  },
  mounted() {
    let notificationType = null;
    let notificationText = null;

    if (Object.keys(this.$route.params).length) {
      if (this.$route.params.redirectedFromInvitation) {
        notificationType = 'success';
        notificationText = this.$t('general.redirection_invite_msg');
      } else if (this.$route.params.redirectedFromPasswordForgotten) {
        notificationType = 'success';
        notificationText = this.$t('general.redirection_msg_password_forgotten');
      } else if (this.$route.params.redirectedFromExpiredInvitation) {
        notificationType = 'warning';
        notificationText = this.$t('general.redirection_expired_invite_msg');
      }
    } else if (this.redirected) {
      notificationType = 'warning';
      notificationText = this.$t('general.redirection_msg');
    }

    if (notificationType) {
      this.$notify({
        text: notificationText,
        data: {
          type: notificationType
        }
      });
    }
  }
};
</script>
