import api from '@core/api/';
import { set, toggle } from '@core/store/utils';

const state = {
  items: []
};

const actions = {
  /**
  * Get FAQs
  * @method getFaqs
  */
  getFaqs({ commit }) {
    commit('toggleLoading');

    api.getApiData('/faqs').then(({ data }) => {
      if (data && data.data && data.data) {
        commit('setItems', data.data);
      }
      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      commit('toggleLoading');
    });
  }
};

const mutations = {
  setItems: set('items'),
  toggleLoading: toggle('isLoading')
};

export default {
  namespaced: true,

  state,
  actions,
  mutations
};
