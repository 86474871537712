import api from '@core/api/';
import { set, toggle } from '@core/store/utils';

const state = {
  isLoading: false,
  items: [],
  errors: []
};

const getters = {
  getItems: state => state.items
};

const actions = {
  /**
  * get api data and save it to fields
  * @method getCurrencies
  */
  getCurrencies({ commit }) {
    commit('toggleLoading');

    api.getApiData('enums/currencies').then(({ data }) => {
      commit('setCurrencies', data.data);
      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
    });
  }
};

const mutations = {
  toggleLoading: toggle('isLoading'),
  setCurrencies: set('items')
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
