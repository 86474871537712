import Vue from 'vue';
import Router from 'vue-router';

// Custom Theme
import routes from '@theme/router/';

// https://medium.com/hypefactors/add-i18n-and-manage-translations-of-a-vue-js-powered-website-73b4511ca69c

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
});

export default router;
