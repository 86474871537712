<template>
  <router-view id="app"/>
</template>

<script>
import cookies from '@theme/utils/cookies';
import { isPublicRoute } from '@theme/utils/router';

export default {
  name: 'GuaranteeTheme',
  mounted() {
    if (cookies.hasCookie('access_token')) {
      // Is supposed user was already loaded (see main.js)
      this.$store.dispatch('guarantee/create/setUserCred');
      this.$user.setPermissions(this.$store.getters['user/getPermissions']);
    } else if (!isPublicRoute(this.$route.name)) {
      this.$router.push({ name: 'login', query: { redirected: window.location.pathname } });
    }
  }
};
</script>
