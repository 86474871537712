<template>
  <validation-observer ref="multistep" v-slot="{ handleSubmit }">
    <b-form
      novalidate
      autocomplete="off"
      class="content"
      @submit.prevent="handleSubmit(submit)"
      @reset.prevent="reset"
    >
      <div :class="['multistep', 'container-fluid', mainContainerClass]">
        <div class="row">
          <div class="col-md-3">
            <slot name="navigation" />
          </div>
          <div class="col-md-9">
            <preloader
              v-show="isLoading"
              show-overlay
            />

            <!-- @slot insert fieldset with form fields -->
            <slot />

            <div
              v-if="!additional"
              class="multistep__btn-row d-flex flex-row flex-nowrap justify-content-between"
            >
              <btn
                ref="btnreset"
                v-if="![0, 5].includes(index)"
                :disabled="disableReset"
                type="reset"
                variant="secondary"
                class="multistep__btn-back"
                data-testing="button_step_previous"
                v-text="$t('button.back')"
              />
              <btn
                ref="btnsubmit"
                v-if="index !== 5"
                :disabled="isLoading || disableSubmit"
                type="submit"
                variant="primary"
                class="multistep__btn-forward"
                data-testing="button_step_next"
                v-text="$t('button.forward')"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="additional"
        v-show="!isLoading"
        class="multistep__additional-row container-fluid"
      >
        <div class="row">
          <div class="col-md-9 offset-md-3">
            <!-- @slot insert content in additional part of the form (different bg-color) -->
            <slot name="additionalRow" />

            <btn
              :disabled="disableSubmit"
              type="submit"
              variant="primary"
              class="multistep__btn-forward"
              data-testing="button_step_next"
              v-text="$t('button.check_guarantee')"
            />

            <div
              v-if="comments"
              class="multistep__comments-row"
            >
            <!-- @slot space for the comments section -->
              <slot name="commentsRow" />
            </div>

            <notification
              v-if="message.length"
              :text="message"
              type="msg"
              status="error"
            />
          </div>
        </div>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm } from 'bootstrap-vue';
import { mapState } from 'vuex';
import Notification from '@components/Notification/Notification';

/**
 * Multistep Form Base Setup
 */
export default {
  name: 'Multistep',
  components: {
    'b-form': BForm,
    Notification
  },
  props: {
    /*
     * Index of current step
     */
    index: {
      type: Number,
      required: true
    },
    /*
     * Insert additional area with single btn
     */
    additional: {
      type: Boolean,
      default: false
    },
    /*
     * Disable the submit button (e.g. initially)
     */
    disableSubmit: {
      type: Boolean,
      default: false
    },
    /**
     * Disable the reset button.
     */
    disableReset: {
      type: Boolean,
      default: false
    },
    /*
     * Insert comments area
     */
    comments: {
      type: Boolean,
      default: false
    },
    /**
     * Shows errors in component.
     */
    message: {
      type: String,
      default: ''
    },
    /**
     * Main container classes
     */
    mainContainerClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      isLoadingData: state => state.guarantee.create.isLoading,
      isLoadingMedia: state => state.guarantee.media.isLoading,
      isLoadingCountries: state => state.guarantee.countries.isLoading
    }),

    isLoading() {
      return (
        this.isLoadingData || this.isLoadingMedia || this.isLoadingCountries
      );
    }
  },
  mounted() {
    this.$parent.$on('clearErrors', this.clearErrors);
  },
  methods: {
    submit(event) {
      /**
       * Emits submit event
       * @event submit
       */
      this.$emit('submit');
    },
    reset() {
      /**
       * emit reset event
       * @event reset
       * @type {reset}
       */
      this.$emit('reset');
    },
    /**
     * clear all validation erros
     * @method clearErrors
     */
    clearErrors() {
      this.$refs.multistep.reset();
    }
  }
};
</script>

<style lang='scss'>
@import "./Multistep";
</style>
