import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Locale2 from 'locale2';
import { localize } from 'vee-validate';

import deDE from '@theme/locales/deDE.json';
import deCH from '@theme/locales/deCH.json';
import deAT from '@theme/locales/deAT.json';

import { getItemLocalStorage, setItemLocalStorage } from '@theme/utils/localstorage';

Vue.use(VueI18n);

const browserLocale = Locale2.length === 2 ? Locale2 + '-' + Locale2.toUpperCase() : Locale2;
const tryLocale = getItemLocalStorage('currentLocale') || browserLocale;
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de-AT';
const allLocales = ['de-DE', 'de-CH', 'de-AT'];
const validCurrentLocale = allLocales.indexOf(tryLocale) > -1 ? tryLocale : fallbackLocale;

const i18n = new VueI18n({
  locale: tryLocale,
  fallbackLocale: fallbackLocale,
  messages: {
    'de-DE': deDE,
    'de-AT': deAT,
    'de-CH': deCH
  },
  silentTranslationWarn: false
});

/**
  * set locale in plugin and also on html tag
  * @method setI18nLanguage
  * @param {string} lang - language to be set
*/
const setI18nLanguage = (lang) => {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  setItemLocalStorage('currentLocale', lang);
  localize(lang);
};

export {
  i18n,
  setI18nLanguage,
  validCurrentLocale,
  fallbackLocale,
  allLocales
};
