<template>
  <div class="multistep--model-text">
    <legend class="multistep__legend">
      <slot />
    </legend>

    <tabbed-pane
      ref="tabbedPane"
      :items="tabbedPaneItems"
    >
      <div
        slot="modelTextUpload"
        class="multistep__file-upload"
      >
        <file-upload
          :access-token="accessToken"
          :upload-url="uploadUrl"
          :errors="uploadErrors"
          collection="model_texts"
          data-testing="upload_model_texts"
          @error="onFileUploadError"
          @filesAdded="onFilesAdded"
        >
          <div
            slot="info"
            class="file-upload__info"
          >
            <span v-text="$t('file_upload.validation.max_size')" />
            <span v-text="$t('file_upload.validation.valid_types')" />
          </div>
        </file-upload>
      </div>

      <div
        slot="modelTextExisting"
        class="multistep__files-existing"
      >
        <table-interactive
          :total-records="existingTemplatesTotal"
          :columns="existingFilesColumns"
          :rows="existingTemplates"
          :search-options="existingFilesSearchOptions"
          :mode="null"
          max-height="300px"
          fixed-header
          class="multistep__table-interactive"
          @choose="onExistingFileChosen"
        />
      </div>
      <div
        slot="modelTextStandard"
        class="multistep__standard-model-text"
      >
        <form-checkbox
          id="useStandardModelText"
          :value="defaultModelText"
          class="multistep__standard-model-text--checkbox"
          data-testing="checkbox_default_model_text"
          @onchange="onUseStandardModelTextChange"
        >
          <span v-html="$t('file_upload.standard_model_text_msg')" />
        </form-checkbox>
      </div>
    </tabbed-pane>

    <div
      v-if="errorBag.length"
      class="multistep__standard-model-text--error"
      v-text="$t(errorBag[0].message)"
    />
    <div class="multistep__display-files">
      <h4
        v-show="hasAttachments"
        class="multistep__display-files--title"
        v-text="$t('file_upload.attachments')"
      />
      <notification
        v-for="(file, index) in files"
        :key="index"
        :text="file.label"
        :title="formatAttachmentLabel(index + 1)"
        removable
        type="file"
        status="default"
        class="multistep__display-files--file"
        @remove="onFileDelete(file.id, file.hash)"
      />
    </div>
    <div ref="documentUploadPanelEndSpacer"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TabbedPane from '@components/TabbedPane/TabbedPane';
import FileUpload from '@components/FileUpload/FileUpload';
import TableInteractive from '@components/TableInteractive/TableInteractive';
import Notification from '@components/Notification/Notification';
import FormCheckbox from '@components/FormCheckbox/FormCheckbox';

export default {
  name: 'MultistepModelText',
  components: {
    TabbedPane,
    FileUpload,
    TableInteractive,
    Notification,
    FormCheckbox
  },
  data() {
    return {
      existingFilesSearchOptions: {
        enabled: true,
        searchFn: this.existingFilesSearchFn,
        placeholder: this.$t('file_upload.search_for_file_name')
      },
      existingFilesColumns: [
        { field: 'file_name', label: 'file_upload.table.file_name', typeDef: Object, thClass: 'sorting' },
        { field: 'created_at', label: 'file_upload.table.created_at', typeDef: Object, thClass: 'sorting' },
        { field: 'choose', label: '', typeDef: Object, sortable: false }
      ],
      uploadErrors: []
    };
  },
  computed: {
    ...mapGetters({
      activeFieldsKey: 'guarantee/create/activeFieldsKey',
      activeFields: 'guarantee/create/activeFields'
    }),
    ...mapState({
      accessToken: state => state.user.accessToken,
      guaranteeId: state => state.guarantee.create.guaranteeId,
      isLoadingMedia: state => state.guarantee.media.isLoading,
      isLoadingFields: state => state.guarantee.create.isLoading,
      files: state => state.guarantee.media.templates,
      defaultModelText: state => state.guarantee.media.defaultModelText,
      existingTemplatesTotal: state => state.guarantee.media.existingTemplatesTotal,
      existingTemplates: state => state.guarantee.media.existingTemplates,
      errorBag: state => state.guarantee.media.errors
    }),
    tabbedPaneItems() {
      return [
        {
          label: this.$t('file_upload.standard_model_text'),
          id: 'modelTextStandard',
          disabled: false
        },
        {
          label: this.$t('file_upload.add_model_texts'),
          id: 'modelTextUpload',
          disabled: this.defaultModelText
        },
        {
          label: this.$t('file_upload.choose_existing_model_texts'),
          id: 'modelTextExisting',
          disabled: this.defaultModelText
        }
      ];
    },
    errors() {
      return this.mediaErrors.concat(this.uploadErrors);
    },
    uploadUrl() {
      return process.env.VUE_APP_API_URL + 'guarantees/' + this.guaranteeId + '/media';
    },
    hasAttachments() {
      let hasAttachments = false;

      if (this.files && this.files.length) {
        this.files.forEach((file) => {
          if (!file.deleted) {
            hasAttachments = true;
          }
        });
      }

      return hasAttachments;
    },
    isLoading() {
      return this.isLoadingMedia || this.isLoadingFields;
    }
  },
  mounted() {
    this.$store.dispatch('guarantee/create/getData').then(() => {
      this.validateSelection();
      this.$store.dispatch('guarantee/create/adjustFormNavigation');
    });
  },
  updated() {
    this.validateSelection();
  },
  methods: {
    /**
     * Scrolls to the end of the attachments
     */
    scrollToAttachments() {
      this.$nextTick().then(() => {
        const scrollHeight = this.$refs.documentUploadPanelEndSpacer.getBoundingClientRect().bottom + window.pageYOffset;
        const isIE = window.navigator.userAgent.indexOf('Trident/');

        isIE ? window.scrollTo(0, scrollHeight) : window.scrollTo({ left: 0, top: scrollHeight, behavior: 'smooth' });
      });
    },

    /**
     * Gets called when a file gets added by the FileUpload component.
     * @event onFileAdded
     * @type {FileAdded}
     */
    onFilesAdded(items) {
      this.$store.dispatch('guarantee/media/addTemplates', { items });
      const text = this.$t('file_upload.successfully_added', { fileName: items[items.length - 1].label });
      this.$notify({
        duration: 5000,
        position: 'top',
        width: '100%',
        data: {
          type: 'success'
        },
        text
      });
      this.scrollToAttachments();
    },

    /**
     * Gets called when an error occurs during the file-upload.
     * @event onFileUploadError
     * @type {Error}
     */
    onFileUploadError(error) {
      if (error && error.message && !error.message.errors) {
        const text = this.$t(`file_upload.validation.${error.message}`);
        this.$notify({
          duration: 5000,
          position: 'top',
          width: '100%',
          data: {
            type: 'error'
          },
          text
        });
      }
    },

    /**
     * Gets called when the User clicks on remove on an item from the attached-files list.
     * @event onFileRemove
     * @type {Click}
     */
    onFileDelete(id, hash) {
      const currentFile = this.files.find(item => item.id === id);
      this.$store.dispatch('guarantee/media/deleteTemplate', { id, hash }).then((data) => {

      });

      const text = this.$t('file_upload.successfully_deleted', { fileName: currentFile.label });
      this.$notify({
        duration: 5000,
        position: 'top',
        width: '100%',
        data: {
          type: 'success'
        },
        text
      });
    },

    /**
     * Gets called when an already uploaded file from the file-list gets selected.
     * @event onExistingFileChosen
     * @type {Choose}
     */
    onExistingFileChosen(file) {
      this.$store.dispatch('guarantee/media/addExistingTemplate', { file });
    },

    /**
     * Is called when standard model texts are changing
     * @event onUseStandardModelTextChange
     * @param {Object} param - key, value
    */
    onUseStandardModelTextChange(param) {
      this.$store.dispatch('guarantee/media/setDefaultTemplate', { defaultTemplate: param.val });
    },

    /**
     * Validates the current file selection
     */
    validateSelection() {
      const isValid = (this.defaultModelText || this.files.length > 0);

      this.$emit('ondisable', !isValid);
    },

    /**
     * Returns a formatted label for an attachment.
     * @param {Number} nr
     */
    formatAttachmentLabel(nr) {
      return this.$t('file_upload.attachment') + ' ' + nr;
    },

    /**
     * Custom search function for searching only for the file-names.
     * @param {Object} row
     * @param {Number} col
     * @param {String} cellValue
     * @param {String} searchTerm
     */
    existingFilesSearchFn(row, col, cellValue, searchTerm) {
      return row.file_name.includes(searchTerm);
    }
  }
};
</script>
