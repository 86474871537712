import api from '@core/api/';
import { toggle } from '@core/store/utils';

const state = {
  isLoading: false,
  list: []
};

const getters = {
  isLoading: state => state.isLoading,
  countries: state => state.list,
  hasCountries: state => state.list.length > 0,
  currentCountry: state => id => state.list.find(item => id === item.id)
};

const actions = {
  /**
   * Gets all available countries
   * @method getCountries
   */
  getCountries({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.list.length > 0) return resolve(state.list);

      commit('toggleLoading');

      return api.getApiData('/countries').then(({ data }) => {
        commit('setCountries', data.data);
        commit('toggleLoading');

        resolve(data);
      }).catch((error) => {
        api.apiError(error);
        commit('toggleLoading');

        reject(error);
      });
    });
  }
};

const mutations = {
  toggleLoading: toggle('isLoading'),
  setCountries: (state, values) => {
    state.list = values.map((el) => {
      return {
        highlight: el.highlight,
        label: el.label,
        value: el.label,
        id: el.value
      };
    });
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
