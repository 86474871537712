<template>
  <b-button
    v-bind="$attrs"
    :class="{ 'btn--icon': icon !== '', 'btn--transparent': transparent }"
    @click="onClick($event)"
  >
    <!-- @slot Button text -->
    <slot />
    <icon
      v-if="icon"
      :icon="icon"
      size="18"
      class="btn__icon"
    />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import Icon from '@atoms/Icon/Icon.vue';

/**
 * Standard button
 */
export default {
  name: 'Btn',
  components: {
    'b-button': BButton,
    Icon
  },
  directives: {
    'b-button': BButton
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick(event) {
      /**
       * Emits click event to parent component.
       * @event onClick
       * @property event
       */
      this.$emit('click', event);
    }
  }
};
</script>

<style lang="scss">
  @import './Btn';
</style>
