import api from '@core/api/';
import { set, toggle } from '@core/store/utils';
import { dateFn } from '@theme/utils/datefns';

const apiUrl = process.env.VUE_APP_API_URL;

const state = {
  isLoading: false,
  templates: [],
  documents: [],
  defaultModelText: false,
  guaranteeId: null,
  existingTemplatesTotal: -1,
  existingDocumentsTotal: -1,
  existingTemplates: [],
  existingDocuments: [],
  errors: []
};

const actions = {
  /**
   * Fetches existing files in the collection 'model_texts'.
   * @method getExistingTemplates
   */
  getExistingTemplates({ dispatch, commit }) {
    commit('toggleLoading');

    return new Promise((resolve, reject) => {
      dispatch('getExistingFiles', {
        collectionsDB: 'model_texts',
        collection: 'templates'
      }).then((items) => {
        commit('setExistingTemplates', { items });
        resolve();
      }).catch(() => reject());
    });
  },

  /**
   * Fetches existing files in the collection 'documents'.
   * @method getExistingDocuments
   */
  getExistingDocuments({ dispatch, commit }) {
    commit('toggleLoading');

    return new Promise((resolve, reject) => {
      dispatch('getExistingFiles', {
        collectionsDB: ['model_texts', 'documents'],
        collection: 'documents'
      }).then((items) => {
        commit('setExistingDocuments', { items });
        resolve();
      }).catch(() => reject());
    });
  },

  /**
   * Gets files from contained in a collection or array of collections from the DB.
   * @method getExistingFiles
   */
  getExistingFiles({ state, rootState, commit }, { collectionsDB, collection }) {
    return new Promise((resolve, reject) => {
      const url = apiUrl + 'accounts/' + rootState.user.user.account.id + '/media';
      const requestBody = {
        params: {
          collection: collectionsDB
        }
      };

      api.getApiData(url, requestBody).then(({ data: responseData }) => {
        const items = [];

        if (responseData.data) {
          responseData.data.forEach(file => {
            items.push({
              id: file.id,
              file_name: file.name,
              file_hash: file.file_hash,
              created_at: dateFn(file.created_at),
              selected: false
            });
          });
        }

        commit('toggleLoading');
        resolve(items);
      }).catch((error) => {
        commit('toggleLoading');
        commit('setError', error.response.data);
        reject(error);
      });
    });
  },

  /**
   * Adds a templatee to the template list.
   * @method addTemplates
   */
  addTemplates({ state, commit }, { items }) {
    if (state.defaultModelText) {
      commit('setDefaultTemplate', { defaultTemplate: false });
    }

    commit('addTemplates', { items });
  },

  /**
   * Adds a template from the existing templates to the template list.
   * @method addExistingTemplate
   */
  addExistingTemplate({ commit }, { file }) {
    if (state.defaultModelText) {
      commit('setDefaultTemplate', { defaultTemplate: false });
    }

    commit('setExistingTemplateSelected', {
      hash: file.file_hash,
      selected: true
    });

    commit('addTemplate', {
      id: file.id,
      hash: file.file_hash,
      label: file.file_name
    });
  },

  /**
   * Adds a document from the existing documents to the document-list.
   * @method addExistingDocument
   */
  addExistingDocument({ commit }, { file }) {
    commit('setExistingDocumentSelected', {
      hash: file.file_hash,
      selected: true
    });

    commit('addDocument', {
      id: file.id,
      hash: file.file_hash,
      label: file.file_name
    });
  },

  /**
   * Adds templates received from the DB to the templates array.
   * @method setTemplates
   */
  setTemplates({ commit }, { files }) {
    const items = [];
    files.forEach(file => {
      const selectedTemplate = state.existingTemplates.find((template) => { return template.file_hash === file.file_hash; });
      if (typeof selectedTemplate !== 'undefined') {
        commit('setExistingTemplateSelected', {
          hash: selectedTemplate.file_hash,
          selected: true
        });
      }
      items.push({
        id: file.id,
        hash: file.file_hash,
        label: file.name
      });
    });
    commit('setTemplates', { items });
  },

  /**
   * Adds documents received from the DB to the documents array.
   * @method setDocuments
   */
  setDocuments({ commit }, { files }) {
    const items = [];
    files.forEach(file => {
      const selectedDocument = state.existingDocuments.find(document => { return document.file_hash === file.file_hash; });
      if (typeof selectedDocument !== 'undefined') {
        commit('setExistingDocumentSelected', {
          hash: selectedDocument.file_hash,
          selected: true
        });
      }
      items.push({
        id: file.id,
        hash: file.file_hash,
        label: file.name
      });
    });
    commit('setDocuments', { items });
  },

  /**
   * Calls the delete endpoint to delete a template with specified file hash.
   * @method deleteTemplate
   */
  deleteTemplate({ state, rootState, dispatch, commit }, { id, hash }) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}guarantees/${rootState.guarantee.create.guaranteeId}/media/${id}`;
      const existingTemplatesContainItem = state.existingTemplates.find(item => item.file_hash === hash);

      if (!existingTemplatesContainItem) {
        api.deleteApiData(url).then(() => {
          dispatch('removeTemplate', { id });
          resolve(id);
        }).catch((error) => {
          if (error && error.response && error.response.data) {
            commit('setError', error.response.data);
          }
          reject(id);
        });
      } else {
        dispatch('removeTemplate', { id });
        commit('setExistingTemplateSelected', {
          hash: hash,
          selected: false
        });
        resolve(id);
      }
    });
  },

  /**
   * Calls the delete endpoint to delete a file.
   * @method deleteDocument
   */
  deleteDocument({ state, rootState, commit }, { id, hash }) {
    const url = `${apiUrl}guarantees/${rootState.guarantee.create.guaranteeId}/media/${id}`;
    const existingDocumentsContainItem = state.existingDocuments.find(item => item.id === id);

    if (!existingDocumentsContainItem) {
      api.deleteApiData(url).then(() => {
        commit('removeDocument', { id });
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          commit('setError', error.response.data);
        }
      });
    } else {
      commit('removeDocument', { id });
      commit('setExistingDocumentSelected', {
        hash: hash,
        selected: false
      });
    }
  },

  /**
   * Removes template from the template list and sets the defaultTemplate if the list is empty.
   * @method removeTemplate
   */
  removeTemplate({ state, commit }, { id }) {
    commit('removeTemplate', { id });

    if (!state.templates.length) {
      commit('setDefaultTemplate', { defaultTemplate: false });
    }
  },

  /**
   * Sets the defaultTemplate.
   * @method setDefaultTemplate
   */
  setDefaultTemplate({ commit }, { defaultTemplate }) {
    if (defaultTemplate) {
      commit('setTemplates', { items: [] });
    }

    commit('setDefaultTemplate', { defaultTemplate });
  }
};

const mutations = {
  toggleLoading: toggle('isLoading'),
  setError: set('error'),
  setLocale: set('locale'),
  setTemplates: (state, { items }) => state.templates = items,
  setDocuments: (state, { items }) => state.documents = items,
  addTemplate: (state, file) => state.templates.push(file),
  addDocument: (state, file) => state.documents.push(file),
  addTemplates: (state, { items }) => state.templates = state.templates.concat(items),
  addDocuments: (state, { items }) => state.documents = state.documents.concat(items),
  removeTemplate: (state, { id }) => state.templates.splice(state.templates.findIndex(item => item.id === id), 1),
  removeDocument: (state, { id }) => state.documents.splice(state.documents.findIndex(item => item.id === id), 1),
  setDefaultTemplate: (state, { defaultTemplate }) => state.defaultModelText = defaultTemplate,
  setExistingTemplates(state, { items }) {
    state.existingTemplates = items;
    state.existingTemplatesTotal = items.length;
  },
  setExistingDocuments(state, { items }) {
    state.existingDocuments = items;
    state.existingDocumentsTotal = items.length;
  },
  setExistingTemplateSelected(state, { hash, selected }) {
    const selectedTemplate = state.existingTemplates.find(item => { return item.file_hash === hash; });
    if (typeof selectedTemplate !== 'undefined') selectedTemplate.selected = selected;
  },
  setExistingDocumentSelected(state, { hash, selected }) {
    const existingDocument = state.existingDocuments.find((item) => { return item.file_hash === hash; });
    if (typeof existingDocument !== 'undefined') existingDocument.selected = selected;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
