<template>
  <vue-notifications v-bind="$attrs">
    <template
      slot="body"
      slot-scope="props"
    >
      <div
        v-if="props.item.title || props.item.text"
        :class="classes(props.item)"
      >
        <p class="notices__content">
          <b
            v-if="!!props.item.title"
            class="notices__title"
            v-html="props.item.title"
          />
          <span
            class="notices__text"
            v-html="props.item.text"
          />
        </p>
        <div class="notices__action">
          <btn
            transparent
            class="notices__remove"
            @click="props.close"
          />
        </div>
      </div>
    </template>
  </vue-notifications>
</template>

<script>
export default {
  name: 'Notices',
  methods: {
    classes(item) {
      return [
        'notices',
        item.data.type ? `notices--${item.data.type}` : ''
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
  @import 'Notices.scss';
</style>
