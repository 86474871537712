<template>
  <transition name="fade-faster">
    <div
      v-show="isVisible"
      class="tooltip"
    >
      <p
        v-if="title"
        class="tooltip__title"
        v-text="title"
      />
      <p
        class="tooltip__text"
        v-text="text"
      />
      <icon
        icon="close"
        color="red"
        color-hover="grey"
        size="12"
        emits-click-event
        class="tooltip__close"
        @click="onCloseClick"
      />
    </div>
  </transition>
</template>

<script>
import Icon from '@atoms/Icon/Icon';

export default {
  name: 'Tooltip',
  components: {
    Icon
  },
  props: {
    /**
     * Title of tooltip
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Text of tooltip
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * Check if Tooltip is visible
     */
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCloseClick(event) {
      /**
      * update model and validate field
      * @event close
      * @property {object} event
      */
      this.$emit('close', event);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './Tooltip';
</style>
