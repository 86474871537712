<template>
  <validation-observer #default="{ handleSubmit }" slim>
    <b-form
      novalidate
      autocomplete="off"
      class="password"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <b-form-group
        class="password__wrapper"
      >
        <h1
          class="password__label"
          v-text="title"
        />
        <tooltip
          :title="$t('label.password_requirements_title')"
          :text="$t('label.password_requirements')"
          :is-visible="tooltipVisible && !popupDisabled"
          class="password__hint"
          @close="popupDisabled = true"
        />
        <form-input
          id="password"
          vid="password"
          ref="password"
          :label="$t('label.password')"
          :placeholder="$t('placeholder.enter_password')"
          :class="{ 'is-invalid': externalError !== null }"
          validation="required|password"
          class="password__form-input"
          variant="minimal"
          type="password"
          data-testing="input_password"
          @onblur="tooltipVisible = false"
          @onfocus="tooltipVisible = true"
          @oninput="onPasswordInput"
        />
        <form-input
          id="repeat_password"
          ref="repeat_password"
          :label="$t('label.repeat_password')"
          :placeholder="$t('placeholder.repeat_password')"
          :class="{ 'is-invalid': externalError !== null }"
          validation="required|confirmed:password"
          variant="minimal"
          type="password"
          class="password__form-input"
          data-testing="input_password_confirm"
          @oninput="onRepeatPasswordInput"
        />
        <p
          v-if="externalError !== null"
          class="invalid-feedback d-block"
          v-text="$t('validation.messages.' + externalError.msg)"
        />
        <btn
          variant="primary"
          class="login__btn"
          type="submit"
          data-testing="button_submit"
          v-text="$t('button.save')"
        />
      </b-form-group>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormGroup } from 'bootstrap-vue';

import FormInput from '@components/FormInput/FormInput';
import Tooltip from '@components/Tooltip/Tooltip';

/**
 * Password form
 */
export default {
  name: 'PasswordForm',
  components: {
    'b-form': BForm,
    'b-form-group': BFormGroup,
    FormInput,
    Tooltip
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    submit: {
      type: Function,
      default: null
    },
    externalError: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      password: null,
      repeatPassword: null,
      tooltipVisible: false,
      popupDisabled: false
    };
  },
  methods: {
    /**
     * Dispatches the login action
     * @event onSubmit
     * @type {Submit}
     */
    onSubmit() {
      if (typeof this.submit === 'function') this.submit(this.password, this.repeatPassword);
    },
    /**
     * Handle input event
     * @event onPasswordInput
     * @type {Input}
     */
    onPasswordInput(value) {
      this.password = value;
    },
    /**
     * Handle input event
     * @event onRepeatPasswordInput
     * @type {Input}
     */
    onRepeatPasswordInput(value) {
      this.repeatPassword = value;
    }
  }
};
</script>

<style lang="scss">
  @import './PasswordForm';
</style>
