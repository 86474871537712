import api from '@core/api/';
import { toggle } from '@core/store/utils';
import qs from 'qs';
import moment from 'moment';

const state = {
  isLoading: false,
  filter: {
    event: 'all'
  },
  history: []
};

const actions = {
  /**
   * Get history
   * @method getHistory
   */
  getHistory({ commit, state }) {
    commit('toggleLoading');

    const query = qs.stringify(
      { filter: state.filter },
      { arrayFormat: 'brackets' }
    );

    return api.getApiData(`/history?${query}`).then(({ data: responseData }) => {
      if (responseData && responseData.data) {
        commit('setHistory', responseData.data);
      }

      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      commit('toggleLoading');
    });
  }
};

const mutations = {
  toggleLoading: toggle('isLoading'),
  setHistory: (state, data) => {
    const groupedData = data.reduce((res, obj) => {
      const correctDate = moment(obj.created_at).format('YYYY-MM-DD');
      res[correctDate] ? res[correctDate].events.push(obj) : res[correctDate] = { date: correctDate, events: [obj] };

      return res;
    }, {});
    const result = Object.keys(groupedData).map((k) => { return groupedData[k]; });

    state.history = result;
  },
  clearFilter: (state, filterName) => delete state.filter[filterName],
  clearAllFilters: (state) => state.filter = {},
  setFilter: (state, params) => state.filter = { ...state.filter, ...params }
};

export default {
  namespaced: true,

  state,
  actions,
  mutations
};
