<template>
  <validation-observer #default="{ handleSubmit, invalid }">
    <b-form class="employee">
      <global-events @keyup.enter="handleSubmit(submit)" />
      <fieldset class="multistep container-fluid">
        <slot name="notification" />
        <div class="row nomargin">
          <div class="col-md-3">
            <legend
              class="employee__legend"
              v-text="$t('label.employee_info')"
            />
          </div>
          <div class="col-md-9 employee__block">
            <div class="row">
              <div class="col-md-12">
                <label
                  class="col-form-label pt-0"
                  v-text="$t('label.title')"
                />
                <radio-buttons
                  :items="titles"
                  :value="employee.title"
                  name="employee-title"
                  data-testing="radio_title"
                  @change="onTitleChange"
                />
              </div>
            </div>
            <div class="row">
              <form-input
                id="first_name"
                ref="firstName"
                :label="$t('label.first_name')"
                :placeholder="$t('placeholder.enter_first_name')"
                :value="employee.first_name"
                type="text"
                validation="required"
                class="col-md-6 col-12"
                show-required-asterisk
                data-testing="input_first_name"
                @oninput="onFirstnameInput"
              />
              <form-input
                id="last_name"
                ref="lastName"
                :label="$t('label.last_name')"
                :placeholder="$t('placeholder.enter_last_name')"
                :value="employee.last_name"
                type="text"
                validation="required"
                show-required-asterisk
                class="col-md-6 col-12"
                data-testing="input_last_name"
                @oninput="onLastnameInput"
              />
            </div>
            <div class="row">
              <form-input
                id="email"
                ref="email"
                :label="$t('label.email')"
                :disabled="type === 'edit'"
                :value="employee.email"
                :placeholder="$t('placeholder.enter_email')"
                type="email"
                validation="required|email"
                class="col-md-6 col-12"
                show-required-asterisk
                data-testing="input_email"
                @oninput="onEmailInput"
              />
              <form-input
                id="mobile"
                ref="mobile"
                :label="$t('label.phone')"
                :disabled="type === 'edit'"
                :value="employee.mobile"
                placeholder="+43 (5574) 123 456789"
                type="text"
                validation="required"
                class="col-md-6 col-12"
                show-required-asterisk
                data-testing="input_mobile"
                @oninput="onMobileInput"
              />
            </div>
            <ul
              v-if="apiErrors"
              class="employee__errors"
            >
              <li
                v-for="error in Object.entries(apiErrors)"
                :key="error[0]"
                class="employee__error"
              >
                <span v-text="$t(`create_user.validation.${error[0]}.${error[1][0]}`)" />
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
            <btn
              :disabled="invalid"
              variant="primary"
              class="content-header__btn content-header__btn--margin"
              data-testing="button_submit"
              @click="handleSubmit(submit)"
              v-text="buttonText"
            />
          </div>
        </div>
      </fieldset>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm } from 'bootstrap-vue';
import GlobalEvents from 'vue-global-events';
import FormInput from '@components/FormInput/FormInput';
import RadioButtons from '@components/RadioButtons/RadioButtons';

export default {
  name: 'EmployeeForm',
  components: {
    'b-form': BForm,
    FormInput,
    RadioButtons,
    GlobalEvents
  },
  props: {
    apiErrors: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'new',
      validator: value => ['new', 'edit'].includes(value)
    },
    buttonText: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      employee: {
        title: 'Frau',
        first_name: '',
        last_name: '',
        email: '',
        mobile: ''
      },
      titles: [
        {
          label: this.$t('label.mrs'),
          id: 'Frau'
        },
        {
          label: this.$t('label.mr'),
          id: 'Herr'
        },
        {
          label: this.$t('label.notitle'),
          id: 'Keine Angabe'
        }
      ]
    };
  },
  watch: {
    data(newData) {
      if (newData) {
        this.employee.title = newData.title;
        this.employee.first_name = newData.first_name;
        this.employee.last_name = newData.last_name;
        this.employee.email = newData.email;
        this.employee.mobile = newData.mobile;
      }
    }
  },
  methods: {
    /**
       * Set title
       * @event onTitleChange
       * @type {input}
       */
    onTitleChange(param) {
      this.employee.title = param;
    },

    /**
       * Set firstName
       * @event onFirstnameInput
       * @type {input}
       */
    onFirstnameInput(param) {
      this.employee.first_name = param;
    },

    /**
       * Set lastName
       * @event onLastnameInput
       * @type {input}
       */
    onLastnameInput(param) {
      this.employee.last_name = param;
    },

    /**
       * Set email
       * @event onEmailInput
       * @type {input}
       */
    onEmailInput(param) {
      this.employee.email = param;
    },

    /**
       * Set mobile
       * @event onMobileInput
       * @type {input}
       */
    onMobileInput(param) {
      this.employee.mobile = param;
    },

    /**
       * Submit the form
       * @event submit
       * @type {click}
       */
    submit() {
      this.$emit('submit', this.employee);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import 'EmployeeForm';
</style>
