import api from '@core/api/';
import { set, toggle } from '@core/store/utils';
import qs from 'qs';

const state = {
  isLoading: false,
  tableBody: [],
  pagination: {
    perPage: 25,
    page: 1
  },
  sorting: {},
  total: 0,
  filter: {},
  stateFilters: [],
  searchSuggestions: []
};

const getters = {
  isEmptyFilter: state => {
    return Object.keys(state.filter).length === 0;
  }
};

const actions = {
  /**
  * Get table data from API
  * @method getData
  */
  getData({ state, rootState, dispatch, commit }) {
    commit('setLoading', true);

    const payload = {
      filter: state.filter,
      per_page: state.pagination.perPage,
      page: state.pagination.page,
      sort: state.sorting
    };

    const query = qs.stringify(payload, { arrayFormat: 'brackets' });
    api.getApiData(`guarantees?${query}`).then(({ data: responseData }) => {
      commit('setTotal', responseData.meta.total);

      if (responseData.data) {
        dispatch('setRowsActions', { rows: responseData.data }).then(() => {
          commit('setTableBody', responseData.data);
        });
      }

      if (!state.stateFilters.length || !rootState.guarantee.beneficiaries.beneficiariesFilter) {
        dispatch('getStateFilters');
      } else if (rootState.guarantee.beneficiaries.beneficiariesFilter &&
        responseData.data.length !== rootState.guarantee.beneficiaries.beneficiariesFilter.length) {
        dispatch('getStateFilters');
      }

      commit('setLoading', false);
    }).catch(() => {
      commit('setLoading', false);
    });
  },

  /**
   * Sets the actions for every guarantee, based on user permissions and guarantee state.
   * @method setRowsActions
   */
  setRowsActions({ state, rootState }, { rows }) {
    const user = rootState.user.user;

    return new Promise((resolve, reject) => {
      rows.forEach((row) => {
        const defaultActions = [
          {
            action: 'guarantee/create/createFromTemplate',
            label: 'button.request_changes',
            type: 'button',
            value: row.id
          },
          {
            action: 'guarantee/detail/exportPdf',
            label: 'button.download_pdf',
            type: 'button',
            value: row.id
          }
        ];

        if (row.state !== 'states.approved' &&
          row.state !== 'states.approved_active' &&
          row.state !== 'states.withdrawn' &&
          row.state !== 'states.drawn' &&
          row.state !== 'states.expired' &&
          row.state !== 'states.approval_requested') {
          if (row.state === 'states.draft') {
            defaultActions.push({
              action: 'guarantee/create/edit',
              label: 'button.edit',
              type: 'button',
              value: row.id
            });
          } else {
            defaultActions.push({
              action: `guarantees/${row.id}/step-2`,
              label: 'button.edit',
              type: 'link'
            });
          }
        }

        if (
          (row.state === 'states.approval_requested' ||
           row.state === 'states.approval_pending') &&
          ['supervisor', 'admin', 'super_admin'].indexOf(user.role) !== -1
        ) {
          defaultActions.push({
            action: `guarantees/${row.id}/approve`,
            label: 'button.approve',
            type: 'link'
          });
        }

        if (row.state === 'states.draft') {
          defaultActions.push({
            action: 'guarantee/create/setDeletedGuarantee',
            label: 'button.delete',
            type: 'button',
            value: {
              id: row.id,
              referenceNumber: row.reference_number
            }
          });
        }

        row.actions = defaultActions;
      });

      resolve();
    });
  },

  /**
   * Clears a single filter which is selected by name.
   * @method clearSingleFilter
   */
  clearSingleFilter({ commit }, filter) {
    if (filter === 'beneficiary_id') {
      commit('guarantee/beneficiaries/clear', null, { root: true });
    } else if (filter === 'search') {
      commit('resetSearchSuggestions');
    }

    commit('clearFilter', filter);
  },

  /**
   * Clears all filters
   * @method clearFilter
   */
  clearFilter({ commit }) {
    commit('clearAllFilters');
    commit('resetSearchSuggestions');
  },

  /**
   * Gets the current filters from the API
   * @method getStateFilters
   */
  getStateFilters({ state, commit }) {
    return new Promise((resolve, reject) => {
      const payload = {
        filter: state.filter,
        filteroptions: true
      };

      const query = qs.stringify(payload, { arrayFormat: 'brackets' });
      api.getApiData(`guarantees?${query}`).then(({ data: responseData }) => {
        if (responseData.data) {
          if (responseData.data.states && responseData.data.states.length) {
            const states = responseData.data.states;
            states.unshift('all');

            commit('setStateFilters', states);
          }

          if (responseData.data.beneficiaries) {
            commit('guarantee/beneficiaries/setBeneficiariesFilter', Object.keys(responseData.data.beneficiaries), { root: true });
          }
        }

        resolve();
      });
    });
  },

  /**
   * get table from API by search term for auto-suggests
   * @method getSuggestions
   */
  getSuggestions({ state, commit }, searchTerm) {
    if (searchTerm.length < 3) return;

    commit('setFilter', { search: searchTerm });

    const payload = {
      filter: state.filter,
      filteroptions: true
    };

    const query = qs.stringify(payload, { arrayFormat: 'brackets' });
    api.getApiData(`guarantees?${query}`).then(({ data: responseData }) => {
      if (responseData && responseData.data && responseData.data.beneficiaries && responseData.data.reference_numbers && responseData.data.reference_numbers.length) {
        const beneficiaries = Object.values(responseData.data.beneficiaries).filter((value, index, self) => self.indexOf(value) === index);
        const referenceNumbers = responseData.data.reference_numbers;

        const listCompanyNames = [];
        const listReferenceNumbers = [];

        beneficiaries.forEach(item => {
          if (listCompanyNames.length < 6) {
            if (item.toLowerCase().includes(searchTerm.toLowerCase())) {
              listCompanyNames.push({
                label: item,
                value: item
              });
            }
          }
        });

        const remaining = 6 - listCompanyNames.length;

        if (remaining > 0) {
          referenceNumbers.forEach(item => {
            if (listReferenceNumbers.length < remaining) {
              if (item.toLowerCase().includes(searchTerm.toLowerCase())) {
                listReferenceNumbers.push({
                  label: item,
                  value: item
                });
              }
            }
          });
        }

        if (listCompanyNames.length) listCompanyNames[0].header = 'label.beneficiary';
        if (listReferenceNumbers.length) listReferenceNumbers[0].header = 'label.reference_number';

        commit('setSearchSuggestions', listCompanyNames.concat(listReferenceNumbers));
      }
    }).catch((error) => {
      api.apiError(error);
    });
  },

  /**
   * Sets the sorting parameters.
   * @method setSorting
   */
  setSorting({ commit }, { params }) {
    const sorts = [];

    params.forEach((el) => {
      sorts.push(el.type === 'asc' ? el.field : `-${el.field}`);
    });

    commit('setSort', sorts.toString());
  }
};

const mutations = {
  setTableBody: set('tableBody'),
  setTotal: set('total'),
  setSearchSuggestions: set('searchSuggestions'),
  toggleLoading: toggle('isLoading'),
  setLoading: set('isLoading'),
  setSort: set('sorting'),
  setStateFilters: set('stateFilters'),
  resetSearchSuggestions: state => state.searchSuggestions = [],
  clearFilter: (state, filterName) => delete state.filter[filterName],
  clearAllFilters: (state) => state.filter = {},
  setFilter: (state, params) => state.filter = { ...state.filter, ...params },
  setPerPage: (state, value) => state.pagination.perPage = value,
  setPage: (state, value) => state.pagination.page = value
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
