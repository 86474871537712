<template>
  <template-fullscreen>
    <password-form
      :title="$t('label.set_password')"
      :submit="submit"
      :external-error="error"
    />
  </template-fullscreen>
</template>

<script>
import TemplateFullscreen from '@theme/router/views/templates/TemplateFullscreen';
import PasswordForm from '@components/PasswordForm/PasswordForm';

export default {
  name: 'ConfirmInvitation',
  components: {
    TemplateFullscreen,
    PasswordForm
  },
  computed: {
    error() {
      return this.$store.getters['user/getError'];
    }
  },
  mounted() {
    const payload = {
      route: this.$route.fullPath
    };

    this.$store.dispatch('user/redirectIfInvitationExpired', payload);
  },
  methods: {
    submit(password, repeatPassword) {
      // TODO: Check if all params are set in the URL

      const payload = {
        route: this.$route.fullPath,
        password: password,
        repeatPassword: repeatPassword
      };

      this.$store.dispatch('user/confirmInvitation', payload).then(() => {
        this.$router.push({
          name: 'login',
          params: {
            redirectedFromInvitation: true
          }
        });
      });
    }
  }
};
</script>
