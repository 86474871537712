<template>
  <btn
    v-if="emitsClickEvent"
    class="icon__button"
    type="button"
    @click="onClick"
  >
    <span :class="classes" />
  </btn>
  <b-link
    v-else-if="link"
    :to="link"
    :class="classes"
  />
  <span
    v-else
    :class="classes"
  />
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  name: 'Icon',
  components: {
    'b-link': BLink
  },
  props: {
    /**
     * Sets the icon.
     */
    icon: {
      type: String,
      required: true
    },
    /**
     * Sets the icon color.
     */
    color: {
      type: String,
      default: 'white'
    },
    /**
     * Sets the icon hover color, if not set, no hover effect will be shown.
     */
    colorHover: {
      type: String,
      default: null
    },
    /**
     * Sets the icon size.
     */
    size: {
      type: String,
      default: '25',
      validator: value => ['12', '18', '19', '20', '25', '32'].indexOf(value) !== -1
    },
    /**
     * Defines if the icon should emit a click event.
     */
    emitsClickEvent: {
      type: Boolean,
      default: false
    },
    /**
     * Turns the icon into a link and sets the href
     */
    link: {
      type: String,
      default: null
    }
  },
  computed: {
    classes() {
      const classes = {};

      classes.icon = true;

      if (this.icon) classes['icon--bf icon--' + this.icon] = true;
      if (this.color) classes['icon--' + this.color] = true;
      if (this.size) classes['icon--size-' + this.size] = true;
      if (this.notify) classes['icon--notify'] = true;

      if (this.colorHover) {
        classes['icon--hover'] = true;
        classes['icon--hover-' + this.colorHover] = true;
      }

      return classes;
    }
  },
  methods: {
    /**
     * Emits click event to the parent component.
     * @event onClick
     * @type {click}
     */
    onClick(event) {
      this.$emit('click', event);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './Icon';
</style>
