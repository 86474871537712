import Content from '@theme/router/views/Content';
import Error from '@theme/router/views/Error';
import Login from '@theme/router/views/Login';
import ConfirmInvitation from '@theme/router/views/ConfirmInvitation';
import PasswordForgotten from '@theme/router/views/PasswordForgotten';
import ResetPassword from '@theme/router/views/ResetPassword';

import Guarantees from '@theme/router/views/guarantee/Guarantees';
import GuaranteeCreate from '@theme/router/views/guarantee/Create';
import GuaranteeDetail from '@theme/router/views/guarantee/Detail';
import GuaranteeApprove from '@theme/router/views/guarantee/Approve';
import Employees from '@theme/router/views/employee/Employees';
import EditEmployee from '@theme/router/views/employee/Edit';
import Profile from '@theme/router/views/profile/Edit';

import Page from '@theme/router/views/pages/Default';
import Documents from '@theme/router/views/pages/Documents';
import History from '@theme/router/views/pages/History';
import Assess from '@theme/router/views/pages/Assess';
import Faqs from '@theme/router/views/pages/FAQs';

export default [
  {
    path: '',
    name: 'home',
    component: Content
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/invites/:hash',
    name: 'confirmInvitation',
    component: ConfirmInvitation
  },
  {
    path: '/password-forgotten',
    name: 'passwordForgotten',
    component: PasswordForgotten
  },
  {
    path: '/password/reset',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/guarantees',
    name: 'myguarantees',
    component: Guarantees
  },
  {
    path: '/guarantees/:id/detail',
    name: 'guaranteeDetail',
    component: GuaranteeDetail
  },
  {
    path: '/guarantees/:id/approve',
    name: 'guaranteeApprove',
    component: GuaranteeApprove
  },
  {
    path: '/guarantees/:id/:step',
    name: 'guaranteeId',
    component: GuaranteeCreate
  },
  {
    path: '/employees',
    name: 'employees',
    component: Employees
  },
  {
    path: '/employees/:id/edit',
    name: 'editEmployee',
    component: EditEmployee
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '/assess',
    name: 'assess',
    component: Assess
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: Faqs
  },
  {
    path: '/page/:name',
    name: 'page',
    component: Page
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '*',
    redirect: 'error'
  }
];
