<template>
  <div class="radio-block">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="radio"
    >
      <input
        v-model="selected"
        :id="name + '_' + index"
        :value="item.id"
        :name="name"
        :disabled="item.disabled"
        :class="{ radio__radio: true, checked: item.id === selected }"
        :data-testing="dataTesting"
        type="radio"
        @change="onChange(item.id)"
      >
      <label
        :for="name + '_' + index"
        class="radio__label"
        v-text="item.label"
      />
    </div>
  </div>
</template>

<script>
/**
   * Form radio component
   */
export default {
  name: 'RadioButtons',
  props: {
    /**
       * The name of radio
       */
    name: {
      type: String,
      required: true
    },
    /**
       * An array of initial items
       */
    items: {
      type: Array,
      required: true
    },
    /**
       * Selected value
       */
    value: {
      type: String,
      default: ''
    },
    /**
       * Variable for cypress testing
       */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data() {
    /**
       * @model
       */
    return {
      selected: ''
    };
  },
  watch: {
    value(newValue) {
      if (newValue) this.selected = newValue;
    }
  },
  mounted() {
    if (this.value) {
      this.selected = this.value;
    } else if (this.items.length) {
      this.selected = this.items[0].id;
    }
  },
  methods: {
    onChange(id) {
      this.selected = id;

      /**
       * Update model and notify all parent listeners
       * @event change
       * @property {string} selected
       */
      this.$emit('change', this.selected);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './RadioButtons';
</style>
