import Vue from 'vue';
import cookies from '@theme/utils/cookies';
import { router, store, i18n } from '@core/';

// Custom Theme
import App from '@theme/App';
import { isPublicRoute } from '@theme/utils/router';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_THEME_FOLDER === 'guarantee') {
    const hasCookie = cookies.hasCookie('access_token');
    const route = to.name;

    if (!hasCookie && !isPublicRoute(route)) {
      next({
        name: 'login',
        replace: true,
        query: { redirected: window.location.pathname }
      });

      if (to.name === 'guaranteeId' || to.name === 'guaranteeDetail') {
        store.commit('user/setRedirected', { from: to.path });
      }

      return;
    } else if (hasCookie) {
      store.dispatch('user/getUser').then(() => {
        if (route === 'home' || route === 'login') {
          next({
            name: 'myguarantees',
            replace: true
          });
        } else {
          next();
        }
      });

      return;
    }
  }

  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

// Hot updates
if (module.hot) {
  module.hot.accept([
    `./themes/${process.env.VUE_APP_THEME_FOLDER}/locales/deAT.json`,
    `./themes/${process.env.VUE_APP_THEME_FOLDER}/locales/deDE.json`,
    `./themes/${process.env.VUE_APP_THEME_FOLDER}/locales/deCH.json`
  ], function () {
    i18n.setLocaleMessage('de-AT', require(`./themes/${process.env.VUE_APP_THEME_FOLDER}/locales/deAT.json`).default);
    i18n.setLocaleMessage('de-DE', require(`./themes/${process.env.VUE_APP_THEME_FOLDER}/locales/deDE.json`).default);
    i18n.setLocaleMessage('de-CH', require(`./themes/${process.env.VUE_APP_THEME_FOLDER}/locales/deCH.json`).default);
  });
}
