// https://gist.github.com/rhythnic/6521495650a215ccab8bf7120949fb7d
/* eslint-disable */
const findByKey = (key, targetProp) => state => val => state[key].find(x => x[targetProp] === val);

/* MUTATIONS */
// Set property on state
// setUser: set('user')
// commit('setUser', { name: 'foo' })
const set = key => (state, val) => state[key] = val;

// Toggle boolean in state
// toggleOpen: toggle('open')
// commit('toggleOpen')
const toggle = key => (state) => state[key] = !state[key];

// push an item onto a list
// addItem: pushTo('items')
const pushTo = key => (state, val) => state[key].push(val);

// remove item from list
const removeFrom = key => (state, item) => {
  const index = state[key].indexOf(item)
  if (index > -1) state[key].splice(index, 1)
}

// copy all key/values from data to state
// useful for resetting state to default values
// resetState: assignConstant(initialState)
// commit('resetState')
const assignConstant = data => state => Object.assign(state, data);


export {
  findByKey,
  set,
  toggle,
  pushTo,
  removeFrom,
  assignConstant
};
