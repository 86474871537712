<template>
  <div class="file-upload">
    <div class="file-upload__dropzone-wrapper">
      <b-progress
        v-show="progress < 100"
        :value="progress"
        class="file-upload__progress-bar"
      />
      <vue-dropzone
        id="dropzone"
        ref="dropzone"
        :options="dropzoneOptions"
        :data-testing="dataTesting"
        class="file-upload__dropzone"
        @vdropzone-success="onSuccess"
        @vdropzone-error="onError"
        @vdropzone-upload-progress="onUploadProgress"
      />
      <btn
        variant="secondary"
        icon="upload"
        class="file-upload__upload-btn"
        @click="onUploadBtnClick"
      >
        {{ $t('file_upload.choose_files') }}
      </btn>
    </div>

    <!-- @slot slot for displaying additional information -->
    <slot
      name="info"
      class="file-upload__info"
    />

    <div
      v-if="errors.length"
      class="file-upload__errors"
    >
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="file-upload__error"
        v-text="$t(error)"
      />
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import { BProgress } from 'bootstrap-vue';

/**
 * File upload component
 */
export default {
  name: 'FileUpload',
  components: {
    vueDropzone,
    'b-progress': BProgress
  },
  props: {
    /**
     * Access token for the API.
     */
    accessToken: {
      type: String,
      required: true
    },
    /**
     * Sets the tmp upload file path for the dropzone component.
     */
    uploadUrl: {
      type: String,
      required: true
    },
    /**
     * Sets the collection, where the files should be uploaded to.
     */
    collection: {
      type: String,
      required: true
    },
    /**
     * Sets the errors which are happening in the file-upload.
     */
    errors: {
      type: Array,
      default: () => []
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      progress: 100,
      uploadErrors: []
    };
  },
  computed: {
    dropzoneOptions() {
      return {
        url: this.uploadUrl,
        maxFilesize: 15, // MB
        headers: {
          Authorization: 'Bearer ' + this.accessToken
        },
        params: {
          collection: this.collection
        },
        dictDefaultMessage: this.$t('file_upload.default_message'),
        dictFileTooBig: 'file_too_big',
        dictInvalidFileType: 'invalid_file_type',
        addRemoveLinks: true,
        paramName: 'files[]',
        acceptedFiles: 'application/pdf,image/tiff,image/jpeg,image/jpg,image/png,.doc,.docx,.xls,.xlsx' // PDF, TIFF, JPG, PNG, Word, Excel,
      };
    }
  },
  methods: {
    /**
     * Gets called when the user clicks on the upload-button. Call is forwarded to the dropzone.
     * @event onUploadBtnClick
     * @type {Click}
     */
    onUploadBtnClick(e) {
      e.preventDefault();
      this.$refs.dropzone.$refs.dropzoneElement.click();
    },

    /**
     * Creates a new file object and emits it to the parent component.
     * @event onSuccess
     * @type {Change}
     */
    onSuccess(file, response) {
      if (response && response.data) {
        const files = [];

        response.data.forEach(file => {
          files.push({
            id: file.id,
            label: file.name
          });
        });

        /**
         * send all uploaded files to parent on success
         * @event filesAdded
         * @property {object} files
         */
        this.$emit('filesAdded', files);
      }
    },

    /**
     * Use to display progress
     */
    onUploadProgress(file, progress, bytesSent) {
      this.progress = progress;
    },

    /**
     * Emits the error event to the parent component.
     * @event onError
     * @type {VDropzone-Error}
     */
    onError(file, message, xhr) {
      if (message.errors && message.errors.files) {
        message = message.errors.files[0];
      }

      /**
       * Emits the error event to the parent component.
       * @event error
       * @property {object} error
       * */
      this.$emit('error', {
        file: file.name,
        message: message
      });
    }
  }
};
</script>

<style lang="scss">
  @import './FileUpload';
</style>
