<template>
  <validation-provider
    :name="id"
    :rules="validation"
    #default="{ errors }"
    slim
    ref="provider"
  >
    <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :state="errors.length === 0"
      :invalid-feedback="errors[0]"
      label-class="form-amount__label"
      class="form-amount"
    >
      <masked-input
        ref="formAmount"
        v-model="model"
        :mask="numberMask"
        :guide="false"
        :name="id"
        :required="required"
        :data-testing="dataTesting"
        type="text"
        autocomplete="off"
        class="form-amount__input"
        @blur="onBlur"
        @keydown.enter="onBlur"
      />
    </b-form-group>
  </validation-provider>
</template>

<script>
import { getNumberFromModel } from '@core/utils';
import { BFormGroup } from 'bootstrap-vue';
import MaskedInput from 'vue-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

/**
 * Standard form amount field
 */
export default {
  name: 'FormAmount',
  components: {
    'b-form-group': BFormGroup,
    MaskedInput
  },
  props: {
    /**
    * input id - it's used also as name attribute and for error handling
    */
    id: {
      type: String,
      required: true
    },
    /**
    * label for the bootstrap form group
    */
    label: {
      type: String,
      default: ''
    },
    /**
    * placeholder for input field
    */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * custom validation rules if needed
     */
    validation: {
      type: String,
      default: ''
    },
    /**
    * value to fill in data from the api
    */
    value: {
      type: Number,
      default: null
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      /**
       * @model
       */
      model: ''
    };
  },

  computed: {
    /**
     * Returns a mask for currency amount. Read more at:
     * https://github.com/text-mask/text-mask/tree/master/addons
     */
    numberMask() {
      return createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        requireDecimal: false,
        integerLimit: 16
      });
    },

    required() {
      return this.validation.indexOf('required') !== -1;
    }
  },

  // detect changes in store step data
  watch: {
    value(newVal) {
      if (this.model !== newVal) this.model = this.getModelFromNumber(newVal);
    }
  },

  mounted() {
    this.model = this.getModelFromNumber(this.value);
  },

  methods: {
    onBlur() {
      /**
      * update model and validate field
      * @event onBlur
      * @property {object} value - key, val
      */
      this.$emit('onchange', { key: this.id, val: this.getNumberFromModel(this.model) });
    },

    /**
     * returns a formatted model from a Number input
     * @param {Number} value
     */
    getModelFromNumber(value) {
      const valueString = '' + value;
      let valueFormatted = ',';
      let beforeComma = '';

      if (valueString.indexOf('.') !== -1) {
        const temp = valueString.split('.');
        beforeComma = temp[0];
        valueFormatted += temp[1].length === 1 ? temp[1] + '0' : temp[1];
      } else {
        beforeComma = valueString;
        valueFormatted += '00';
      }

      if (beforeComma.length > 3) {
        valueFormatted = beforeComma.slice(-3) + valueFormatted;
        beforeComma = beforeComma.slice(0, -3);

        while (beforeComma.length >= 1) {
          valueFormatted = beforeComma.slice(-3) + '.' + valueFormatted;
          beforeComma = beforeComma.slice(0, -3);
        }
      } else {
        valueFormatted = beforeComma + valueFormatted;
      }

      return valueFormatted;
    },

    /**
     * sanitize model
     * @param {String} value
     */
    getNumberFromModel(value) {
      return getNumberFromModel(value);
    }
  }
};

</script>

<style lang="scss">
  @import './FormAmount';
</style>
