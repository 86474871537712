<template>
  <transition name="fade">
    <div
      v-show="visible"
      :class="classList"
      class="notification"
    >
      <div class="notification__content">
        <label
          v-if="title"
          class="notification__title"
          v-text="title"
        />
        <div class="notification__content-inner">
          <icon
            v-if="type === 'file'"
            icon="paperclip"
            size="20"
            class="notification__icon"
          />
          <span
            class="notification__text"
            v-text="text"
          />
        </div>
      </div>
      <div class="notification__action">
        <btn
          v-if="removable"
          transparent
          class="notification__remove"
          @click="onRemoveClick"
        >
          <template v-if="type === 'file'">
            {{ $t("button.remove") }}
          </template>
        </btn>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@atoms/Icon/Icon';

/**
 * Notification component
 */
export default {
  name: 'Notification',
  components: {
    Icon
  },
  props: {
    /**
     * Notification status
     */
    status: {
      type: String,
      default: 'success',
      validator: value =>
        ['success', 'warning', 'error', 'info', 'default'].includes(value)
    },
    /**
     * Notification type
     */
    type: {
      type: String,
      default: 'file',
      validator: value => ['msg', 'file'].includes(value)
    },
    /**
     * Notification title
     */
    title: {
      type: String,
      default: null
    },
    /**
     * Notification text
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * Sets the removable prop
     */
    removable: {
      type: Boolean,
      default: false
    },
    /**
     * The Notifications visibility
     */
    visibility: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: this.visibility
    };
  },
  computed: {
    classList() {
      let classList = '';

      if (this.type === 'msg') {
        classList += ' notification--msg';
      }

      if (this.type === 'file') {
        classList += ' notification--file';
      }

      if (this.status.length) {
        classList += ' notification--' + this.status;
      }

      if (this.visible) {
        classList += ' notification--visible';
      }

      return classList;
    }
  },
  methods: {
    onRemoveClick() {
      if (this.type !== 'file') this.visible = false;

      /**
       * remove notification
       * @event remove
       */
      this.$emit('remove');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./Notification";
</style>
