<template>
  <template-default>
    <div class="template employees template--bg-gray template--navbar-fixed">
      <content-header
        slot="preview"
        class="content-header--bg"
        type="h1"
      >
        {{ $t('headline.employees') }}
      </content-header>
      <!--TODO To figure out how to create one modal for many actions-->
      <modal
        id="modalDelete"
        ref="modalDelete"
        :title="$t('headline.remove_employee')"
        :visible="deletedEmployee !== null"
        type="danger"
        ok-variant="danger"
        ok-title="button.delete"
        cancel-title="button.cancel"
        @ok="onDeleteEmployee"
        @cancel="onCloseDeleteDialog"
      >
        {{ $t('label.remove_employee') }}
      </modal>
      <modal
        id="modalResetPassword"
        ref="modalResetPassword"
        :title="$t('headline.reset_password_employee')"
        :visible="invitedEmployee !== null"
        type="danger"
        ok-variant="danger"
        ok-title="button.lock"
        cancel-title="button.cancel"
        @ok="onInviteEmployee"
        @cancel="onCloseResetDialog"
      >
        {{ $t('label.reset_employee_password') }}
      </modal>
      <table-interactive
        :is-loading="isLoading"
        :total-records="totalRecords"
        :columns="columns"
        :rows="rows"
        :row-style-class="rowStyleClass"
        :pagination-options="paginationOptions"
        :sort-options="sortOptions"
        class="content--margin container-fluid"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
      >
        <h2
          class="table-interactive__hl"
          v-text="$t('headline.total_employees', { total: totalRecords })"
        />
      </table-interactive>
    </div>
  </template-default>
</template>

<script>
import { mapState } from 'vuex';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import TableInteractive from '@components/TableInteractive/TableInteractive';
import privatePageMixin from '@theme/components/mixins/privatePageMixin';
import Modal from '@components/Modal/Modal';

export default {
  name: 'Employees',
  components: {
    TemplateDefault,
    ContentHeader,
    TableInteractive,
    Modal
  },
  mixins: [privatePageMixin],
  data() {
    return {
      paginationOptions: {
        enabled: true,
        mode: 'pages',
        perPage: 25,
        perPageDropdown: [25, 50, 75, 100],
        position: 'bottom',
        dropdownAllowAll: false,
        nextLabel: 'Vor',
        prevLabel: 'Zurück',
        rowsPerPageLabel: 'Ergebnisse pro Seite',
        ofLabel: 'von',
        pageLabel: 'Seite', // for 'pages' mode
        allLabel: 'Alle'
      },
      sortOptions: {
        enabled: true
      }
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.employees.isLoading,
      totalRecords: state => state.employees.total,
      rows: state => state.employees.tableBody,
      deletedEmployee: state => state.employees.deletedEmployee,
      invitedEmployee: state => state.employees.invitedEmployee,
      user: state => state.user.user
    }),
    columns() {
      return [
        {
          label: 'label.first_name',
          field: 'first_name',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.last_name',
          field: 'last_name',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.email',
          field: 'email',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.role',
          field: 'role',
          sortable: false
        },
        {
          label: 'label.state',
          field: 'state',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        }
      ];
    }
  },
  mounted() {
    if (this.$route.params) {
      let notificationText = null;

      switch (this.$route.params.redirectedFrom) {
        case 'create':
          notificationText = this.$t('create_user.messages.employee_created_text', {
            firstName: this.$route.params.firstName,
            lastName: this.$route.params.lastName
          });
          break;
        case 'edit':
          notificationText = this.$t('create_user.messages.employee_edited_text', {
            firstName: this.$route.params.firstName,
            lastName: this.$route.params.lastName
          });
          break;
      }

      if (this.notificationText) {
        this.$notify({
          text: notificationText,
          data: {
            type: 'success'
          }
        });
      }
    }

    this.$store.dispatch('employees/getEmployees');
  },
  methods: {
    rowStyleClass(row) {
      if (row.state === 'state_user.deleted') {
        return 'table-interactive__row--disabled';
      }
    },

    /**
       * Handle the change page event
       * @event onPageChange
       * @type {Click}
       */
    onPageChange(params) {
      this.$store.commit('employees/setPage', params.currentPage);
      this.$store.dispatch('employees/getEmployees');
    },

    /**
       * Handle per page event
       * @event onPerPageChange
       * @type {Click}
       */
    onPerPageChange(params) {
      if (this.isLoading) return;

      this.$store.commit('employees/setPage', 1);
      this.$store.commit('employees/setPerPage', params.currentPerPage);
      this.$store.dispatch('employees/getEmployees');
    },

    /**
       * Handle sort change event
       * @event onSortChange
       * @type {Click}
       */
    onSortChange(params) {
      this.$store.dispatch('employees/setSorting', { params });
      this.$store.dispatch('employees/getEmployees');
    },

    /**
       * Redirects to to new employee view
       * @event onClickCreateEmployee
       * @type {Click}
       */
    onClickCreateEmployee() {
      this.$router.push({
        name: 'newEmployee'
      });
    },

    /**
       * Closes the dialog
       * @event onCloseDeleteDialog
       * @type {Cancel}
       */
    onCloseDeleteDialog() {
      this.$store.commit('employees/setDeletedEmployee', null);
    },

    /**
       * Triggers the delete-employee action in the store
       * @event onDeleteEmployee
       * @type {Ok}
       */
    onDeleteEmployee() {
      this.$store.dispatch('employees/delete').then(() => {
        this.$store.dispatch('employees/getEmployees').then(() => {
          this.$notify({
            text: this.$t('general.delete_employee_notification_text', {
              firstName: this.deletedEmployee.firstName,
              lastName: this.deletedEmployee.lastName
            }),
            data: {
              type: 'success'
            }
          });

          this.$store.commit('employees/setDeletedEmployee', null);
        });
      });
    },

    /**
       * Triggers reset-password action in the store
       * @event onInviteEmployee
       * @type {click}
       */
    onInviteEmployee() {
      this.$store.dispatch('employees/invite').then(() => {
        this.$notify({
          // title: this.$t('general.reset_employee_password_notification_title'),
          text: this.$t('general.reset_employee_password_notification_text', {
            firstName: this.invitedEmployee.firstName,
            lastName: this.invitedEmployee.lastName
          }),
          data: {
            type: 'success'
          }
        });

        this.$store.commit('employees/setInvitedEmployee', null);
      });
    },

    /**
       * Close-reset password dialog
       * @event onCloseResetDialog
       * @type {click}
       */
    onCloseResetDialog() {
      this.$store.commit('employees/setInvitedEmployee', null);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import 'Employees';
</style>
