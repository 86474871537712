<template>
  <fieldset class="multistep--template">
    <legend class="multistep__legend">
      <!-- @slot current step title -->
      <slot />
    </legend>

    <template v-if="!isLoadingFields">
      <div class="multistep__row row">
        <form-selectbox-icons
          v-if="offices"
          :id="activeFieldsKey[0]"
          :value="officeValue"
          :options="offices"
          :placeholder="$t('placeholder.enter_select')"
          :label="$t('label.office_id')"
          :disabled="hasGuaranteeId || offices.length === 1"
          :readonly="offices.length === 1"
          class="col-12"
          validation="required"
          data-testing="select_office"
          @onchange="onChangeOffice"
        />
      </div>
      <div
        v-if="showTemplate"
        class="multistep__row row"
      >
        <form-autocomplete
          v-if="guarantees"
          :key="createTemplateId"
          :id="activeFieldsKey[1]"
          :value="activeTemplate(activeFields[activeFieldsKey[1]])"
          :options="guarantees"
          :is-loading="isLoadingGuarantees"
          :label="$t('label.template')"
          :placeholder="$t('placeholder.enter_template')"
          class="col-12"
          data-testing="select_template"
          @search="onSearch"
          @select="onChangeAutocomplete"
          @reset="onResetAutocomplete"
        >
          <p
            class="multistep__margin"
            v-html="$t('description.multistep_template')"
          />
        </form-autocomplete>
      </div>
    </template>
  </fieldset>
</template>

<script>
import FormSelectboxIcons from '@components/FormSelectboxIcons/FormSelectboxIcons';
import FormAutocomplete from '@components/FormAutocomplete/FormAutocomplete';
import { mapGetters } from 'vuex';

export default {
  name: 'MultistepTemplate',
  components: {
    FormSelectboxIcons,
    FormAutocomplete
  },
  computed: {
    ...mapGetters({
      activeFieldsKey: 'guarantee/create/activeFieldsKey',
      activeFields: 'guarantee/create/activeFields',
      isLoadingFields: 'guarantee/create/isLoadingFields',
      offices: 'offices/offices',
      hasOffices: 'offices/hasOffices',
      isLoadingGuarantees: 'guarantee/template/isLoading',
      guarantees: 'guarantee/template/guarantees',
      hasGuaranteeId: 'guarantee/create/hasGuaranteeId',
      activeTemplate: 'guarantee/template/activeTemplate'
    }),
    showTemplate() {
      return !!parseInt(this.activeFields[this.activeFieldsKey[0]]) && !this.hasGuaranteeId;
    },
    createTemplateId() {
      return 'template-' + (this.activeFields && this.activeFields.template_id) ? this.activeFields.template_id : 0;
    },
    officeValue() {
      let value;

      if (this.offices.length === 1) {
        this.onChangeOffice({ key: 'office_id', val: this.offices[0] });
        value = this.offices[0].value;
      } else {
        value = parseInt(this.activeFields[this.activeFieldsKey[0]]);
      }

      return value;
    }
  },
  mounted() {
    this.$store.dispatch('offices/getOffices').then(() => {
      this.$store.dispatch('guarantee/create/getData')
        .then(() => this.$store.dispatch('guarantee/create/adjustFormNavigation'));
    });
  },
  methods: {
    /**
    * change Field Value in Store (Model) and update office from current form
    * reset template_id and autocomplete for templates
    * @event onChangeOffice
    * @type {event}
    */
    onChangeOffice(param) {
      this.$store.dispatch('guarantee/create/setOffice', param);
      this.$store.commit('guarantee/template/setData', []);
      this.$store.dispatch('guarantee/create/setField', {
        key: 'template_id',
        val: null
      });
    },
    /*
    * set form data on selecting new beneficiary in autocomplete
    * @event onChangeAutocomplete
    * @type {event}
    */
    onChangeAutocomplete(param) {
      this.$store.dispatch('guarantee/create/setField', {
        key: 'template_id',
        val: param.id
      });
    },
    /*
    * set form data on selecting new beneficiary in autocomplete
    * @event onChangeAutocomplete
    * @type {event}
    */
    onResetAutocomplete() {
      this.$store.dispatch('guarantee/create/setField', {
        key: 'template_id',
        val: null
      });
    },
    /*
    * autocomplete for guarantee templates
    * @event onSearch
    * @param {string} query - search query
    */
    onSearch(query) {
      this.$store.dispatch('guarantee/template/sendQuery', query);
    }
  }
};
</script>
