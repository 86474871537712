<template>
  <div class="language-switcher">
    <b-dropdown
      id="dropdown"
      :text="formatLocale(current)"
      :data-testing="dataTesting"
      class="language-switcher__dropdown"
    >
      <b-dropdown-item
        v-for="(item, index) in lang"
        :key="index"
        class="dropdown-item"
        @click.stop="onSelectLang(item)"
      >{{ formatLocale(item) }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

/**
 * Language switcher for changing the locale
 */
export default {
  name: 'LanguageSwitcher',
  components: {
    'b-dropdown': BDropdown,
    'b-dropdown-item': BDropdownItem
  },

  props: {
    /**
     * Array of selectable locales
     */
    locales: {
      type: Array,
      required: true
    },
    /**
     * currently selected locale
     */
    current: {
      type: String,
      required: true
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },

  computed: {
    lang() {
      return this.locales.filter(item => item !== this.current);
    }
  },

  methods: {

    onSelectLang(value) {
      event.preventDefault();

      /**
       * Emit change event
       * @event change
       * @property value
       */
      this.$emit('change', value);
    },

    /**
     * Formats the locale to display in the language switcher
     *
     * @method formatLocale
     */
    formatLocale(locale) {
      return locale.includes('-') ? locale.split('-')[1] : locale;
    }
  }
};

</script>

<style lang="scss">
  @import './LanguageSwitcher';
</style>
