<template>
  <validation-provider
    :name="id"
    :rules="validation"
    #default="{ errors }"
    slim
    ref="provider"
  >
    <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :state="errors.length === 0"
      :invalid-feedback="errors[0]"
      :class="{ 'is-open': isOpen, 'form-autocomplete--placeholder': showPlaceholder, 'form-autocomplete--resettable': resettable, 'form-autocomplete--has-value': hasValue }"
      class="form-autocomplete"
      label-class="form-autocomplete__label"
    >
      <!-- @slot for label and text -->
      <slot />

      <div class="form-autocomplete__wrapper">
        <div class="form-autocomplete__input-container">
          <input
            ref="input"
            :id="id"
            :name="id"
            v-model="searchTerm"
            :placeholder="placeholder"
            :data-testing="dataTesting"
            class="form-autocomplete__input"
            type="text"
            @input="onSearch"
            @keydown.enter.exact="onEnter"
            @keyup.down.exact="onKeyDown"
            @keyup.up.exact="onKeyUp"
            @keyup.esc.exact="onClose"
            @focus="isOpen = true"
            @blur="onBlur"
          >

          <p
            v-if="showPlaceholder"
            class="form-autocomplete__placeholder"
            v-html="value.label"
          />

          <icon
            v-if="resettable && trimmedSearchTerm"
            icon="close"
            color="grey"
            color-hover="red"
            size="12"
            emits-click-event
            class="form-autocomplete__reset"
            @click="reset"
          />

        </div>
        <transition name="fade">
          <div
            v-if="openAutocomplete"
            key="wrapper"
            class="form-autocomplete__options"
          >
            <ul
              v-if="!isLoading && options.length"
              class="form-autocomplete__options-list"
            >
              <template v-for="(option, index) in options">
                <li
                  v-if="option.header"
                  :key="`${index}-header`"
                  class="form-autocomplete__option-title"
                  v-text="$t(option.header)"
                />
                <li
                  :key="index"
                  :class="{ 'form-autocomplete__option--highlighted': index === optionHighlighted }"
                  class="form-autocomplete__option"
                  @mouseenter="onHighlight(index)"
                  @mouseleave="onHighlight(index)"
                  @click="onSelectOption(option)"
                  v-html="option.label"
                />
              </template>
            </ul>

            <p
              v-if="!isLoading && options.length === 0"
              class="form-autocomplete__msg"
              v-text="$t('label.no_entry')"
            />

            <transition name="fade">
              <preloader
                v-if="isLoading"
                class="preloader--center-x-15 preloader--small"
              />
            </transition>
          </div>
        </transition>
      </div>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import debounce from 'lodash/debounce';
import Icon from '@atoms/Icon/Icon';

/**
* Standard autocomplete component
*/
export default {
  name: 'FormAutocomplete',
  components: {
    'b-form-group': BFormGroup,
    Icon
  },
  props: {
    /**
    * selectbox id - it's used also as name attribute for the hidden input field and for error handling
    */
    id: {
      type: String,
      default: ''
    },
    /**
    * label for the bootstrap form group
    */
    label: {
      type: String,
      default: ''
    },
    /**
    * placeholder for multiselect
    */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * custom validation rules if needed
     */
    validation: {
      type: String,
      default: ''
    },
    /**
    * value to fill in data from the api
    */
    value: {
      type: Object,
      default: null
    },
    /**
    * options to be displayed in the array
    */
    options: {
      type: Array,
      default: () => []
    },
    /**
    * loading indicator (API)
    */
    isLoading: {
      type: Boolean,
      default: false
    },
    /**
     * Adds a reset icon and function
     */
    resettable: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    },
    /**
     * to use value.value from store
     */
    updateSearchTerm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /**
      * @model
      */
      searchTerm: '',
      /**
       * Is true, when the autocomplete dropdown opens
       */
      isOpen: false,
      /**
       * option that is highlighted for keyEvents
       */
      optionHighlighted: 0,
      /**
       * id of the current option - it's used for enter event
       */
      activeId: 0
    };
  },
  computed: {
    trimmedSearchTerm() {
      return this.searchTerm.trim();
    },
    openAutocomplete() {
      return this.isOpen && this.trimmedSearchTerm && this.trimmedSearchTerm.length > 2;
    },
    showPlaceholder() {
      return this.hasValue && this.value.label && !this.isOpen;
    },
    hasValue() {
      return this.value != null;
    }

  },
  mounted() {
    if (this.updateSearchTerm && this.value && this.value.value) {
      this.searchTerm = this.value.value;
      this.activeId = this.value.id;
      /**
       * emit search with trimmedSearchTerm
       * @event search
       * @property {string} trimmedSearchTerm
      */
      this.$emit('search', this.trimmedSearchTerm);
    }
  },
  methods: {
    /**
     * emit set search term to add a new value to store
     * @event onBlur
     * @type {Blur}
    */
    onBlur() {
      this.optionHighlighted = 0;
      this.isOpen = false;

      if (this.updateSearchTerm) {
        /**
         * emit searchTerm on blur
         * @event set-search-term
         * @property {string} trimmedSearchTerm
        */
        this.$emit('set-search-term', this.trimmedSearchTerm);

        return;
      }

      if (this.value && this.value.value && this.trimmedSearchTerm !== '' && this.trimmedSearchTerm !== this.value.value) {
        this.$emit('set-search-term', this.trimmedSearchTerm);
      } else {
        this.clear();
      }
    },
    /**
     * update searchTerm blur input on esc key
     * @event onClose
     * @type {keyup}
    */
    onClose() {
      if (this.updateSearchTerm && this.value && this.trimmedSearchTerm !== this.value.value) this.searchTerm = this.value.value;

      this.$refs.input.blur();
    },
    /**
     * prevent form submit and select option if the activeId was changing
     * @event onEnter
     * @type {keyup}
    */
    onEnter(e) {
      const option = this.options[this.optionHighlighted];

      if (this.isOpen && option && option.id !== this.activeId) {
        e.preventDefault();

        this.onSelectOption(option);
      } else {
        this.onSearch();
      }

      this.$refs.input.blur();
      /**
       * if you hit the enter key the next element can be focused
       * @event focusnext
      */
      this.$emit('focusnext');
    },
    /**
     * Emits search event with the searchTerm
     * @event onSearch
     * @type {click|keyup}
    */
    onSearch: debounce(function() {
      this.$emit('search', this.trimmedSearchTerm);

      if (this.trimmedSearchTerm === '') this.reset();
    }, 400),

    /**
     * used for reset filter in guarantee
     * @method clear
    */
    clear() {
      this.searchTerm = '';
      this.activeId = 0;
    },

    /**
     * Resets the component & emits reset
     * @event reset
    */
    reset() {
      this.clear();
      /**
       * Resets the component & emits reset
       * @event reset
      */
      this.$emit('reset');
    },

    /**
     * Updates the highlighted value.
     * @event onKeyDown
     * @type {keyup}
     */
    onKeyDown() {
      if (this.isOpen && this.options.length) {
        this.optionHighlighted = (this.optionHighlighted + 1) % this.options.length;
      }
    },

    /**
     * Updates the highlighted value.
     * @event onKeyUp
     * @type {Keyup}
     */
    onKeyUp() {
      if (this.isOpen && this.options.length) {
        this.optionHighlighted = this.optionHighlighted === 0 ? this.options.length - 1 : this.optionHighlighted - 1;
      }
    },

    /**
     * Updates the highlighted value.
     * @event onHighlight
     * @type {mouseenter|mouseleave}
     */
    onHighlight(index) {
      this.optionHighlighted = index;
    },

    /**
     * Gets triggered when a suggestion of the suggestion-dropdown is selected.
     * @event onSelectSuggestion
     * @type {click}
     */
    onSelectOption(option) {
      if (this.updateSearchTerm) {
        this.searchTerm = option.value;
      }

      this.activeId = option.id;

      this.$emit('search', this.trimmedSearchTerm);
      /**
       * event to send the selected option in the autocomplete
       * @event select
       * @property {object} option
      */
      this.$emit('select', option);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './FormAutocomplete';
</style>
