<template>
  <div class="template-fullscreen">
    <notices
      :duration="7000"
      position="top"
      width="100%"
    />
    <img
      src="@img/logo.svg"
      alt="BTV Vier Länder Bank"
      class="template-fullscreen__logo"
    >
    <div class="template-fullscreen__wrapper">
      <img
        src="@img/garantieservice.svg"
        alt="Garantieservice"
        class="template-fullscreen__label"
      >
      <slot />
    </div>
  </div>
</template>

<script>
import Notices from '@components/Notices/Notices';

export default {
  name: 'TemplateFullscreen',
  components: {
    Notices
  }
};
</script>
