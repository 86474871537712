const state = {
  items: [
    {
      title: 'navigation.navbar.my_guarantees',
      link: '/guarantees'
    },
    {
      title: 'navigation.navbar.faqs',
      link: '/faqs'
    },
    /*,
    {
      title: 'navigation.navbar.documents',
      link: '/documents'
    }, */
    {
      title: 'navigation.navbar.history',
      link: '/history'
    }
  ]
};

export default {
  namespaced: true,

  state
};
