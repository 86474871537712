<template>
  <template-fullscreen>
    <password-forgotten-form />
  </template-fullscreen>
</template>
<script>
import TemplateFullscreen from '@theme/router/views/templates/TemplateFullscreen';
import PasswordForgottenForm from '@components/PasswordForgottenForm/PasswordForgottenForm';

export default {
  name: 'PasswordForgotten',
  components: {
    TemplateFullscreen,
    PasswordForgottenForm
  }
};
</script>
