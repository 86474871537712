var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav',{staticClass:"FormNavigation flex-column list-unstyled"},_vm._l((_vm.menuItems),function(item,key){return _c('b-nav-text',{key:key,staticClass:"FormNavigation__navbar-item",class:{
      'FormNavigation__navbar-item--active': item.active,
      'FormNavigation__navbar-item--completed': item.completed
    },attrs:{"tag":"li"}},[_c('span',{staticClass:"FormNavigation__nav-icon",class:{
        'FormNavigation__nav-icon--completed': item.completed,
        'FormNavigation__nav-icon--disabled': item.disabled,
        'FormNavigation__nav-icon--active': item.active
      },domProps:{"textContent":_vm._s(!item.completed ? key + 1 : null)}}),_c('span',{class:{ 'FormNavigation__navbar-link-wrapper--active': item.active }},[_c('a',{staticClass:"FormNavigation__navbar-link",class:{
          'FormNavigation__navbar-link--active': item.active,
          'FormNavigation__navbar-link--completed': item.completed,
          'FormNavigation__navbar-link--disabled': item.disabled
        },attrs:{"href":"javascript:undefined"},on:{"click":function($event){return _vm.onClick(key, item)}}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }