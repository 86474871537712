<template>
  <div class="row">
    <div class="col-lg-1" />
    <div class="timeline col-lg-10">
      <div class="timeline__sidebar">
        <div class="timeline__date">
          <div
            class="timeline__day"
            v-text="getDay"
          />
          <div
            class="timeline__micro"
            v-text="getMonthYear"
          />
        </div>
        <div class="timeline__line" />
      </div>
      <div class="timeline__events">
        <timeline-item
          v-for="(event, index) in events"
          :key="index"
          :event="event"
        />
      </div>
    </div>
    <div class="col-lg-1" />
  </div>
</template>

<script>
import TimelineItem from '@components/TimelineItem/TimelineItem';
import moment from 'moment';

export default {
  name: 'Timeline',
  components: {
    TimelineItem
  },
  props: {
    /**
       * Date of the timeline
       */
    date: {
      type: String,
      required: true
    },
    /**
       * Events of this date
       */
    events: {
      type: Array,
      required: true
    }
  },
  computed: {
    getDay() {
      return moment(this.date).format('DD');
    },
    getMonthYear() {
      return moment(this.date).format('MMMM YYYY');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './Timeline.scss';
</style>
