<template>
  <modal
    id="supervisor-modal"
    ref="modal"
    :title="$t('headline.send_guarantee_to_approve')"
    :ok-disabled="activeSupervisor === null"
    modal-class="supervisor-modal"
    ok-title="button.send_guarantee_to_approve"
    ok-variant="danger"
    @ok="onApprove"
    @cancel="onClose"
  >
    <span class="supervisor-modal__header-note">{{ $t('headline.send_guarantee_to_approve_header_note') }}</span>
    <div
      class="supervisor-modal__wrapper"
      data-testing="section_supervisor_select">
      <div
        v-for="(item, key) in supervisors"
        :key="key"
        class="supervisor-modal__container"
      >
        <div
          :class="{'supervisor-modal__item': true,'supervisor-modal__item--active': item.id === activeSupervisor}"
          :data-testing="'supervisor_' + item.id"
          @click="markAsActive(item.id)"
        >
          <div class="supervisor-modal__content">
            <div class="supervisor-modal__content-info">
              <span
                class="supervisor-modal__firstname"
                v-text="item.full_name"
              />
            </div>
            <span>
              <a
                :href="`mailto:${item.email}`"
                class="supervisor-modal__email"
                @click.stop
                v-text="item.email"
              />
            </span>
          </div>
          <icon
            v-if="item.id === activeSupervisor"
            icon="success"
            color="red"
            size="20"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@components/Modal/Modal';
import Icon from '@atoms/Icon/Icon';

export default {
  name: 'SupervisorModal',
  components: {
    Modal,
    Icon
  },
  props: {
    supervisors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeSupervisor: null
    };
  },
  methods: {
    /**
     * Submit the form
     *
     * @event onApprove
     * @type {click}
     */
    onApprove(event) {
      if (event.target.tagName !== 'TEXTAREA') {
        this.$emit('submit');
      }
    },
    /**
     * Set active item
     *
     * @event markAsActive
     * @type {click}
     * @param id
     */
    markAsActive(id) {
      this.activeSupervisor = this.activeSupervisor === id ? null : id;
      this.$emit('change', this.activeSupervisor);
    },
    /**
     * Open internal modal
     */
    open() {
      this.$refs.modal.show();
    },
    /**
       * @event onClose
       * @type {click}
       */
    onClose() {
      this.activeSupervisor = null;
    }
  }
};
</script>

<style lang="scss">
  @import './SupervisorModal';
</style>
