<template>
  <template-default>
    <article class="template template--bg-gray template--navbar-fixed">
      <content-header
        slot="preview"
        class="content-header--bg"
        type="h1"
      >
        {{ $t('headline.history') }}
      </content-header>
      <filter-list
        :items="['status', 'date-from', 'date-until', 'search']"
        class="container-fluid"
        @reset="onFilterReset"
      >
        <form-selectbox
          id="state-filter"
          slot="status"
          ref="statusFilter"
          :value="filter.event"
          :options="stateFiltersOptions"
          placeholder="Art"
          @onchange="onChangeStatus"
        />
        <form-date-picker
          id="date-filter-from"
          ref="dateFilterFrom"
          slot="date-from"
          placeholder="von"
          variant="filter"
          @open="onFromDateOpen"
          @change="onFromDateChanged"
        />
        <form-date-picker
          id="date-filter-until"
          ref="dateFilterUntil"
          slot="date-until"
          :min-date="minDateUntil"
          placeholder="bis"
          variant="filter"
          @open="onUntilDateOpen"
          @change="onUntilDateChanged"
        />
        <search
          id="search-filter"
          slot="search"
          ref="searchFilter"
          :placeholder="$t('placeholder.search')"
          variant="filter"
          @search="onSearch"
          @reset="onSearchReset"
        />
      </filter-list>
      <div class="history__content content container-fluid">
        <div class="history__timeline-wrapper">
          <preloader
            v-if="isLoading"
            show-overlay
            class="preloader--center-circle"
          />
          <timeline
            v-for="(item, key) in history"
            :key="key"
            :date="item.date"
            :events="item.events"
            class="history__timeline"
          />
        </div>
      </div>
    </article>
  </template-default>
</template>

<script>
import { mapState } from 'vuex';
import { dateApi } from '@theme/utils/datefns';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import Timeline from '@components/Timeline/Timeline';
import FilterList from '@components/FilterList/FilterList';
import FormSelectbox from '@components/FormSelectbox/FormSelectbox';
import FormDatePicker from '@components/FormDatePicker/FormDatePicker';
import Search from '@components/Search/Search';

export default {
  name: 'History',
  components: {
    TemplateDefault,
    ContentHeader,
    Timeline,
    FilterList,
    FormSelectbox,
    FormDatePicker,
    Search
  },
  data() {
    return {
      stateFiltersOptions: [
        {
          label: 'Alle',
          value: 'all'
        },
        {
          label: 'Benachrichtigung',
          value: 'notification'
        },
        {
          label: 'Statusänderung',
          value: 'state'
        },
        {
          label: 'Information',
          value: 'info'
        },
        {
          label: 'Erfolgreich',
          value: 'success'
        },
        {
          label: 'Warnung',
          value: 'warning'
        },
        {
          label: 'Fehler',
          value: 'error'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      history: state => state.history.history,
      isLoading: state => state.history.isLoading,
      filter: state => state.history.filter
    }),
    minDateUntil() {
      return this.filter && this.filter.created_at_from ? dateApi(this.filter.created_at_from) : null;
    }
  },
  mounted() {
    this.$store.dispatch('history/getHistory');
  },
  methods: {
    onFromDateOpen() {
      this.$refs.dateFilterUntil.close();
    },
    onUntilDateOpen() {
      this.$refs.dateFilterFrom.close();
    },
    onChangeStatus(params) {
      this.$store.commit('history/setFilter', { event: params.val.value });
      this.$store.dispatch('history/getHistory');
    },
    onFromDateChanged(value) {
      this.$store.commit('history/setFilter', { created_at_from: value });
      this.$store.dispatch('history/getHistory');
    },
    onUntilDateChanged(value) {
      this.$store.commit('history/setFilter', { created_at_until: value });
      this.$store.dispatch('history/getHistory');
    },
    onSearch(searchTerm) {
      this.$store.commit('history/setFilter', { search: searchTerm });
      this.$store.dispatch('history/getHistory');
    },
    onSearchReset() {
      this.$store.commit('history/clearFilter', 'search');
      this.$store.dispatch('history/getHistory');
    },
    /**
       * Resets all the filters and dispatches getHistory
       * @event onFilterReset
       * @type {Reset}
       */
    onFilterReset() {
      this.$store.commit('history/clearAllFilters');
      this.$store.commit('history/setFilter', { event: 'all' });
      this.$refs.dateFilterFrom.reset();
      this.$refs.dateFilterUntil.reset();
      this.$refs.searchFilter.reset();

      this.$store.dispatch('history/getHistory');
    }
  }
};
</script>

<style lang='scss' scoped>
  @import 'History';
</style>
