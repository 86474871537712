import api from '@core/api/';
import { set, toggle } from '@core/store/utils';
const state = {
  comments: null,
  isLoading: false
};

const actions = {
  /**
  * get api data and save it to the comments object
  * @method getOverview
  */
  getComments({ commit }, guaranteeId) {
    commit('toggleLoading');

    api.getApiData('guarantees/' + guaranteeId + '/comments').then(({ data: responseData }) => {
      if (responseData && responseData.data) {
        commit('setComments', responseData.data);
      }

      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error, '/error');
      commit('toggleLoading');
    });
  }
};
const mutations = {
  toggleLoading: toggle('isLoading'),
  setComments: set('comments')
};

export default {
  namespaced: true,

  state,
  actions,
  mutations
};
