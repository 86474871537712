<template>
  <validation-observer #default="{ handleSubmit }" slim>
    <b-form
      novalidate
      autocomplete="off"
      class="login-form"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <b-form-group class="login-form__wrapper">
        <legend
          class="login-form__label"
          v-text="$t(title)"
        />

        <form-input
          id="username"
          ref="username"
          :label="$t('label.email')"
          :placeholder="$t('placeholder.enter_email')"
          class="login-form__form-input"
          variant="minimal"
          type="email"
          validation="required|email"
          data-testing="input_username"
        />
        <form-input
          id="password"
          ref="password"
          :label="$t('label.password')"
          :placeholder="$t('placeholder.enter_password')"
          type="password"
          variant="minimal"
          validation="required"
          class="login-form__form-input"
          show-password-icon
          data-testing="input_password"
        />
        <div
          v-if="externalError !== null"
          class="invalid-feedback-box"
          v-html="$t('validation.messages.' + externalError.msg)"
        />
        <btn
          variant="primary"
          class="login-form__btn"
          type="submit"
          data-testing="button_submit"
          v-text="$t('label.signin')"
        />
        <router-link
          to="password-forgotten"
          class="login-form__link"
          v-text="$t('button.password_forgotten')"
        />
      </b-form-group>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormGroup } from 'bootstrap-vue';
import FormInput from '@components/FormInput/FormInput';

/**
 * Standard login form
 */
export default {
  name: 'LoginForm',
  components: {
    'b-form': BForm,
    'b-form-group': BFormGroup,
    FormInput
  },
  props: {
    title: {
      type: String,
      default: 'label.login'
    },
    externalError: {
      type: Object,
      default: null
    }
  },
  methods: {
    /**
     * Dispatches the login action
     * @event onSubmit
     * @type {Submit}
     */
    onSubmit() {
      this.$store.dispatch('user/postLogin', {
        email: this.$refs.username.model,
        password: this.$refs.password.model
      }).then(result => { // This is a fallback, if the login is called and the user is already logged in
        this.$store.dispatch('user/getUser').then(() => {
          this.$store.dispatch('guarantee/create/setUserCred');
          this.$user.setPermissions(this.$store.getters['user/getPermissions']);
        });
      });
    }
  }
};
</script>

<style lang="scss">
  @import './LoginForm';
</style>
