<template>
  <div data-testing="section_comments">
    <comment
      v-for="(comment, index) in comments"
      :key="index"
      :date="new Date(comment.created_at)"
      :author="comment.user"
      :text="comment.body"
      class="multistep__comment"
    />
  </div>
</template>

<script>
import Comment from '@components/Comment/Comment';

export default {
  name: 'MultistepSummaryComments',
  components: {
    Comment
  },
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  }
};
</script>
