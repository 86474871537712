<template>
  <validation-provider
    :name="id"
    :rules="validation"
    #default="{ errors }"
    slim
    ref="provider"
  >
    <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :state="errors.length === 0"
      :invalid-feedback="errors[0]"
      label-class="form-textarea__label"
      class="form-textarea"
    >
      <textarea
        ref="textarea"
        v-model="model"
        :id="id"
        :name="id"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="{
          'form-textarea__widget': true,
          'form-textarea__widget--disabled': disabled
        }"
        :data-testing="dataTesting"
        rows="4"
        @blur="onBlur"
        @input="onInput"
      />
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';

/**
 * Textarea component
 */
export default {
  name: 'FormTextarea',
  components: {
    'b-form-group': BFormGroup
  },
  props: {
    /**
     * textarea id - it's used also as name attribute and for error handling
     */
    id: {
      type: String,
      required: true
    },
    /**
     * label for the bootstrap form group
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * placeholder for input field
     */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * value to fill in data from the api
     */
    value: {
      type: String,
      default: null
    },
    /**
     * custom validation rules if needed
     */
    validation: {
      type: String,
      default: ''
    },
    /**
     * disabled state
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      /**
       * @model
       */
      model: ''
    };
  },
  watch: {
    value(newVal) {
      if (this.model !== newVal) this.model = newVal;
    }
  },
  mounted() {
    if (this.value) this.model = this.value;
  },
  methods: {
    onBlur() {
      /**
       * update model and validate field
       * @event onchange
       * @property {object} value - key, val
       */
      this.$emit('onchange', { key: this.id, val: this.model });
    },
    onInput() {
      /**
       * Notify parent component
       * @event oninput
       * @property {object} model
       */
      this.$emit('oninput', this.model);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./FormTextarea";
</style>
