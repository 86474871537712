import api from '@core/api/';
import { set, toggle } from '@core/store/utils';
import qs from 'qs';

const state = {
  isLoading: false,
  tableBody: [],
  pagination: {
    perPage: 25,
    page: 1
  },
  sorting: {},
  total: 0,
  errors: null,
  employee: {
    title: 'Frau',
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  },
  // TODO Create one property for many actions for employee
  deletedEmployee: null,
  invitedEmployee: null,
  supervisors: []
};

const actions = {
  /**
   * Fetch supervisors by accountId
   * @method getSupervisors
   */
  getSupervisors({ commit }, accountId) {
    return new Promise((resolve, reject) => {
      api.getApiData(`accounts/${accountId}/supervisors`).then(({ data }) => {
        commit('setSupervisors', data.data);
        resolve(data.data);
      }).catch((error) => {
        api.apiError(error);
        reject();
      });
    });
  },

  /**
   * GET request to the API to receive the employees of given supervisor.
   * @method getEmployees
   */
  getEmployees({ state, dispatch, commit }) {
    commit('setLoading', true);

    const payload = {
      per_page: state.pagination.perPage,
      page: state.pagination.page,
      sort: state.sorting
    };

    const query = qs.stringify(payload);
    return api.getApiData(`/employees?${query}`).then(({ data: responseData }) => {
      responseData.data.forEach(item => item.state = `state_user.${item.state}`);

      commit('setTotal', responseData.data.length);
      commit('setTableBody', responseData.data);
      commit('setLoading', false);

      if (responseData.data) {
        dispatch('setRowsActions', { rows: responseData.data });
      }
    }).catch(() => {
      commit('setLoading', false);
    });
  },

  /**
   * POST request to the API to create a new user.
   * @method createEmployee
   */
  createEmployee({ commit }, employee) {
    commit('setLoading', true);

    return new Promise((resolve, reject) => {
      api.postApiData('/employees', employee).then(() => {
        resolve();
        commit('setErrors', null);
        commit('setLoading', false);
      }).catch(error => {
        commit('setLoading', false);
        if (error.response && error.response.data && error.response.data.errors) {
          commit('setErrors', error.response.data.errors);
        }
        reject(error);
      });
    });
  },

  /**
   * PATCH request to the API to update user.
   * @method editEmployee
   */
  editEmployee({ commit }, { id, data }) {
    commit('setLoading', true);

    return new Promise((resolve, reject) => {
      api.patchApiData(`/employees/${id}`, data).then(() => {
        resolve();
        commit('setErrors', null);
        commit('setLoading', false);
      }).catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          commit('setErrors', error.response.data.errors);
        }
        reject(error);
      });
    });
  },

  /**
   * GET Receive employee by ID
   * @method getEmployee
   */
  getEmployee({ commit }, id) {
    commit('setLoading', true);

    return api.getApiData(`/employees/${id}`).then(({ data: responseData }) => {
      commit('setEmployee', responseData.data);
      commit('setErrors', null);
      commit('setLoading', false);
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('setErrors', error.response.data.errors);
      }
    });
  },

  /**
   * Sets the deleted employee id, needed for the dropdown action
   * @method setDeletedEmployee
   */
  setDeletedEmployee({ commit }, value) {
    commit('setDeletedEmployee', value);
  },

  /**
   * Sets the deleted employee id, needed for the dropdown action
   * @method setDeletedEmployee
   */
  setInvitedEmployee({ commit }, employee) {
    commit('setInvitedEmployee', employee);
  },

  /**
    * Deletes existing user
    * @method delete
    */
  delete({ state, commit }) {
    if (!state.deletedEmployee) return;

    commit('toggleLoading');

    return api.deleteApiData(`employees/${state.deletedEmployee.id}`).then(() => {
      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error, '/error');
      commit('toggleLoading');
    });
  },

  /**
   * Reset employee password
   * @method setRowsActions
   */
  invite({ commit, state }) {
    commit('toggleLoading');

    if (!state.invitedEmployee) return;

    // POST /invites/{token}

    const payload = { email: state.invitedEmployee.email };
    return new Promise((resolve, reject) => {
      return api.postApiData(`/employees/${state.invitedEmployee.id}/reset-password`, payload).then(() => {
        commit('toggleLoading');
        resolve();
      }).catch((error) => {
        reject(error);
        api.apiError(error, '/error');
        commit('toggleLoading');
      });
    });
  },

  /**
   * Sets the actions for every user
   * @method setRowsActions
   */
  setRowsActions({ state }, { rows }) {
    return new Promise((resolve, reject) => {
      rows.forEach((row) => {
        const defaultActions = [
          {
            action: 'employees/setInvitedEmployee',
            label: 'button.lock',
            type: 'button',
            value: {
              id: row.id,
              email: row.email
            }
          },
          {
            action: 'employees/setDeletedEmployee',
            label: 'button.delete',
            type: 'button',
            value: {
              id: row.id,
              firstName: row.first_name,
              lastName: row.last_name
            }
          }
        ];

        if (row.state === 'state_user.active') {
          defaultActions.unshift({
            action: `employees/${row.id}/edit`,
            label: 'button.edit',
            type: 'link'
          });
        }

        row.actions = defaultActions;
      });

      resolve();
    });
  },

  /**
   * Sets the sorting parameters.
   * @method setSorting
   */
  setSorting({ commit }, { params }) {
    const sorts = [];

    params.forEach((el) => {
      sorts.push(el.type === 'asc' ? el.field : `-${el.field}`);
    });

    commit('setSort', sorts.toString());
  }
};

const mutations = {
  setTableBody: set('tableBody'),
  setTotal: set('total'),
  setLoading: set('isLoading'),
  setSort: set('sorting'),
  setErrors: set('errors'),
  toggleLoading: toggle('isLoading'),
  setDeletedEmployee: set('deletedEmployee'),
  setInvitedEmployee: set('invitedEmployee'),
  setEmployee: set('employee'),
  setSupervisors: set('supervisors'),
  setPerPage: (state, value) => state.pagination.perPage = value,
  setPage: (state, value) => state.pagination.page = value
};

export default {
  namespaced: true,

  state,
  actions,
  mutations
};
