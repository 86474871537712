import { set, toggle } from '@core/store/utils';
import dummyData from '@theme/store/dummydata/content';

const state = {
  isLoading: false,
  content: {}
};

const getters = {
  title: state => state.content.title
};

const actions = {
  get({ commit }) {
    commit('toggleLoading');
    commit('setData', dummyData);
    commit('toggleLoading');
  }
};

const mutations = {
  setData: set('content'),
  toggleLoading: toggle('isLoading')
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
