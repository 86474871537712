import api from '@core/api/';
import { set, toggle } from '@core/store/utils';

const state = {
  menuItemsLeft: [],
  menuItemsRight: []
};

const actions = {
  /**
  * Get Links
  * @method getLinks
  */
  getLinks({ commit }) {
    commit('toggleLoading');

    api.getApiData('/menu-items').then(({ data }) => {
      if (data && data.data && data.data) {
        const currItems = [];
        const currLinks = [];

        data.data.forEach((item) => {
          switch (item.location.toString()) {
            case 'footer_left':
              currItems.push(item);
              break;
            case 'footer_right':
              currLinks.push(item);
              break;
          }
        });

        commit('setLeftMenu', currItems);
        commit('setRightMenu', currLinks);
      }

      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      commit('toggleLoading');
    });
  }
};

const mutations = {
  setLeftMenu: set('menuItemsLeft'),
  setRightMenu: set('menuItemsRight'),
  toggleLoading: toggle('isLoading')
};

export default {
  namespaced: true,

  state,
  actions,
  mutations
};
