<template>
  <div>
    <notices
      :duration="7000"
      position="top"
      width="100%"
    />
    <navbar />
    <slot />
    <footerbar />
  </div>
</template>

<script>
import Navbar from '@theme/components/Navbar/Navbar';
import Footerbar from '@theme/components/Footerbar/Footerbar';
import Notices from '@components/Notices/Notices';

export default {
  name: 'TemplateDefault',
  components: {
    Navbar,
    Footerbar,
    Notices
  }
};
</script>
