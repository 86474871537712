<template>
  <template-default>
    <modal
      id="modalDelete"
      ref="modalDelete"
      :title="$t('button.delete')"
      type="danger"
      ok-title="button.delete"
      ok-variant="danger"
      cancel-title="button.cancel"
      class="content-header__modal"
      data-testing="modal_delete_guarantee"
      @ok="onDeleteGuarantee"
    >
      {{ $t("label.cancel_guarantee") }}
    </modal>
    <supervisor-modal
      ref="supervisorModal"
      :supervisors="supervisors"
      @change="onChangeSupervisor"
      @submit="onSubmit"
    />
    <article class="template template--navbar-fixed">
      <content-header
        slot="preview"
        :index="activeIndex"
        type="h1"
        class="content-header--numbers"
      >
        {{ $t("headline.create_guarantee") }}
        <div
          v-if="showNumbers"
          slot="numbers"
          class="content-header__guarantee-numbers"
        >
          <editable-label
            v-if="referenceNumber"
            id="referenceNumber"
            ref="referenceNumber"
            :number="referenceNumber"
            validation="min:3"
            :label="$t('numbers.reference_number')"
            is-editable
            class="content-header__guarantee-number content-header__guarantee-number--second"
            data-testing="edit_reference_number"
            @change="onReferenceNumberChange"
          />
        </div>

        <div
          v-if="hasGuaranteeId"
          slot="buttons"
          class="content-header__buttons"
        >
          <btn
            variant="secondary"
            icon="delete"
            class="content-header__btn content-header__btn--margin btn--icon"
            data-testing="button_delete_guarantee"
            @click="onClickDelete"
          >
            {{ $t("button.delete") }}
          </btn>
          <btn
            :disabled="activeIndex < 2"
            variant="primary"
            icon="save"
            class="content-header__btn content-header__btn--margin btn--icon"
            data-testing="button_save_guarantee"
            @click="onClickSave"
          >
            {{ $t("button.save") }}
          </btn>
        </div>
      </content-header>
      <preloader
        v-show="isLoading || isLoadingGuarantee"
        show-overlay
      />
      <multistep
        ref="form"
        :index="activeIndex"
        :disable-submit="disableSubmitBtn"
        :additional="activeIndex === 5"
        :comments="activeIndex === 5"
        :message="userMessage"
        :main-container-class="
          activeStep.component === 'MultistepSummary'
            ? 'multistep__wrapper--summary'
            : ''
        "
        @submit="activeIndex !== 5 ? onSubmit() : openSupervisorModal()"
        @reset="onReset"
      >
        <notification
          v-if="dateChanged.length"
          :text="dateChanged"
          type="msg"
          status="error"
          removable
        />
        <form-navigation
          slot="navigation"
          :menu-items="formNavigation"
          @click="goToStep"
        />
        <component
          :is="activeStep.component"
          @goToStep="goToStep"
          @ondisable="onDisableSubmit"
        >
          {{ $t(activeStep.title) }}
        </component>
        <component
          slot="additionalRow"
          :is="activeStep.component + 'Additional'"
          @ondisable="onDisableSubmit"
        />
        <div
          v-if="userErrors"
          class="multistep__user-errors container-fluid">
          <span
            class="multistep__user-errors-msg"
            v-text="userErrors" />
        </div>
      </multistep>
    </article>
  </template-default>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { dateFn } from '@theme/utils/datefns';

import ContentHeader from '@components/ContentHeader/ContentHeader';
import Multistep from '@components/Multistep/Multistep';
import EditableLabel from '@components/EditableLabel/EditableLabel';
import Modal from '@components/Modal/Modal';
import FormNavigation from '@components/FormNavigation/FormNavigation';
import Notification from '@components/Notification/Notification';

import TemplateDefault from '@theme/router/views/templates/TemplateDefault';

import MultistepGuarantee from '@theme/components/Multistep/MultistepGuarantee';
import MultistepBeneficiary from '@theme/components/Multistep/MultistepBeneficiary';
import MultistepTemplate from '@theme/components/Multistep/MultistepTemplate';
import MultistepModelText from '@theme/components/Multistep/MultistepModelText';
import MultistepDocuments from '@theme/components/Multistep/MultistepDocuments';
import MultistepSummary from '@theme/components/Multistep/MultistepSummary';
import SupervisorModal from '@theme/components/Modal/SupervisorModal';
import MultistepSummaryAdditional from '@theme/components/Multistep/MultistepSummaryAdditional';

export default {
  name: 'Guarantee',
  components: {
    ContentHeader,
    TemplateDefault,
    Multistep,
    MultistepGuarantee,
    MultistepBeneficiary,
    MultistepTemplate,
    MultistepDocuments,
    MultistepModelText,
    EditableLabel,
    MultistepSummary,
    MultistepSummaryAdditional,
    Modal,
    SupervisorModal,
    FormNavigation,
    Notification
  },
  data() {
    return {
      isLoading: false,
      disableSubmit: false,
      showSupervisorModal: false
    };
  },
  computed: {
    ...mapState({
      isLoadingGuarantee: state => state.guarantee.isLoadingGuarantee,
      steps: state => state.guarantee.create.steps,
      apiErrors: state => state.guarantee.create.errors,
      isRedirected: state => state.guarantee.create.isRedirected,
      supervisors: state => state.employees.supervisors,
      user: state => state.user.user,
      notification: state => state.guarantee.create.notification
    }),
    ...mapGetters({
      currentState: 'guarantee/create/currentState',
      activeIndex: 'guarantee/create/activeIndex',
      activeStep: 'guarantee/create/activeStep',
      activeFieldsKey: 'guarantee/create/activeFieldsKey',
      referenceNumber: 'guarantee/numbers/referenceNumber',
      guaranteeNumber: 'guarantee/numbers/guaranteeNumber',
      hasGuaranteeId: 'guarantee/create/hasGuaranteeId',
      officeId: 'guarantee/create/officeId',
      formNavigation: 'guarantee/create/getFormNavigation'
    }),
    dateChanged() {
      if (this.notification) {
        if (this.notification.valid_from) {
          return this.$t('general.validation.update_old_date', {
            field: this.$t('label.runtime_from'),
            date: dateFn(this.notification.valid_from)
          });
        } else if (this.notification.valid_until) {
          return this.$t('general.validation.update_old_date', {
            field: this.$t('label.runtime_until'),
            date: dateFn(this.notification.valid_until)
          });
        }
      }

      return '';
    },
    currentSlugIndex() {
      return this.$store.getters['guarantee/create/currentSlugIndex'](
        this.$route.params.step
      );
    },
    showNumbers() {
      return (
        this.hasGuaranteeId &&
        (this.guaranteeNumber !== null || this.referenceNumber !== null)
      );
    },
    office() {
      return this.$store.getters['offices/currentOffice'](this.officeId);
    },
    disableSubmitBtn() {
      if (this.disableSubmit) return true;

      if (this.currentState) {
        return (
          this.currentState !== 'draft' &&
          this.currentState !== 'changes_requested'
        );
      }

      return false;
    },
    userMessage() {
      if (
        this.currentState &&
        this.currentState !== 'draft' &&
        this.currentState !== 'changes_requested'
      ) {
        return this.$t(
          `general.validation.request_approval.${this.currentState}`
        );
      } else if (
        this.apiErrors &&
        this.apiErrors.errors &&
        this.apiErrors.errors.state
      ) {
        return this.$t('general.validation.request_approval.error');
      }

      return '';
    },
    userErrors() {
      if (this.apiErrors && this.apiErrors.errors) {
        if (
          this.apiErrors.errors.files &&
          this.apiErrors.errors.files[0] === 'validation.max.array'
        ) {
          return this.$t('file_upload.validation.max_array');
        }
      }
      return null;
    }
  },

  watch: {
    isRedirected(newVal) {
      if (newVal) {
        this.$notify({
          text: this.$t('general.redirection_form'),
          data: {
            type: 'warning'
          }
        });
      }
    }
  },
  created() {
    this.isLoading = true;

    if (this.$route.params.id === 'new') {
      this.reloadStep();
    } else {
      this.$store.dispatch('guarantee/create/getGuarantee', this.$route.params.id).then(() => {
        if (!this.hasGuaranteeId && this.$route.params.id) {
          this.reloadStep();
        }
      });
    }

    this.isLoading = false;
  },
  methods: {
    /**
     * Deletes a guarantee during the form process.
     * @event onDeleteGuarantee
     * @type {Ok}
     */
    onDeleteGuarantee() {
      this.$store
        .dispatch('guarantee/create/delete', this.$route.params.id)
        .then(() => {
          this.$store.dispatch('guarantee/create/resetData').then(() => {
            this.$router.push({
              name: 'myguarantees',
              params: {
                notificationTitle: this.$t(
                  'approve_guarantee.messages.delete_title'
                ),
                notificationText: this.$t(
                  'approve_guarantee.messages.delete_text',
                  { referenceNumber: this.referenceNumber }
                )
              }
            });

            this.$emit('clearErrors');
          });
        });
    },
    /**
     * Returns menuItems as a link or as a text
     */
    menuItems(isLink) {
      const menuItems = [];
      const that = this;

      this.steps.forEach(function(step, key) {
        step.key = `${key + 1}. `;

        if (that.activeIndex > key && isLink) {
          menuItems.push(step);
        } else if (that.activeIndex <= key && !isLink) {
          menuItems.push(step);
        }
      });

      return menuItems;
    },

    /**
     * changes reference number
     * @event onReferenceNumberChange
     * @type {change}
     */
    onReferenceNumberChange(value) {
      this.$store.dispatch('guarantee/numbers/postReferenceNumber', value);
    },

    openSupervisorModal() {
      this.$store
        .dispatch('employees/getSupervisors', this.user.account.id)
        .then(data => {
          if (data.length === 1) {
            this.$store.commit('guarantee/create/setField', {
              index: this.activeIndex,
              key: 'supervisor_id',
              val: data[0].id
            });
            this.sendData(this.activeIndex + 1);

            return;
          }

          this.$refs.supervisorModal.open();
        });
    },

    /**
     * submit form, validate with vee-validate and send data to api
     * @event onSubmit
     * @type {submit}
     */
    onSubmit() {
      this.sendData(this.activeIndex + 1);
    },
    /**
     * go to previous step in form
     * @event onReset
     * @type {reset}
     */
    onReset() {
      this.disableSubmit = false;
      this.$store.dispatch('guarantee/create/setActive', { currentIndex: this.activeIndex - 1 });
    },
    /**
     *
     * @event onClickSave
     * @type {click}
     */
    onClickSave() {
      this.sendData(this.activeIndex + 1);
    },
    /**
     *
     * @event onClickDelete
     * @type {click}
     */
    onClickDelete() {
      this.$refs.modalDelete.show();
    },

    /**
     * validate Form and send data to api, step handling
     * @event sendData
     * @param {number} index
     */
    sendData(index, reset) {
      this.$refs.form.$refs.multistep.validate().then(success => {
        if (!success) return;

        this.$store.dispatch('guarantee/create/postData', index).then(() => {
          if (reset) this.$store.dispatch('guarantee/create/resetData');
        });

        this.$emit('clearErrors');
      });
    },
    /**
     * go to any step without validation
     * @event goToStep
     * @param {number} index
     */
    goToStep(index) {
      this.$store.dispatch('guarantee/create/setActive', { currentIndex: index });
    },
    /**
     * change step according to router params - the data isn't saved - it uses the data of the API (GET)
     * @event reloadStep
     */
    reloadStep() {
      this.$store.commit(
        'guarantee/create/setGuaranteeId',
        Number(this.$route.params.id)
      );

      // get office
      this.$store.dispatch('offices/getOffices').then(() => {
        if (this.office) {
          this.$store.commit('guarantee/create/setLocale', this.office.locale);
        }
      });

      this.$store.dispatch('guarantee/create/setActive', { currentIndex: this.currentSlugIndex });

      if (!this.guaranteeNumber) {
        this.$store.dispatch(
          'guarantee/numbers/getReferenceAndGuaranteeNumber'
        );
      }

      if (this.activeStep.slug !== this.$route.params.step) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            id: this.$route.params.id,
            step: this.activeStep.slug
          }
        });
      }
    },
    /**
     * set param disabled initially
     * @event onDisableSubmit
     * @param {Boolean} disable
     */
    onDisableSubmit(disable) {
      this.disableSubmit = disable;
    },
    onChangeSupervisor(id) {
      this.$store.commit('guarantee/create/setField', {
        index: this.activeIndex,
        key: 'supervisor_id',
        val: id
      });
    }
  }
};
</script>
