import { extend } from 'vee-validate';
import { required, email, max, min, confirmed } from 'vee-validate/dist/rules';
import { dateValid, dateAfter } from '@theme/utils/datefns';

extend('required', {
  ...required
});

extend('email', {
  ...email
});

extend('max', {
  ...max
});

extend('min', {
  ...min
});

extend('confirmed', {
  ...confirmed
});

extend('amount', {
  validate: (value) => value !== '0,00'
});

extend('password', {
  validate: value => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/g.test(value)
});

extend('dateFormat', {
  validate: value => dateValid(value)
});

extend('dateAfter', {
  validate: (value, { after }) => dateValid(value) && dateValid(after) && dateAfter(value, after),
  params: ['after']
});

extend('dateIndefinite', {
  validate: value => dateValid(value) || value === 'unbefristet' || value === ''
});
