import moment from 'moment';

export function dateFn(date) {
  return moment(date).format('DD.MM.YYYY');
}

export function dateCreate(date) {
  return moment(date).toDate();
}

export function dateApi(date) {
  return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
}

export function dateFromString(date) {
  return moment(moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'));
}

export function dateCreateMoment(date) {
  return moment(date);
}

export function dateToday() {
  return moment().startOf('day');
}

export function dateAddOneYear(date) {
  return moment(date).add(1, 'year');
}

export function dateYesterday() {
  return moment().subtract(1, 'days').format('DD.MM.YYYY');
}

export function dateValid(date) {
  return moment(date, 'DD.MM.YYYY', true).isValid();
}

export function dateAfter(date, after) {
  return moment(date, 'DD.MM.YYYY').isAfter(moment(after, 'DD.MM.YYYY'));
}
