import api from '@core/api/';

const actions = {
  /**
   * Generates a pin via API call
   * @method generatePin
   */
  // I leave it inside until they want WebSMS again
  generatePin(state, guaranteeId) {
    const requestBody = {
      status: 'get_a_pin_for_approval',
      accept_terms: true
    };

    return new Promise((resolve, reject) => {
      api.patchApiData('guarantees/' + guaranteeId + '/states', requestBody).then(({ data }) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
        api.apiError(error, '/error');
      });
    });
  },

  /**
   * Approves the guaranteeId
   * @method approve
   */
  approveGuarantee(state, args) { // I leave it inside until they want WebSMS again
    return new Promise((resolve, reject) => {
      let requestBody = {
        status: 'approve',
        accept_terms: true
      };
      let requestUrl = 'guarantees/' + args + '/states';

      if (typeof args !== 'string' && !(args instanceof String)) {
        requestBody = {
          ...requestBody,
          pin: args.pin
        };

        requestUrl = 'guarantees/' + args.guaranteeId + '/states';
      }

      return api.patchApiData(requestUrl, requestBody).then(({ data }) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /**
   * Declines the guaranteeId
   * @method decline
   */
  declineGuarantee(state, args) {
    return new Promise((resolve, reject) => {
      const requestBody = {
        status: 'decline',
        comment_text: args.commentText,
        accept_terms: true
      };

      return api.patchApiData('guarantees/' + args.guaranteeId + '/states', requestBody).then(({ data }) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }
};

export default {
  namespaced: true,

  actions
};
