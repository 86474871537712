<template>
  <div class="tabbed-pane">
    <radio-buttons
      ref="radios"
      :items="items"
      :value="value"
      name="tabs"
      class="tabbed-pane__radios"
      @change="onChange"
    />
    <slot name="notification" />
    <div
      v-for="(item, index) in items"
      v-show="selected === item.id"
      :key="index"
      class="tabbed-pane__item"
    >
      <slot :name="item.id" />
    </div>
  </div>
</template>

<script>
/**
   * Tabbed pane component
   */
import RadioButtons from '@components/RadioButtons/RadioButtons';

export default {
  name: 'TabbedPane',
  components: {
    RadioButtons
  },
  props: {
    /**
       * An array of initial items
       */
    items: {
      type: Array,
      default: () => []
    },
    /**
       * Sets the initially selected tab
       */
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: ''
    };
  },
  watch: {
    value(newVal) {
      if (this.selected !== newVal) {
        if (newVal.length) {
          this.selected = newVal;
          this.$refs.radios.selected = newVal;
        } else if (this.items.length) {
          this.selected = this.items[0].id;
          this.$refs.radios.selected = this.items[0].id;
        }
      }
    }
  },
  mounted() {
    if (this.value) {
      this.selected = this.value;
      this.$refs.radios.selected = this.value;
    } else if (this.items.length) {
      this.selected = this.items[0].id;
    }
  },
  methods: {
    /**
       * Set selected value
       * @event onChange
       * @type {Change}
       */
    onChange(val) {
      this.selected = val;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './TabbedPane';
</style>
