<template>
  <div class="search">
    <div class="search__block">
      <input
        :placeholder="placeholder"
        v-model="searchTerm"
        :data-testing="dataTesting"
        class="search__input"
        type="text"
        @keyup.enter="onSearch"
        @keyup.down.exact="onKeyDown"
        @keyup.up.exact="onKeyUp"
        @keyup.esc.exact="reset"
        @input.exact="onInput"
        @focus="onFocus"
        @blur="onBlur"
      >
      <icon
        v-if="variant==='filter' && hasSearchTerm"
        class="search__input-reset"
        icon="close"
        color="grey"
        color-hover="red"
        size="12"
        emits-click-event
        @click="reset"
      />
      <icon
        color="red"
        icon="search"
        size="19"
        class="search__icon"
        emits-click-event
        @click="onSearch"
      />
    </div>
    <transition-group name="fade">
      <div
        v-show="isOpen && searchSuggestions.length"
        key="wrapper"
        class="search__suggestions"
      >
        <ul class="search__suggestions-list">
          <template v-for="(suggestion, index) in searchSuggestions">
            <li
              v-if="suggestion.header"
              :key="`${index}-header`"
              class="search__suggestion-title"
            >
              <span
                class="search__suggestion-title-text"
                v-text="$t(suggestion.header)"
              />
            </li>
            <li
              :key="index"
              :class="{ 'search__suggestion': true, 'search__suggestion--highlighted': index === highlightedValue }"
              @mouseenter="onSuggestionMouseEnter(index)"
              @mouseleave="onSuggestionMouseLeave(index)"
              @click="onSelectSuggestion(suggestion)"
            >
              <span
                class="search__suggestion-text"
                v-text="suggestion.label"
              />
            </li>
          </template>
        </ul>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Icon from '@atoms/Icon/Icon.vue';

/**
 * Search component
 */
export default {
  name: 'Search',
  components: {
    Icon
  },
  props: {
    /**
     * value for input
     */
    value: {
      type: String,
      default: ''
    },
    /**
     * placeholder for input
     */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * Sets the suggestions for the autocomplete dropdown.
     */
    suggestions: {
      type: Array,
      default: () => []
    },
    /**
     * Sets a variant for the component
     */
    variant: {
      type: String,
      default: 'default'
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      /**
       * Search term
       */
      searchTerm: '',
      /**
       * Stores my modified search suggestions.
       */
      searchSuggestions: [],
      /**
       * Flag to manually hide the suggestions dropdown.
       */
      isOpen: false,
      /**
       * Stores the highlighted value in the suggestions dropdown.
       */
      highlightedValue: 0
    };
  },
  computed: {
    hasSearchTerm() {
      return this.searchTerm && this.searchTerm.length > 0;
    }
  },
  watch: {
    value(newVal) {
      this.searchTerm = newVal;
    },
    suggestions(newSuggestions) {
      if (newSuggestions) {
        this.highlightedValue = 0;
        this.searchSuggestions = newSuggestions;
      }
    }
  },
  mounted() {
    this.searchTerm = this.value;

    if (this.suggestions.length) {
      this.searchSuggestions = this.suggestions;
    }
  },
  methods: {
    /**
     * Updates the highlighted value.
     * @event onKeyDown
     * @type {Keyup}
     */
    onKeyDown() {
      if (this.isOpen && this.searchSuggestions.length) {
        this.highlightedValue = (this.highlightedValue + 1) % this.searchSuggestions.length;
      }
    },

    /**
     * Updates the highlighted value.
     * @event onKeyUp
     * @type {Keyup}
     */
    onKeyUp() {
      if (this.isOpen && this.suggestions.length) {
        this.highlightedValue = this.highlightedValue === 0 ? this.searchSuggestions.length - 1 : this.highlightedValue - 1;
      }
    },

    /**
     * Updates the highlighted value.
     * @event onSuggestionMouseEnter
     * @type {MouseEvent}
     */
    onSuggestionMouseEnter(index) {
      this.highlightedValue = index;
    },

    /**
     * Updates the highlighted value.
     * @event onSuggestionMouseLeave
     * @type {MouseEvent}
     */
    onSuggestionMouseLeave(index) {
      this.highlightedValue = index;
    },

    onInput(event) {
      /**
       * Gets emitted when the text in the input field changes.
       * @event input
       * @property {string} searchTerm
       */
      this.$emit('input', this.searchTerm.trim());
      this.isOpen = true;
    },

    onSearch() {
      if (this.isOpen && this.searchSuggestions.length) {
        this.searchTerm = this.searchSuggestions[this.highlightedValue].value;
      }

      /**
       * send searchTerm while searching
       * @event search
       * @property {string} searchTerm
       */
      this.$emit('search', this.searchTerm);
      this.isOpen = false;
    },

    /**
     * Gets triggered when a suggestion of the suggestion-dropdown is selected.
     * @event onSelectSuggestion
     * @type {Click}
     */
    onSelectSuggestion(suggestion) {
      if (suggestion && suggestion.value && suggestion.value.length) {
        this.searchTerm = suggestion.value;
        this.$emit('search', this.searchTerm);
      }

      this.isOpen = false;
    },

    /**
     * Gets triggered when the input field gets the focus.
     * @event onFocus
     * @type {Focus}
     */
    onFocus(event) {
      this.isOpen = true;
    },

    /**
     * Gets triggered when the input looses the focus.
     * @event onBlur
     * @type {Blur}
     */
    onBlur(event) {
      this.isOpen = false;
    },

    /**
     * Resets the searchTerm
     * @event reset
    */
    clear() {
      this.searchTerm = '';
    },

    reset() {
      this.clear();
      /**
       * Resets the component
       * @event reset
       */
      this.$emit('reset');
    }
  }
};
</script>

<style lang='scss' scoped>
  @import './Search';
</style>
