<template>
  <template-fullscreen>
    <password-form
      :title="$t('label.reset_password')"
      :submit="submit"
      :external-error="error"
    />
  </template-fullscreen>
</template>

<script>
import TemplateFullscreen from '@theme/router/views/templates/TemplateFullscreen';
import PasswordForm from '@components/PasswordForm/PasswordForm';

export default {
  name: 'ResetPassword',
  components: {
    TemplateFullscreen,
    PasswordForm
  },
  computed: {
    error() {
      return this.$store.getters['user/getError'];
    }
  },
  methods: {
    /**
       * Submit the form
       * @event submit
       * @type {click}
       */
    submit(password, repeatPassword) {
      let payload = {
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: password,
        password_confirmation: repeatPassword
      };
      this.$store.dispatch('user/resetPassword', payload).then(() => {
        payload = {
          email: payload.email,
          password: payload.password
        };
        this.$store.dispatch('user/postLogin', payload);
      });
    }
  }
};
</script>
