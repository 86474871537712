'use strict';

import { isHttps } from '@theme/utils/router';

class Cookies {
  decode(s) {
    return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
  }

  /**
   * setCookie
   * @method setCookie
   */
  setCookie(key, value, attributes) {
    if (typeof attributes.expires === 'number') {
      attributes.expires = new Date(new Date() * 1 + attributes.expires);
    }
    if (!isHttps() && attributes.secure) delete attributes.secure;

    // We're using "expires" because "max-age" is not supported by IE
    attributes.expires = attributes.expires ? attributes.expires.toUTCString() : '';

    try {
      const result = JSON.stringify(value);
      if (/^[\{\[]/.test(result)) {
        value = result;
      }
    } catch (e) {}

    value = encodeURIComponent(String(value))
      .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

    key = encodeURIComponent(String(key))
      .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
      .replace(/[\(\)]/g, escape);

    let stringifiedAttributes = '';
    for (const attributeName in attributes) {
      if (!attributes.hasOwnProperty(attributeName)) {
        continue;
      }
      stringifiedAttributes += '; ' + attributeName;
      if (attributes[attributeName] === true) {
        continue;
      }

      stringifiedAttributes += '=' + attributes[attributeName].split(';')[0];
    }

    return (document.cookie = key + '=' + value + stringifiedAttributes);
  }

  /**
   * getCookie
   * @method getCookie
   */
  getCookie(key) {
    const jar = {};
    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all.
    const cookies = document.cookie ? document.cookie.split('; ') : [];
    let i = 0;
    for (; i < cookies.length; i++) {
      const parts = cookies[i].split('=');
      let cookie = parts.slice(1).join('=');

      try {
        const name = this.decode(parts[0]);
        cookie = this.decode(cookie);

        jar[name] = cookie;

        if (key === name) {
          break;
        }
      } catch (e) {}
    }

    return key ? jar[key] : jar;
  }

  /**
  * check if cookie is existing
  * @method hasCookie
  */
  hasCookie(cookieName) {
    const cookies = document.cookie.split(';').filter((item) => item.includes(cookieName + '='));

    if (cookies.length === 0) return false;

    return cookies[0].split('=')[1].length > 0;
  }

  /**
  * set cookie
  * @method removeCookie
  */
  removeCookie(cookieName) {
    return document.cookie = cookieName + '=; expires=0;';
  }
}

const cookies = new Cookies();

export default cookies;
