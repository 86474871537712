<template>
  <template-default>
    <article class="content container template template--navbar-fixed template--content">
      <h1
        v-text="$t(title)"
      />
      <p>In mollit <b>cupidatat</b> nulla duis et <em>proident</em>. Ea in tempor aute quis <a href="#">excepteur</a> minim dolor veniam. Nisi fugiat laboris qui laborum sunt voluptate sit fugiat consectetur elit velit et. Eiusmod ex ad elit voluptate. Esse pariatur ea adipisicing voluptate do ut esse reprehenderit cupidatat fugiat.</p>
      <h2>Überschrift 2</h2>
      <p>In mollit cupidatat nulla duis et proident. Ea in tempor aute quis excepteur minim dolor veniam. Nisi fugiat laboris qui laborum sunt voluptate sit fugiat consectetur elit velit et. Eiusmod ex ad elit voluptate. Esse pariatur ea adipisicing voluptate do ut esse reprehenderit cupidatat fugiat.</p>
      <h3>Überschrift 3</h3>
      <p>In mollit cupidatat nulla duis et proident. Ea in tempor aute quis excepteur minim dolor veniam. Nisi fugiat laboris qui laborum sunt voluptate sit fugiat consectetur elit velit et. Eiusmod ex ad elit voluptate. Esse pariatur ea adipisicing voluptate do ut esse reprehenderit cupidatat fugiat.</p>
      <h4>Überschrift 4</h4>
      <p>In mollit cupidatat nulla duis et proident. Ea in tempor aute quis excepteur minim dolor veniam. Nisi fugiat laboris qui laborum sunt voluptate sit fugiat consectetur elit velit et. Eiusmod ex ad elit voluptate. Esse pariatur ea adipisicing voluptate do ut esse reprehenderit cupidatat fugiat.</p>
      <h5>Überschrift 5</h5>
      <p>In mollit cupidatat nulla duis et proident. Ea in tempor aute quis excepteur minim dolor veniam. Nisi fugiat laboris qui laborum sunt voluptate sit fugiat consectetur elit velit et. Eiusmod ex ad elit voluptate. Esse pariatur ea adipisicing voluptate do ut esse reprehenderit cupidatat fugiat.</p>
      <blockquote>
        <p>In mollit cupidatat nulla duis et proident. Ea in tempor aute quis excepteur minim dolor veniam. Nisi fugiat laboris qui laborum sunt voluptate sit fugiat consectetur elit velit et. Eiusmod ex ad elit voluptate. Esse pariatur ea adipisicing voluptate do ut esse reprehenderit cupidatat fugiat.</p>
        <footer>White Walker</footer>
      </blockquote>

      <ul>
        <li>asdf<br>asdf</li>
        <li>asdf</li>
        <li>asdf
          <ul>
            <li>adf</li>
            <li>adf</li>
            <li>adf</li>
          </ul>
        </li>
      </ul>

      <ol>
        <li>asdf<br>asdf</li>
        <li>asdf</li>
        <li>asdf
          <ol>
            <li>adf</li>
            <li>adf</li>
            <li>adf</li>
          </ol>
        </li>
      </ol>

      <br>
      <br>

      <btn
        variant="primary">
        primary
      </btn>

      <br>
      <br>

      <btn
        variant="secondary">
        secondary
      </btn>

      <br>
      <br>

      <btn
        variant="primary"
        disabled>
        primary disabled
      </btn>

      <br>
      <br>

      <btn
        variant="secondary"
        disabled>
        secondary disabled
      </btn>

      <br>
      <br>

    </article>
  </template-default>
</template>
<script>
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';

export default {
  name: 'Content',
  components: {
    TemplateDefault
  },
  computed: {
    title() {
      return this.$store.getters['contentTemplate/title'];
    }
  },
  created() {
    this.$store.dispatch('contentTemplate/get');
  }
};
</script>
