<template>
  <template-default>
    <article class="content container template template--navbar-fixed template--content">
      <h1>
        Leider ging da etwas schief!
      </h1>
    </article>
  </template-default>

</template>
<script>
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';

export default {
  name: 'Error',
  components: {
    TemplateDefault
  }
};
</script>
