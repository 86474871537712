<template>
  <template-default>
    <article class="content content--margin container template template--navbar-fixed template--content">
      <h1>
        Dokumente
      </h1>
    </article>
  </template-default>
</template>
<script>
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';

export default {
  name: 'Documents',
  components: {
    TemplateDefault
  }
};
</script>
