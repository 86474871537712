<template>
  <template-default>
    <article class="template template--navbar-fixed">
      <content-header>
        {{ $t('headline.guarantee_approve') }}
        <div
          slot="buttons"
          class="content-header__buttons"
        >
          <btn
            variant="secondary"
            @click="onClickBackButton"
          >
            {{ $t('headline.back') }}
          </btn>
        </div>
      </content-header>
      <div
        v-if="!isLoading && detail"
        class="approve content container-fluid"
      >
        <template v-if="detail.steps">
          <legend
            class="approve__legend"
            v-text="$t('headline.summary') + ' ' + referenceNumber"
          />
          <table-summary
            v-for="(step, index) in detail.steps"
            :key="index"
            :rows="step"
            class="approve__block"
          >
            <template
              slot="download"
              slot-scope="stepData"
            >
              <a
                href="javascript:undefined"
                @click="onClickDownload(stepData.data.id, stepData.data.file_name)"
                v-text="stepData.data.file_name"
              />
            </template>
            <header class="approve__title">
              <h3 v-text="getStepTitle(index)" />
            </header>
          </table-summary>
        </template>
        <table-summary
          v-if="notes"
          :rows="{ 'note': notes }"
          class="approve__block"
        >
          <header class="approve__title">
            <h3 v-text="$t('headline.note')" />
          </header>
        </table-summary>
        <summary-additional :detail="detail" />
        <notification
          v-if="error"
          :text="error"
          status="error"
          type="msg"
          class="approve__notification"
          removable/>
        <form-checkbox
          v-if="state === 'approval_requested' || state === 'approval_pending'"
          id="accept_terms"
          :value="acceptTerms"
          validation="required"
          data-testing="checkbox_accept_terms"
          @onchange="onChangeCheckbox"
        >
          <span v-html="$t('label.accept_terms')" />
        </form-checkbox>
        <div
          v-if="comments && comments.length"
          class="approve__comments"
        >
          <header class="approve__title">
            <h3 v-text="$t('headline.comments')" />
          </header>
          <multistep-summary-comments
            :comments="comments"
            class="approve__comments-items"
          />
        </div>

        <div class="approve__buttons">
          <p
            v-if="buttonText"
            class="approve__button-text"
            v-text="buttonText"
          />
          <div class="approve__buttons-list">
            <div class="approve__buttons-list-item">
              <btn
                :disabled="state !== 'approval_requested' && state !== 'approval_pending' ? true : !acceptTerms"
                variant="success"
                class="approve__button"
                data-testing="button_approve"
                @click="onClickApproveButton"
              >
                {{ $t('button.approve_guarantee') }}
              </btn>

              <modal
                id="modalApprove"
                ref="modalApprove"
                :ok-disabled="pin.length < 4"
                title="headline.enter_pin"
                type="primary"
                ok-title="button.approve"
                data-testing="button_cancel_approve"
                @ok="onApproveGuarantee"
                @cancel="onCancel"
                v-if="user.mobile_verification"
              >
                <transition-group name="fade">
                  <p
                    key="label"
                    class="approve__label"
                    v-text="$t('approve_guarantee.messages.sms_send')"
                  />
                    <validation-observer
                      ref="pin"
                      key="pin-observer">
                      <validation-provider
                        key="pin-validation-provider"
                        vid="pin"
                        name="pin"
                        rules="required">
                          <form-input
                            v-if="!isPinValid"
                            id="pin"
                            key="pin"
                            :value="pin"
                            :placeholder="$t('headline.enter_pin')"
                            type="text"
                            validation="required"
                            data-testing="input_pin"
                            @oninput="updatePin"
                          />
                      </validation-provider>
                    </validation-observer>
                </transition-group>
              </modal>
            </div>
            <div class="approve__buttons-list-item">
              <btn
                :disabled="state !== 'approval_requested' && state !== 'approval_pending' && state !== 'declined' ? true : !acceptTerms"
                variant="danger"
                class="decline__button"
                data-testing="button_decline"
                @click="onClickDeclineButton"
              >
                {{ $t('button.decline_guarantee') }}
              </btn>

              <modal
                id="modalDecline"
                ref="modalDecline"
                :ok-disabled="commentText.length <= 0"
                type="danger"
                title="headline.guarantee_decline"
                ok-title="button.decline"
                ok-variant="danger"
                @ok="onDeclineGuarantee"
                @cancel="onCancel"
              >
                <transition-group name="fade">
                  <validation-observer
                      ref="comment"
                      key="comment-observer">
                      <validation-provider
                        key="comment-validation-provider"
                        vid="comment"
                        name="comment"
                        rules="required">
                        <form-textarea
                          id="comment_text"
                          ref="commentText"
                          key="commentText"
                          :value="commentText"
                          :placeholder="$t('headline.enter_comment_text')"
                          type="textarea"
                          validation="required"
                          data-testing="textarea_comment"
                          @oninput="updateCommentText"
                        />
                      </validation-provider>
                  </validation-observer>
                </transition-group>
              </modal>
            </div>
          </div>
        </div>
      </div>
    </article>
  </template-default>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import MultistepSummaryComments from '@theme/components/Multistep/MultistepSummaryComments';
import SummaryAdditional from '@theme/components/Detail/SummaryAdditional';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import TableSummary from '@components/TableSummary/TableSummary';
import Modal from '@components/Modal/Modal';
import FormInput from '@components/FormInput/FormInput';
import FormCheckbox from '@components/FormCheckbox/FormCheckbox';
import FormTextarea from '@components/FormTextarea/FormTextarea';
import privatePageMixin from '@theme/components/mixins/privatePageMixin';
import Notification from '@components/Notification/Notification';

export default {
  name: 'GuaranteeApprove',
  components: {
    TemplateDefault,
    MultistepSummaryComments,
    FormInput,
    FormTextarea,
    ContentHeader,
    TableSummary,
    Modal,
    SummaryAdditional,
    FormCheckbox,
    Notification
  },
  mixins: [privatePageMixin],
  data() {
    return {
      commentText: '',
      guaranteeId: -1,
      isPinValid: false,
      pin: '',
      acceptTerms: false,
      error: false
    };
  },
  computed: {
    ...mapGetters({
      referenceNumber: 'guarantee/numbers/referenceNumber',
      hasOffices: 'offices/hasOffices'
    }),
    ...mapState({
      state: state => state.guarantee.detail.detail.state,
      detail: state => state.guarantee.detail.detail,
      notes: state => state.guarantee.detail.detail.notes,
      comments: state => state.guarantee.comment.comments,
      isLoading: state => state.guarantee.detail.isLoading,
      user: state => state.user.user
    }),
    buttonText() {
      if (this.state === 'approved' || this.state === 'approved_active') {
        return this.$t('approve_guarantee.messages.approved');
      } else if (this.state === 'draft') {
        return this.$t('approve_guarantee.messages.draft');
      }
      return null;
    }
  },
  mounted() {
    this.guaranteeId = this.$route.params.id;
    this.$store.dispatch('guarantee/detail/getDetail', this.guaranteeId);
    this.$store.dispatch('guarantee/numbers/getReferenceAndGuaranteeNumber', this.guaranteeId);
    this.$store.dispatch('guarantee/comment/getComments', this.guaranteeId);

    if (this.$store.getters['guarantee/countries/countries'].length === 0) {
      this.$store.dispatch('guarantee/countries/getCountries');
    }

    if (!this.hasOffices) this.$store.dispatch('offices/getOffices');
  },
  methods: {
    onClickDownload(attachmentId, filename) {
      this.$store.dispatch('guarantee/detail/downloadAttachment', { guaranteeId: this.$route.params.id, attachmentId, filename });
    },
    /**
       * Get the title of the steps
       * @method getStepTitle
       * @param {Number} index
       */
    getStepTitle(index) {
      return this.$t(this.$store.state.guarantee.create.steps[index].title);
    },

    /**
       * Updates the pin value
       * @event updatePin
       * @type {Input}
       */
    updatePin(data) {
      if (data && data.length) this.pin = data;
    },

    /**
       * Updates the pin value
       * @event updatePin
       * @type {Input}
       */
    updateCommentText(data) {
      if (data && data.length) this.commentText = data;
    },

    /**
       * Go to all guarantees page
       * @event onClickBackButton
       * @type {Click}
       */
    onClickBackButton() {
      this.$router.push({
        name: 'myguarantees'
      });
    },

    /**
       * Open the approve modal
       * @event onClickApprove
       * @type {Click}
       */
    onClickApproveButton() {
      if (this.user.mobile_verification) {
        this.$store.dispatch('guarantee/approve/generatePin', this.guaranteeId).then((data) => {
          this.$refs.modalApprove.show();
        }).catch((error) => {
          if (error.response.status === 400) {
            this.error = 'mobile number wrong.';
          } else {
            this.error = this.$t('general.validation.request_approval.error');
          }
        });

        return;
      }

      this.$store.dispatch('guarantee/approve/approveGuarantee', this.guaranteeId).then(() => {
        this.$router.push({
          name: 'myguarantees',
          params: {
            notificationTitle: this.$t('approve_guarantee.messages.approved_title'),
            notificationText: this.$t('approve_guarantee.messages.approved_text', {
              referenceNumber: this.referenceNumber,
              beneficiary: this.detail.steps[2].company_name
            })
          }
        });
      }).catch((error) => {
        if (process.env.NODE_ENV === 'development') console.error(error);
      });
    },

    /**
       * Open the decline modal
       * @event onClickDecline
       * @type {Click}
       */
    onClickDeclineButton() {
      this.$refs.modalDecline.show();
    },

    /**
       * Trigger the guarantee approve action
       * @event onApproveGuarantee
       * @type {Ok}
       */
    onApproveGuarantee(event) {
      event.preventDefault();

      let requestData = this.guaranteeId;

      if (this.user.mobile_verification) {
        requestData = {
          guaranteeId: this.guaranteeId,
          pin: this.pin
        };
      }

      this.$store.dispatch('guarantee/approve/approveGuarantee', requestData).then((data) => {
        // reset error messages
        this.$refs.pin.setErrors({});

        this.isPinValid = true;
        this.$refs.modalApprove.hide();

        this.$router.push({
          name: 'myguarantees',
          params: {
            notificationTitle: this.$t('approve_guarantee.messages.approved_title'),
            notificationText: this.$t('approve_guarantee.messages.approved_text', {
              referenceNumber: this.referenceNumber,
              beneficiary: this.detail.steps[2].company_name
            })
          }
        });
      }).catch((e) => {
        const message = e?.response?.data
          ? this.$t(e.response.data)
          : this.$t('approve_guarantee.validation.invalid_pin');

        this.$refs.pin.setErrors({
          pin: message
        });
      });
    },

    /**
       * Trigger the guarantee approve action
       * @event onDeclineGuarantee
       * @type {Ok}
       */
    onDeclineGuarantee(event) {
      event.preventDefault();

      this.$store.dispatch('guarantee/approve/declineGuarantee', {
        guaranteeId: this.guaranteeId,
        commentText: this.commentText
      }).then((data) => {
        // reset error messages
        this.$refs.comment.setErrors({});

        this.$refs.modalDecline.hide();

        this.$router.push({
          name: 'myguarantees',
          params: {
            // notificationTitle: this.$t('approve_guarantee.messages.decline_title'),
            notificationText: this.$t('approve_guarantee.messages.decline_text', { referenceNumber: this.referenceNumber })
          }
        });
      }).catch((e) => {
        const message = e?.response?.data
          ? this.$t(e.response.data)
          : this.$t('validation.messages.default');

        this.$refs.comment.setErrors({
          comment: message
        });
      });
    },
    /**
       * handle cancel modal event
       * @event onCancel
       * @type {BvEvt}
       */
    onCancel() {
      this.pin = '';
      this.commentText = '';
      this.acceptTerms = false;

      if (this.$refs.pin) {
        this.$refs.pin.setErrors({});
      }
      if (this.$refs.comment) {
        this.$refs.comment.setErrors({});
      }
    },

    /**
       * Handle TOS checkbox
       * @event onChangeCheckbox
       * @type {event}
       */
    onChangeCheckbox(param) {
      this.acceptTerms = param.val;
    }
  }
};
</script>

<style lang='scss' scoped>
  @import 'Approve';
</style>
