import cookies from '@theme/utils/cookies';
import router from '@core/router';
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

/*
* create api instance
*/
const api = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use((config) => {
  if (cookies.hasCookie('access_token')) config.headers.Authorization = 'Bearer ' + cookies.getCookie('access_token');
  config.headers['X-Locale'] = localStorage.getItem('currentLocale') || 'de-AT';

  return config;
}, (error) => {
  const route = (error) ? 'error' : 'login';

  router.replace({
    name: route
  }, () => {});
});

export default api;
