<template>
  <validation-provider
    :name="id"
    :rules="validation"
    #default="{ errors }"
    slim
  >
    <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :state="errors.length === 0"
      :invalid-feedback="errors[0]"
      label-class="form-selectbox-icons__label"
      class="form-selectbox-icons"
    >
      <multiselect
        ref="formSelectboxIcons"
        v-model="model"
        :class="{ 'is-invalid': errors.length !== 0 }"
        :name="id"
        :options="options"
        :searchable="false"
        :show-labels="false"
        :placeholder="placeholder"
        :disabled="disabled"
        :data-testing="dataTesting"
        track-by="value"
        label="label"
        @input="onChangeModel"
      >
        <template
          slot="option"
          slot-scope="{ option }"
        >
          <span
            :class="'form-selectbox-icons__icon--' + option.locale"
            class="form-selectbox-icons__icon"
            v-text="option.label"
          />
        </template>
      </multiselect>
    </b-form-group>
  </validation-provider>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { BFormGroup } from 'bootstrap-vue';

/**
* Form Selectbox with Validation
*/
export default {
  name: 'FormSelectboxIcons',
  components: {
    Multiselect,
    'b-form-group': BFormGroup
  },
  props: {
    /**
    * selectbox id - it's used also as name attribute for multiselect and error handling
    */
    id: {
      type: String,
      default: ''
    },
    /**
    * label for the bootstrap form group
    */
    label: {
      type: String,
      default: ''
    },
    /**
    * placeholder for multiselect
    */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * custom validation rules if needed
     */
    validation: {
      type: String,
      default: ''
    },
    /**
    * value to fill in data from the api
    */
    value: {
      type: Number,
      default: null
    },

    /**
     * Options to set by the api
     */
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    /**
     * disable selectbox
    */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      /**
      * @model
      */
      model: null
    };
  },
  watch: {
    // detect changes in store step data
    value(newVal) {
      this.createModel(newVal);
    }
  },
  mounted() {
    const vm = this;
    this.$nextTick(() => vm.createModel(this.value));
  },
  methods: {
    onChangeModel(value) {
      this.model = value;

      /**
      * update model and validate hidden field - this is a special case for vue-multiselect
      * @event onchange
      * @property {object} value - key, val
      */
      this.$emit('onchange', { key: this.id, val: value });
    },
    /**
    * create model from current value
    * @event createModel
    * @param {number} val - current value
    */
    createModel(val) {
      if (this.value && this.value !== null && typeof this.options[0] === 'object') {
        this.model = this.options.find((o) => o.value === val);
      }
    }
  }
};
</script>

<style lang="scss">
  @import '~vue-multiselect/dist/vue-multiselect.min.css';
  @import './../../styles/multiselect';
  @import './FormSelectboxIcons';
</style>
