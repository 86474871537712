import Vue from 'vue';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import './rules';

import { i18n } from '@core/plugins/i18n';

import de from '@theme/locales/validation/deDE.json';
import at from '@theme/locales/validation/deAT.json';
import ch from '@theme/locales/validation/deCH.json';

localize({ 'de-DE': de, 'de-AT': at, 'de-CH': ch });

localize(i18n.locale);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
