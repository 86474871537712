import api from '@core/api/';
import { toggle } from '@core/store/utils';

const state = {
  isLoading: false,
  list: []
};

const getters = {
  isLoadingFields: state => state.isLoading,
  offices: state => state.list,
  hasOffices: state => state.list.length > 0,
  currentOffice: state => id => state.list.find(item => id === item.value)
};

const actions = {
  /**
   * Gets offices
   * @method getOffices
   */
  getOffices({ commit, state, rootGetters }) {
    if (state.list.length > 0) return;

    commit('toggleLoading');

    return api.getApiData('/offices').then(({ data }) => {
      commit('setOffices', data.data);

      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      commit('toggleLoading');
    });
  }
};

const mutations = {
  toggleLoading: toggle('isLoading'),
  setOffices: (state, values) => {
    state.list = values.map(function (el) {
      return {
        label: el.label,
        locale: el.locale,
        value: el.value
      };
    });
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
