<template>
  <div
    class="comment"
  >
    <div class="comment__content">
      <p
        class="comment__date"
        v-text="formattedDate"
      />
      <p
        class="comment__text"
        v-text="text"
      />
    </div>
    <p
      class="comment__author"
      v-text="author.full_name"
    />
  </div>
</template>

<script>
import { dateFn } from '@theme/utils/datefns';

export default {
  name: 'Comment',
  props: {
    /**
       * Author of comment
       */
    author: {
      type: Object,
      required: true
    },
    /**
       * Date of comment
       */
    date: {
      type: Date,
      required: true
    },
    /**
       * Text of comment
       */
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedDate() {
      return dateFn(this.date);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './Comment';
</style>
