<template>
  <div
    ref="editableLabelWrapper"
    class="editable-label"
  >
    <p
      class="editable-label__label"
      v-text="label"
    />
    <validation-observer ref="formEditableLabel" #default="{ handleSubmit }">
      <b-form
        v-if="isEditable"
        v-show="isEdited"
        novalidate
        validated
        autocomplete="off"
        class="editable-label__form"
        @submit.prevent
      >
        <validation-provider
          :name="id"
          :rules="validation"
          #default="{ errors, invalid }"
          slim
          ref="prov"
        >
          <b-form-group
            :state="errors.length === 0"
            class="editable-label__edit-wrapper"
          >
            <input
              ref="inputField"
              v-model="value"
              :data-testing="dataTesting + '_input'"
              name="reference_number"
              type="text"
              required
              class="editable-label__input"
              onfocus="this.value = this.value;"
              @keydown.esc="onKeyupEsc"
              @keydown.enter.capture="handleSubmit(submit)"
            >
            <btn
              :data-testing="dataTesting + '_button_save'"
              variant="primary"
              icon="save"
              class="editable-label__button"
              @click="handleSubmit(submit)"
            >
              {{ $t('button.save') }}
            </btn>
            <btn
              :data-testing="dataTesting + '_button_cancel'"
              variant="secondary"
              icon="close"
              class="editable-label__button"
              @click="onClickCancel"
            >
              {{ $t('button.cancel') }}
            </btn>
          </b-form-group>
          <tooltip
            v-if="invalid"
            :text="errors[0]"
            :is-visible="invalid && showErrorTooltip"
            class="editable-label__error"
            @close="onCloseTooltip"
          />
        </validation-provider>
      </b-form>
    </validation-observer>
    <p
      v-show="!isEdited"
      class="editable-label__number-wrapper"
    >
      <span
        ref="displayNumber"
        :data-testing="dataTesting + '_label'"
        class="editable-label__number"
        v-text="value"
      />
      <icon
        v-if="isEditable"
        :data-testing="dataTesting + '_edit_button'"
        icon="edit"
        color="red"
        color-hover="grey"
        size="19"
        emits-click-event
        class="editable-label__icon-edit"
        @click="onEditClick"
      />
    </p>

  </div>
</template>

<script>
import { BFormGroup, BForm } from 'bootstrap-vue/';

import Icon from '@atoms/Icon/Icon';
import Tooltip from '@components/Tooltip/Tooltip';

/**
 * Component for displaying and editing a guarantee number.
 */
export default {
  name: 'EditableLabel',
  components: {
    'b-form-group': BFormGroup,
    'b-form': BForm,
    Icon,
    Tooltip
  },
  props: {
    /**
     * id of the editable label
     */
    id: {
      type: String,
      required: true
    },
    /**
     * custom validation rules if needed
     */
    validation: {
      type: String,
      default: ''
    },
    /**
    * label of the editable label
    */
    label: {
      type: String,
      default: ''
    },
    /**
     * actual editable label
     */
    number: {
      type: String,
      required: true
    },
    /**
     * defines if the number is editable
     */
    isEditable: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      /**
       * @model
       */
      value: '',
      valueCurrent: '',
      isEdited: false,
      showErrorTooltip: true
    };
  },

  watch: {
    number(newVal) {
      this.value = newVal;
    }
  },
  mounted() {
    this.value = this.number;

    this.$watch(() => this.$refs.formEditableLabel.errors,
      (newVal) => {
        if (newVal.referenceNumber.length && !this.showErrorTooltip) {
          this.showErrorTooltip = true;
        }
      });
  },
  methods: {
    submit(event) {
      /**
       * Emits the value of the input field to parent
       * @event change
       * @property value
       */
      this.$emit('change', this.value);
      this.isEdited = false;
      event.stopPropagation();
    },

    /**
     * @method onKeyupEsc
     * @type {keyup}
     * Aborts the edit process and resets value
     */
    onKeyupEsc() {
      this.value = this.valueCurrent;
      this.isEdited = false;
    },

    /**
     * @method onEditClick
     * @type {click}
     * Makes the input field visible and allows the user the edit the value
     */
    onEditClick() {
      this.isEdited = true;
      this.valueCurrent = this.value;

      const input = this.$refs.inputField;

      this.$nextTick(() => {
        input.focus();
      });

      input.style.width = (this.$refs.displayNumber.clientWidth + 10) + 'px';
    },

    /**
     * @method onClickCancel
     * @type {click}
     * Revert editing value to previous state
     */
    onClickCancel() {
      this.value = this.valueCurrent;
      this.isEdited = false;
    },

    /**
     * @method onCloseTooltip
     * @type {click}
     * Close the toollip
     */
    onCloseTooltip() {
      this.showErrorTooltip = false;
    }
  }
};

</script>

<style lang="scss">
  @import './EditableLabel';
</style>
