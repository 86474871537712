<template>
  <validation-observer #default="{ handleSubmit, invalid }">
    <b-form
      class="section"
      @keydown.enter="handleSubmit(submit)"
    >
      <div class="row wrapper">
        <div class="col-md-3">
          <legend
            class="section__legend"
            v-text="$t('label.profile')"
          />
        </div>
        <div class="col-md-9 section__block">
          <div class="row">
            <div class="col-md-12">
              <label
                class="col-form-label pt-0"
                v-text="$t('label.title')"
              />
              <radio-buttons
                :items="titles"
                :value="formData.title"
                name="user-title"
                @change="onTitleChange"
              />
            </div>
          </div>
          <div class="row">
            <form-input
              id="first_name"
              :label="$t('label.first_name')"
              :placeholder="$t('placeholder.enter_first_name')"
              :value="formData.firstName"
              type="text"
              validation="required"
              class="col-md-6 col-12"
              show-required-asterisk
              @oninput="onFirstnameInput"
            />
            <form-input
              id="last_name"
              :label="$t('label.last_name')"
              :placeholder="$t('placeholder.enter_last_name')"
              :value="formData.lastName"
              type="text"
              validation="required"
              show-required-asterisk
              class="col-md-6 col-12"
              @oninput="onLastnameInput"
            />
          </div>
          <div class="row">
            <form-input
              id="email"
              :label="$t('label.email')"
              :placeholder="$t('placeholder.enter_email')"
              :value="formData.email"
              type="text"
              validation="required"
              disabled
              class="col-md-6 col-12"
              show-required-asterisk
              @oninput="onEmailInput"
            />
            <form-input
              id="mobile_phone"
              :label="$t('label.mobile_phone')"
              :value="formData.mobilePhone"
              :disabled="isMobilePhoneDisabled"
              :placeholder="$t('label.mobile_phone')"
              type="text"
              validation="required"
              class="col-md-6 col-12"
              show-required-asterisk
              @oninput="onMobilePhoneInput"
            />
          </div>
          <ul
            v-if="externalErrors"
            class="section__errors"
          >
            <li
              v-for="error in Object.entries(externalErrors)"
              :key="error[0]"
              class="section__error"
            >
              <span v-text="$t(`create_user.validation.${error[0]}.${error[1][0]}`)" />
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <btn
            :disabled="invalid"
            variant="primary"
            class="content-header__btn content-header__btn--margin"
            @click="handleSubmit(submit)"
            v-text="$t('button.update')"
          />
        </div>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm } from 'bootstrap-vue';
import FormInput from '@components/FormInput/FormInput';
import RadioButtons from '@components/RadioButtons/RadioButtons';

export default {
  name: 'UserForm',
  components: {
    'b-form': BForm,
    FormInput,
    RadioButtons
  },
  props: {
    externalErrors: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formData: {
        title: 'Frau',
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: ''
      },
      titles: [
        {
          label: this.$t('label.mrs'),
          id: 'Frau'
        },
        {
          label: this.$t('label.mr'),
          id: 'Herr'
        },
        {
          label: this.$t('label.notitle'),
          id: 'Keine Angabe'
        }
      ]
    };
  },
  computed: {
    isMobilePhoneDisabled() {
      return this.data.role === 'supervisor';
    }
  },
  mounted() {
    this.formData.title = this.data.title || '';
    this.formData.firstName = this.data.first_name || '';
    this.formData.lastName = this.data.last_name || '';
    this.formData.email = this.data.email || '';
    this.formData.mobilePhone = this.data.mobile || '';
  },
  methods: {
    /**
       * Set title
       * @event onTitleChange
       * @type {input}
       */
    onTitleChange(param) {
      this.formData.title = param;
    },
    /**
       * Set firstName
       * @event input
       * @type {input}
       */
    onFirstnameInput(param) {
      this.formData.firstName = param;
    },

    /**
       * Set lastName
       * @event input
       * @type {input}
       */
    onLastnameInput(param) {
      this.formData.lastName = param;
    },

    /**
       * Set email
       * @event input
       * @type {input}
       */
    onEmailInput(param) {
      this.formData.email = param;
    },

    /**
       * Set mobile
       * @event onMobileInput
       * @type {input}
       */
    onMobilePhoneInput(param) {
      this.formData.mobilePhone = param;
    },

    /**
       * Submit the form
       * @event submit
       * @type {click}
       */
    submit() {
      this.$emit('submit', this.formData);
    }
  }
};
</script>
