import { saveAs } from 'file-saver';
import api from '@core/api/';
import { i18n } from '@core/plugins/i18n/index';
import { set, toggle } from '@core/store/utils';

/* eslint-disable no-empty-pattern */
const state = {
  detail: null,
  isLoading: false
};

const getters = {
  state: state => state.detail ? state.detail.state : '',
  notes: state => state.detail ? state.detail.notes : ''
};

const actions = {
  /**
   * @param guaranteeId
   * @param attachmentId
   * @param filename
   *
   * @method Download any attachment
   */
  downloadAttachment({}, { guaranteeId, attachmentId, filename }) {
    return api.getApiData(`guarantees/${guaranteeId}/media/${attachmentId}`, { responseType: 'arraybuffer' }).then(({ data: responseData }) => {
      const blob = new Blob([responseData]);
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
    });
  },
  /**
  * get api data and save it to the detail object
  * @method getOverview
  */
  getDetail({ commit }, guaranteeId) {
    commit('toggleLoading');

    return api.getApiData('guarantees/' + guaranteeId + '/step-6').then(({ data: responseData }) => {
      if (responseData && responseData.data) {
        commit('setDetail', responseData.data);
      }

      commit('toggleLoading');
    }).catch((error) => {
      if (error.response.data.current_step !== null) {
        this.commit('guarantee/create/setGuaranteeId', Number(guaranteeId));
        this.dispatch('guarantee/create/setActive', { routerMethod: 'replace', currentIndex: Number(error.response.data.current_step) - 1 });

        commit('toggleLoading');

        return;
      }

      api.apiError(error, '/error');
      commit('toggleLoading');
    });
  },

  exportPdf({}, id) {
    api.postApiData('/guarantees/' + id + '/export-pdf', null, { responseType: 'blob' }).then(({ data }) => {
      if (data) saveAs(data, i18n.t('terms.exportpdf_name', { id: id }));
    }).catch((error) => {
      console.error(error);
    });
  }
};
const mutations = {
  toggleLoading: toggle('isLoading'),
  setDetail: set('detail')
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
