<template>
  <b-nav class="FormNavigation flex-column list-unstyled">
    <b-nav-text
      v-for="(item, key) in menuItems"
      :key="key"
      :class="{
        'FormNavigation__navbar-item--active': item.active,
        'FormNavigation__navbar-item--completed': item.completed
      }"
      tag="li"
      class="FormNavigation__navbar-item"
    >
      <span
        :class="{
          'FormNavigation__nav-icon--completed': item.completed,
          'FormNavigation__nav-icon--disabled': item.disabled,
          'FormNavigation__nav-icon--active': item.active
        }"
        class="FormNavigation__nav-icon"
        v-text="!item.completed ? key + 1 : null"
      />
      <span :class="{ 'FormNavigation__navbar-link-wrapper--active': item.active }">
        <a
          :class="{
            'FormNavigation__navbar-link--active': item.active,
            'FormNavigation__navbar-link--completed': item.completed,
            'FormNavigation__navbar-link--disabled': item.disabled
          }"
          href="javascript:undefined"
          class="FormNavigation__navbar-link"
          @click="onClick(key, item)"
        >
          {{ $t(item.title) }}
        </a>
      </span>
    </b-nav-text>
  </b-nav>
</template>

<script>
import { BNav, BNavText } from 'bootstrap-vue';

export default {
  name: 'FormNavigation',
  components: {
    'b-nav': BNav,
    'b-nav-text': BNavText
  },
  props: {
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onClick(key, item) {
      /**
      * send values onclick
      * @event click
      * @property {string} key
      * @property {object} item
      */
      this.$emit('click', key, item);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './FormNavigation';
</style>
