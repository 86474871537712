<template>
  <div class="table-interactive">
    <!-- @slot Headline of the Table -->
    <slot />
    <vue-good-table
      ref="vueGoodTable"
      :columns="columns"
      :rows="rows"
      :total-rows="totalRecords"
      :search-options="searchOptions"
      :sort-options="sortOptions"
      :pagination-options="paginationOptions"
      :mode="mode"
      :fixed-header="fixedHeader"
      :max-height="maxHeight"
      :class="{ 'fixed-header': fixedHeader }"
      :is-loading="isLoading"
      :row-style-class="getRowStyleClass"
      style-class="table-interactive__table"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-row-click="onRowClick"
    >
      <preloader
        slot="loadingContent"
        show-overlay
        class="preloader--center-circle"
      />
      <template
        slot="table-column"
        slot-scope="props">
        <span
          v-if="props.column.field !== 'actions'"
          v-text="$t(props.column.label)"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props">
        <actions-dropdown
          v-if="props.column.field === 'actions'"
          :items="props.row.actions"
          :disable-edit-button="props.formattedRow['state']==='states.approval_pending'"
          right
          class="table-interactive__actions"
        >
          {{ $t("button.actions") }}
        </actions-dropdown>
        <btn
          v-else-if="props.column.field === 'choose'"
          :disabled="props.row.selected"
          right
          class="table-interactive__choose"
          @click="onChooseClick(props.row)"
        >
          {{ $t("terms.choose") }}
        </btn>
        <span
          v-else-if="props.column.field === 'state'"
          v-text="$t(props.formattedRow[props.column.field])"
        />
        <span
          v-else-if="props.column.field === 'amount'"
          class="table-interactive__alignright"
          v-text="formatAmount(props.formattedRow[props.column.field])"
        />
        <span
          v-else-if="
            props.column.field === 'valid_from' ||
              props.column.field === 'valid_until' ||
              props.column.field === 'activated_at'
          "
          v-text="createDate(props.formattedRow[props.column.field])"
        />
        <span
          v-else-if="props.column.field === 'role'"
          v-text="$t('roles.' + props.formattedRow[props.column.field])"
        />
        <span
          v-else
          v-text="props.formattedRow[props.column.field]" />
      </template>
      <p
        slot="emptystate"
        class="table-interactive__empty-state"
        v-text="$t('label.no_entry')"
      />
    </vue-good-table>
  </div>
</template>

<script>
import { dateFn } from '@theme/utils/datefns';
import { VueGoodTable } from 'vue-good-table';
import ActionsDropdown from '@components/ActionsDropdown/ActionsDropdown';
import accounting from 'accounting';

/**
 * Table with Sorting, Filtering, Searching, Paging
 */
export default {
  name: 'TableInteractive',
  components: {
    VueGoodTable,
    ActionsDropdown
  },
  props: {
    /**
     * loading indicator
     */
    isLoading: {
      type: Boolean,
      default: false
    },
    /**
     * amount of records that are used for the paging
     */
    totalRecords: {
      type: Number,
      required: true
    },
    /**
     * Table Head - labels are used for th's
     */
    columns: {
      type: Array,
      required: true
    },
    /**
     * Table Body - rows of the table
     */
    rows: {
      type: Array,
      required: true
    },
    /**
     * If the search options are set, the table will have a live-search element.
     */
    searchOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    sortOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    paginationOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    /**
     * Mode. If set to 'remote', allows server side sorting/filtering.
     */
    mode: {
      type: String,
      default: 'remote'
    },
    /**
     * Sets the header as fixed.
     */
    fixedHeader: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the max-height.
     */
    maxHeight: {
      type: String,
      default: null
    },
    /**
     * Set custom class for rows
     */
    rowStyleClass: {
      type: [Function, String],
      default: null
    }
  },
  methods: {
    getRowStyleClass(row) {
      let rowStyleClasses = 'table-interactive__row';
      let classes = '';
      if (typeof this.rowStyleClass === 'function') {
        classes += this.rowStyleClass(row);
      } else {
        classes += this.rowStyleClass;
      }

      if (classes) {
        rowStyleClasses += ` ${classes}`;
      }

      return rowStyleClasses;
    },

    /**
     * Returns a formatted date.
     * @param {Date} date
     */
    createDate(date) {
      return (date && typeof (date) !== 'object') ? dateFn(date) : '';
    },

    /**
     * Returns a EUR formatted currency amount.
     * @param amount
     */
    formatAmount(amount) {
      return accounting.formatMoney(amount, '', 2, '.', ',');
    },

    onChooseClick(rowData) {
      /**
       * Emits the row data to the parent component, if its choose-button is clicked
       * @event choose
       * @property {object} rowData
       */
      this.$emit('choose', rowData);
    },
    onPageChange(params) {
      if (this.mode === 'remote') {
      /**
       * Emits the change page event if mode is remote
       * @event on-page-change
       * @property {object} params
       */
        this.$emit('on-page-change', params);
      }
    },
    onSortChange(params) {
      if (this.mode === 'remote') {
        /**
       * Emits the sort change event if mode is remote
       * @event on-sort-change
       * @property {object} params
       */
        this.$emit('on-sort-change', params);
      }
    },
    onColumnFilter(params) {
      if (this.mode === 'remote') {
        /**
       * Emits the column filter event if mode is remote
       * @event on-column-filter
       * @property {object} params
       */
        this.$emit('on-column-filter', params);
      }
    },
    onPerPageChange(params) {
      if (this.mode === 'remote') {
        /**
     * Emits the per page event if mode is remote
     * @event on-per-page-change
     * @property {object} params
     */
        this.$emit('on-per-page-change', params);
      }
    },

    onRowClick(params) {
    /**
     * Emits the row click
     * @event on-row-click
     * @property {object} params
     */
      this.$emit('on-row-click', params);
    }
  }
};
</script>

<style lang="scss">
@import "./TableInteractive";
@import "~vue-good-table/dist/vue-good-table.css";
</style>
