<template>
  <validation-observer #default="{ handleSubmit }" slim>
    <b-form
      novalidate
      autocomplete="off"
      class="password-forgotten-form"
      ref="passwordForgottenForm"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <b-form-group
        class="password-forgotten-form__wrapper"
      >
        <h1
          class="password-forgotten-form__label"
          v-text="$t('headline.reset_password')"
        />
        <form-input
          id="email"
          ref="email"
          :label="$t('label.email')"
          :placeholder="$t('placeholder.enter_email')"
          class="login-form__form-input"
          variant="minimal"
          type="email"
          validation="required|email"
          data-testing="input_email"
          @oninput="onEmailInput"
        />
        <div
          v-if="apiErrors !== null"
          class="invalid-feedback-box"
          v-text="$t('validation.messages.' + apiErrors.msg)"
        />
        <btn
          variant="primary"
          type="submit"
          data-testing="button_submit"
          v-text="$t('button.send')"
        />
        <router-link
          to="login"
          class="password-forgotten-form__link"
          v-text="$t('button.back_to_login')"
        />
      </b-form-group>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormGroup } from 'bootstrap-vue';
import FormInput from '@components/FormInput/FormInput';

/**
 * Password form
 */
export default {
  name: 'PasswordForgottenForm',
  components: {
    'b-form': BForm,
    'b-form-group': BFormGroup,
    FormInput
  },
  data() {
    return {
      email: null
    };
  },
  computed: {
    apiErrors() {
      return this.$store.getters['user/getError'];
    }
  },
  methods: {
    /**
     * Dispatches the Password Forgotten action
     * @event onSubmit
     * @type {Submit}
     */
    onSubmit() {
      this.$store.dispatch('user/sendResetPasswordLink', {
        email: this.email
      }).then((data) => {
        this.$router.push({
          name: 'login',
          params: {
            redirectedFromPasswordForgotten: true
          }
        });
      });

      this.$refs.passwordForgottenForm.reset();
    },
    /**
     * Handle input event
     * @event onEmailInput
     * @type {Input}
    */
    onEmailInput(value) {
      this.email = value;
    }
  }
};
</script>

<style lang="scss">
  @import './PasswordForgottenForm';
</style>
