<template>
  <validation-observer ref="resetPasswordForm" #default="{ handleSubmit, invalid }">
    <b-form
      class="section"
      @keydown.enter="handleSubmit(submit)"
    >
      <div class="row wrapper">
        <div class="col-md-3">
          <legend
            class="section__legend"
            v-text="$t('label.change_password')"
          />
        </div>
        <div class="col-md-9 section__block">
          <div class="row">
            <form-input
              id="current_password"
              v-model="formData.currentPassword"
              :label="$t('label.current_password')"
              :placeholder="$t('placeholder.current_password')"
              validation="required"
              type="password"
              scope="password-reset-form"
              class="col-md-6 col-12"
              @oninput="onCurrentPasswordInput"
            />
          </div>
          <div class="row">
            <form-input
              id="password"
              vid="password"
              v-model="formData.password"
              :label="$t('label.new_password')"
              :placeholder="$t('placeholder.enter_password')"
              validation="required|password"
              type="password"
              scope="password-reset-form"
              class="col-md-6 col-12"
              @onblur="onPasswordBlur"
              @onfocus="onPasswordFocus"
              @oninput="onPasswordInput"
            >
              <tooltip
                slot="additional"
                :title="$t('label.password_requirements_title')"
                :text="$t('label.password_requirements')"
                :is-visible="isTooltipVisible"
                class="section__password-hint"
                @close="onCloseTooltip"
              />
            </form-input>
          </div>
          <div class="row">
            <form-input
              id="repeat_password"
              v-model="formData.passwordConfirmation"
              :label="$t('label.repeat_new_password')"
              :placeholder="$t('placeholder.repeat_password')"
              validation="required|confirmed:password"
              type="password"
              scope="password-reset-form"
              class="col-md-6 col-12"
              @oninput="onpPasswordConfirmationInput"
            />
          </div>
          <ul
            v-if="externalErrors"
            class="section__errors"
          >
            <li
              v-for="error in Object.entries(externalErrors)"
              :key="error[0]"
              class="section__error"
            >
              <span v-text="$t(`create_user.validation.${error[0]}.${error[1][0]}`)" />
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <btn
            :disabled="invalid"
            variant="primary"
            class="content-header__btn content-header__btn--margin"
            @click="handleSubmit(submit)"
            v-text="$t('button.update')"
          />
        </div>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm } from 'bootstrap-vue';
import FormInput from '@components/FormInput/FormInput';
import Tooltip from '@components/Tooltip/Tooltip';

export default {
  name: 'ResetPasswordForm',
  components: {
    'b-form': BForm,
    FormInput,
    Tooltip
  },
  props: {
    externalErrors: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formData: {
        currentPassword: '',
        password: '',
        passwordConfirmation: ''
      },
      tooltipVisible: false,
      tooltipClosed: false
    };
  },
  computed: {
    isTooltipVisible() {
      return !this.tooltipClosed && this.tooltipVisible;
    }
  },
  methods: {
    /**
       * @event onCurrentPasswordInput
       * @type {oninput}
       */
    onCurrentPasswordInput(param) {
      this.formData.currentPassword = param;
    },

    /**
       * @event onPasswordInput
       * @type {oninput}
       */
    onPasswordInput(param) {
      this.formData.password = param;
    },

    /**
       * @event onpPasswordConfirmationInput
       * @type {oninput}
       */
    onpPasswordConfirmationInput(param) {
      this.formData.passwordConfirmation = param;
    },

    /**
       * Makes the tooltip visible onFocus
       * @event onPasswordFocus
       * @type {Focus}
       */
    onPasswordFocus() {
      this.tooltipVisible = true;
    },

    /**
       * Hides the tooltip onBlur
       * @event onPasswordBlur
       * @type {Blur}
       */
    onPasswordBlur(event) {
      // If the tooltip is visible & the user didn't write anything so far, don't trigger the validation
      if (this.isTooltipVisible && !this.formData.password.length) {
        event.stopImmediatePropagation();
      }

      this.tooltipVisible = false;
    },

    /**
       * Closes the tooltip
       * @event onCloseTooltip
       * @type {Close}
       */
    onCloseTooltip(event) {
      this.tooltipClosed = true;
    },

    /**
       * Submit the form
       * @event submit
       * @type {Click}
       */
    submit() {
      this.$emit('submit', this.formData);
    },

    /**
       * Clean form
       */
    clearForm() {
      this.formData = {
        currentPassword: '',
        password: '',
        passwordConfirmation: ''
      };

      this.$refs.resetPasswordForm.reset();
    }
  }
};
</script>
