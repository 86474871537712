import template from '@theme/store/modules/guarantee/template';
import numbers from '@theme/store/modules/guarantee/numbers';
import beneficiaries from '@theme/store/modules/guarantee/beneficiaries';
import create from '@theme/store/modules/guarantee/create';
import table from '@theme/store/modules/guarantee/table';
import media from '@theme/store/modules/guarantee/media';
import detail from '@theme/store/modules/guarantee/detail';
import approve from '@theme/store/modules/guarantee/approve';
import countries from '@theme/store/modules/guarantee/countries';
import comment from '@theme/store/modules/guarantee/comment';

export default {
  namespaced: true,
  modules: {
    template,
    numbers,
    beneficiaries,
    create,
    table,
    media,
    detail,
    approve,
    countries,
    comment
  }
};
