<template>
  <template-default>
    <article class="template template--navbar-fixed">
      <content-header
        class="content-header--bg"
        type="h1"
      >
        {{ title }}
      </content-header>
      <section
        class="content content--max-width content--margin container-fluid"
        v-html="body"
      />
    </article>
  </template-default>
</template>
<script>
import api from '@core/api/';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import ContentHeader from '@components/ContentHeader/ContentHeader';

export default {
  name: 'Default',
  components: {
    TemplateDefault,
    ContentHeader
  },

  data() {
    return {
      title: '',
      body: ''
    };
  },

  watch: {
    $route (to, from) {
      this.getPage();
    }
  },

  mounted() {
    this.getPage();
  },

  methods: {
    /**
     * Get Content of Page
     * @event getPage
     */
    getPage() {
      api.getApiData(`static-page/${this.$route.params.name}`).then(({ data }) => {
        if (data && data.data && data.data) {
          this.title = data.data.item_name;
          this.body = data.data.body;
        }
      });
    }
  }

};
</script>
