<template>
  <validation-provider
    :name="id"
    :rules="validation"
    #default="{ errors }"
    slim
    ref="provider"
  >
    <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :state="errors.length === 0"
      :label-class="labelClass"
      :class="{
        'form-input--disabled': disabled,
        'form-input--focus': isFocus
      }"
      class="form-input"
    >
      <div class="form-input__wrapper">
        <input
          ref="input"
          v-model="model"
          :id="id"
          :name="id"
          :placeholder="placeholder"
          :type="passwordVisible ? 'text' : inputType"
          :class="{ 'form-input__input': true, 'form-input__input--minimal': variant === 'minimal', 'form-input__input--disabled': disabled }"
          :disabled="disabled"
          :data-testing="dataTesting"
          :readonly="readOnly"
          @blur="onBlur"
          @focus="onFocus"
          @input="onInput"
          @keydown.enter="onBlur"
        >
        <span
          v-if="showPasswordIcon"
          class="form-input__icon"
          @click="passwordVisible = !passwordVisible"
        />
        <!-- @slot additional: for displaying additional info (like a tooltip) -->
        <slot name="additional" />
      </div>

      <template v-slot:invalid-feedback>
            <span v-text="errors[0]"></span>
      </template>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';

/**
 * Standard form input field
 */
export default {
  name: 'FormInput',
  components: {
    'b-form-group': BFormGroup
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    /**
    * input id - it's used also as name attribute and for error handling
    */
    id: {
      type: String,
      required: true
    },
    /**
    * different input type - it sets the correct validation rules
    */
    type: {
      type: String,
      required: true
    },
    /**
    * label for the bootstrap form group
    */
    label: {
      type: String,
      default: ''
    },
    /**
    * placeholder for input field
    */
    placeholder: {
      type: String,
      default: ''
    },
    /**
    * value to fill in data from the api
    */
    value: {
      type: [Number, String],
      default: null
    },
    /**
     * custom validation rules if needed
     */
    validation: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    errorOnBlur: {
      type: Boolean,
      default: false
    },
    validateOnInput: {
      type: Boolean,
      default: false
    },
    showPasswordIcon: {
      type: Boolean,
      default: false
    },
    showRequiredAsterisk: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      /**
       * @model
       */
      model: '',
      isFocus: false,
      passwordVisible: false
    };
  },

  computed: {
    /**
     * Returns the type of input field.
     */
    inputType() {
      switch (this.type) {
        case 'amount':
          return 'text';
        case 'zip':
          return 'number';
        case 'date':
          return 'text';
        default:
          return this.type;
      }
    },
    /**
     * Set label class
     */
    labelClass() {
      return this.showRequiredAsterisk ? 'form-input__label form-input__label--required' : 'form-input__label';
    }
  },
  // detect changes in store step data
  watch: {
    value(newVal) {
      if (this.model !== newVal) this.model = newVal;
    }
  },
  mounted() {
    this.model = this.value;
  },
  methods: {
    onBlur(event) {
      /**
      * update model
      * @event onchange
      * @property {Object} value - key - val
      */
      this.$emit('onchange', {
        key: this.id,
        val: this.model
      });

      /**
        * send blur event
        * @event onBlur
        * @type {event} event
        */
      this.$emit('onblur', event);
      this.isFocus = false;
    },

    onInput() {
      /**
    * update model
    * @event onInput
    * @property {object} model
    */
      this.$emit('oninput', this.model);
    },

    onFocus() {
      /**
      * focus input field
      * @event onFocus
      * @type {Focus}
      */
      this.$emit('onfocus');
      this.isFocus = true;
    }
  }
};

</script>

<style lang="scss">
  @import './FormInput';
</style>
