<template>
  <div
    v-if="activeFields.steps"
    class="multistep--summary"
  >
    <legend class="multistep__legend">
      <!-- @slot Legend of the Step -->
      <slot />
    </legend>

    <table-summary
      v-for="(activeStep, index) in activeFields.steps"
      :key="index"
      :rows="activeStep"
    >
      <template
        slot="download"
        slot-scope="stepData"
      >
        <a
          href="javascript:undefined"
          data-testing="link_download"
          @click="onClickDownload(stepData.data.id, stepData.data.file_name)"
          v-text="stepData.data.file_name"
        />
      </template>
      <header class="table-summary__title">
        <h4 v-text="getStepTitle(index)" />
        <icon
          v-if="index > 0"
          icon="edit"
          color="blue"
          color-hover="red"
          size="19"
          emits-click-event
          class="table-summary__edit"
          @click="onClickEdit(index)"
        />
      </header>
    </table-summary>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TableSummary from '@components/TableSummary/TableSummary';
import Icon from '@atoms/Icon/Icon';

/**
 * Step Summary in Multistep Form
*/
export default {
  name: 'MultistepSummary',
  components: {
    TableSummary,
    Icon
  },
  computed: {
    ...mapGetters({
      activeFields: 'guarantee/create/activeFields',
      isLoadingCountries: 'guarantee/countries/isLoading',
      isLoadingFields: 'guarantee/create/isLoadingFields',
      guaranteeNumber: 'guarantee/numbers/guaranteeNumber',
      hasOffices: 'offices/hasOffices'
    }),
    isLoading() {
      return this.isLoadingCountries || this.isLoadingFields;
    }
  },
  mounted() {
    this.$store.dispatch('guarantee/countries/getCountries').then(() => {
      if (!this.hasOffices) this.$store.dispatch('offices/getOffices');

      this.$store.dispatch('guarantee/create/getData').then(() => this.$store.dispatch('guarantee/create/adjustFormNavigation'));
    });
  },
  methods: {
    onClickDownload(attachmentId, filename) {
      this.$store.dispatch('guarantee/detail/downloadAttachment', { guaranteeId: this.$route.params.id, attachmentId, filename });
    },
    /**
     * get the title of the steps
     * @method getStepTitle
     * @param {Number} index
    */
    getStepTitle(index) {
      return this.$t(this.$store.state.guarantee.create.steps[index].title);
    },
    /**
     * go to another step
     * @method onClickEdit
     * @param {Number} index
    */
    onClickEdit(index) {
      this.$emit('goToStep', index);
    }
  }
};
</script>
