<template>
  <div class="timeline-item">
    <span class="timeline-item__dot" />
    <div :class="['timeline-item__wrapper', `timeline-item__wrapper--${event.type}`]">
      <div
        class="timeline-item__time"
        v-text="`${getTime} ${$t('history.terms.clock')}`"
      />
      <h4
        class="timeline-item__title-wrapper"
      >
        <icon
          v-if="event.type"
          :icon="event.type"
          class="timeline-item__icon"
        />
        <i18n
          :path="`history.messages.${event.event}`"
          tag="span"
          class="timeline-item__title"
        >
          <router-link
            v-if="event.guarantee && event.guarantee.id"
            :to="{ name: 'guaranteeDetail', params: { id: event.guarantee.id }}"
            place="referenceNumber"
            v-text="event.guarantee.reference_number"
          />
        </i18n>
      </h4>
      <p
        v-if="typeof event.user === 'object' && Object.keys(event.user)"
        class="timeline-item__author"
        v-text="getShortInitials"
      />
      <p
        v-else-if="event.type === 'info'"
        class="timeline-item__systeminfo"
        v-text="$t('label.systeminfo')"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@atoms/Icon/Icon.vue';
import moment from 'moment';

export default {
  name: 'TimelineItem',
  components: {
    Icon
  },
  props: {
    /**
       * events of this date
       */
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    getTime() {
      return moment(this.event.created_at).format('HH:mm');
    },
    getShortInitials() {
      const user = this.event.user;
      return `${user.first_name.charAt(0).toUpperCase()}${user.last_name.charAt(0).toUpperCase()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './TimelineItem.scss';
</style>
