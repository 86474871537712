<template>
  <div class="multistep--beneficiary">
    <legend class="multistep__legend">
      <slot />
    </legend>

    <template>
      <div class="multistep__row row">
        <form-autocomplete
          v-if="activeFieldsKey[0]"
          ref="beneficiary"
          :key="createBeneficiaryId"
          :id="activeFieldsKey[0]"
          :value="valueAutocomplete"
          :options="beneficiaries"
          :is-loading="isLoadingBeneficiaries"
          :label="$t('label.company_name')"
          :placeholder="$t('placeholder.enter_company_name')"
          update-search-term
          validation="required"
          class="col-12"
          data-testing="select_beneficiary"
          @search="onSearch"
          @select="onChangeAutocomplete"
          @set-search-term="onSetSearchTerm"
          @reset="onResetAutocomplete"
          @focusnext="focusNext"
        />
      </div>

      <div class="multistep__row row">
        <div class="multistep__col col-12 col-md-4">
          <form-input
            :id="activeFieldsKey[1]"
            :value="activeFields[activeFieldsKey[1]]"
            :label="$t('label.street')"
            :placeholder="$t('placeholder.enter_street')"
            type="text"
            validation="required"
            data-testing="input_street"
            @onchange="onChangeField"
          />
        </div>
        <div class="multistep__col col-12 col-md-2">
          <form-input
            :id="activeFieldsKey[3]"
            :value="activeFields[activeFieldsKey[3]]"
            :label="$t('label.zip_code')"
            :placeholder="$t('placeholder.enter_zip_code')"
            type="text"
            validation="required"
            data-testing="input_zip_code"
            @onchange="onChangeField"
          />
        </div>
        <div class="multistep__col col-12 col-md-3">
          <form-input
            :id="activeFieldsKey[2]"
            :value="activeFields[activeFieldsKey[2]]"
            :label="$t('label.city')"
            :placeholder="$t('placeholder.enter_city')"
            type="text"
            validation="required"
            data-testing="input_city"
            @onchange="onChangeField"
          />
        </div>
        <div class="multistep__col col-12 col-md-3">
          <form-selectbox
            key="country"
            :id="activeFieldsKey[4]"
            :options="countries"
            :value="currentCountry"
            :label="$t('label.country')"
            :placeholder="$t('placeholder.enter_country')"
            validation="required"
            searchable
            data-testing="select_country"
            country-component
            @onchange="onChangeCountry"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FormInput from '@components/FormInput/FormInput';
import FormAutocomplete from '@components/FormAutocomplete/FormAutocomplete';
import FormSelectbox from '@components/FormSelectbox/FormSelectbox';

export default {
  name: 'MultistepBeneficiary',
  components: {
    FormInput,
    FormAutocomplete,
    FormSelectbox
  },
  computed: {
    ...mapState({
      isLoadingBeneficiaries: state => state.guarantee.beneficiaries.isLoading
    }),
    ...mapGetters({
      activeIndex: 'guarantee/create/activeIndex',
      activeFields: 'guarantee/create/activeFields',
      activeFieldsKey: 'guarantee/create/activeFieldsKey',
      isLoadingFields: 'guarantee/create/isLoadingFields',
      isLoadingCountries: 'guarantee/countries/isLoading',
      beneficiaries: 'guarantee/beneficiaries/beneficiaries',
      countries: 'guarantee/countries/countries',
      activeBeneficiary: 'guarantee/beneficiaries/activeBeneficiary'
    }),
    createBeneficiaryId() {
      return 'beneficiary-' + (this.activeFields && this.activeFields.beneficiary_id) ? this.activeFields.beneficiary_id : 0;
    },
    valueAutocomplete() {
      if (!this.activeFields || !this.activeFields.company_name) return null;

      let label = `<b>${this.activeFields.company_name} </b>`;

      if (
        this.activeFields.street ||
        this.activeFields.zip_code ||
        this.activeFields.city
      ) { label += '<small>'; }

      if (this.activeFields.street) label += `, ${this.activeFields.street}`;
      if (this.activeFields.zip_code) label += `, ${this.activeFields.zip_code}`;
      if (this.activeFields.city) label += ` ${this.activeFields.city}`;
      if (this.currentCountry) label += `, ${this.currentCountry}`;

      if (
        this.activeFields.street ||
        this.activeFields.zip_code ||
        this.activeFields.city ||
        this.currentCountry
      ) { label += '</small>'; }

      return {
        label: label,
        value: this.activeFields.company_name,
        id: this.activeFields.beneficiary_id
      };
    },
    currentCountry() {
      if (!this.activeFields.country_id) return '';

      const data = this.$store.getters['guarantee/countries/currentCountry'](this.activeFields.country_id);

      return data && data.label ? data.label : '';
    },
    isLoading() {
      return this.isLoadingFields || this.isLoadingCountries;
    }
  },
  mounted() {
    this.$store.dispatch('guarantee/countries/getCountries');

    this.$store
      .dispatch('guarantee/create/getData')
      .then(() =>
        this.$store.dispatch('guarantee/create/adjustFormNavigation')
      );
  },
  methods: {
    /**
     * change Input Field Value in Store (Model)
     * @event onChangeField
     * @type {event}
     */
    onChangeField(param) {
      this.$store.dispatch('guarantee/create/setField', param);
    },
    /**
     * set form data on selecting new beneficiary in autocomplete
     * @event onChangeAutocomplete
     * @type {event}
     */
    onChangeAutocomplete(param) {
      const beneficiary = this.activeBeneficiary(param.id);

      if (Object.keys(beneficiary).length === 0) {
        return null;
      }

      Object.keys(beneficiary).forEach(k => {
        // field is called "beneficiary_id" not "id"
        const key = (k === 'id') ? 'beneficiary_id' : k;
        this.$store.dispatch('guarantee/create/setField', {
          key: key,
          val: beneficiary[k]
        });
      });
    },
    /**
     * sets the country in the store if changed
     * @event onChangeCountry
     * @type {onchange}
     */
    onChangeCountry({ key, val }) {
      if (!val || !val.id) return;

      this.$store.dispatch('guarantee/create/setField', {
        key: key,
        val: val.id
      });
    },
    /**
     * autocomplete in beneficiaries
     * @event onSearch
     * @param {string} query - search query
     */
    onSearch(query) {
      this.$store.dispatch('guarantee/beneficiaries/sendQuery', { searchQuery: query, onlyOwn: false });
    },

    onResetAutocomplete() {
      this.$store.commit('guarantee/beneficiaries/clear');

      this.$store.commit('guarantee/create/setField', {
        index: this.activeIndex,
        key: 'company_name',
        val: ''
      });

      this.$store.commit('guarantee/create/setField', {
        index: this.activeIndex,
        key: 'beneficiary_id',
        val: null
      });
    },

    onSetSearchTerm(searchTerm) {
      this.$store.commit('guarantee/create/setField', {
        index: this.activeIndex,
        key: 'company_name',
        val: searchTerm
      });
    },

    focusNext() {
      document.getElementById('street').select();
    }
  }
};
</script>
