<template>
  <template-default>
    <div class="template template--bg-gray template--navbar-fixed">
      <content-header
        slot="preview"
        class="content-header--bg"
        type="h1"
      >
        {{ $t('headline.my_guarantees') }}

        <div
          slot="buttons"
          class="content-header__buttons"
        >
          <btn
            variant="primary"
            icon="add"
            class="content-header__btn btn--icon"
            data-testing="button_create_guarantee"
            @click="onClickGuarantee"
          >
            {{ $t('button.create_guarantee') }}
          </btn>
        </div>
      </content-header>
      <filter-list
        :items="['status', 'beneficiary', 'start_date_is', 'due_date_is', 'search']"
        class="container-fluid"
        item-class="filter-col-4"
        list-class="row"
        @reset="onFilterReset"
      >

        <form-selectbox
          id="state-filter"
          slot="status"
          ref="statusFilter"
          :value="filter.state || stateFilters[0]"
          :options="stateFiltersOptions"
          :placeholder="$t('placeholder.status')"
          searchable
          data-testing="select_status"
          @onchange="onChangeStatus"
        />

        <form-autocomplete
          id="beneficiaryFilter"
          slot="beneficiary"
          ref="beneficiaryFilter"
          :key="createBeneficiaryId"
          :value="beneficiaryValue"
          :options="beneficiaries"
          :is-loading="isLoadingBeneficiaries"
          :placeholder="$t('placeholder.beneficiary')"
          update-search-term
          resettable
          data-testing="select_beneficiary"
          @search="onSearchBeneficiaries"
          @select="onChangeBeneficiaries"
          @reset="onClearBeneficiaries"
        />

        <form-date-picker
          id="start-date-is"
          ref="startDateIs"
          slot="start_date_is"
          :placeholder="$t('placeholder.enter_start_date_is')"
          variant="filter"
          data-testing="start_date_is"
          @open="onStartDateIsOpen"
          @change="onStartDateIsChanged"
        />

        <form-date-picker
          id="due-date-is"
          ref="dueDateIs"
          slot="due_date_is"
          :min-date="minDateIs"
          :placeholder="$t('placeholder.enter_due_date_is')"
          variant="filter"
          data-testing="due_date_is"
          @open="onDueDateIsOpen"
          @change="onDueDateIsChanged"
        />

        <search
          id="search-filter"
          slot="search"
          ref="searchFilter"
          :placeholder="$t('placeholder.search')"
          :suggestions="searchSuggestions"
          variant="filter"
          data-testing="input_search_filter"
          @search="onSearch"
          @input="onSearchInput"
          @reset="onSearchReset"
        />
      </filter-list>
      <modal
        id="modalDelete"
        ref="modalDelete"
        :title="$t('headline.remove_guarantee')"
        :visible="deletedGuarantee !== null"
        type="danger"
        ok-variant="danger"
        ok-title="button.delete"
        cancel-title="button.cancel"
        @ok="onDeleteGuarantee"
        @cancel="onCloseDeleteDialog"
      >
        {{ $t('label.remove_guarantee') }}
      </modal>
      <table-interactive
        :is-loading="isLoading"
        :total-records="totalRecords"
        :columns="columns"
        :rows="rows"
        :pagination-options="paginationOptions"
        :sort-options="sortOptions"
        :row-style-class="rowStyleClass"
        class="content--margin container-fluid"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        @on-row-click="onRowClick"
      >
        <h2
          class="table-interactive__hl"
          v-text="$t('headline.total_guarantees', { total: totalRecords })"
        />
      </table-interactive>
    </div>
  </template-default>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import debounce from 'lodash/debounce';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import TableInteractive from '@components/TableInteractive/TableInteractive';
import FilterList from '@components/FilterList/FilterList';
import FormDatePicker from '@components/FormDatePicker/FormDatePicker';
import FormSelectbox from '@components/FormSelectbox/FormSelectbox';
import Search from '@components/Search/Search';
import { dateApi } from '@theme/utils/datefns';
import FormAutocomplete from '@components/FormAutocomplete/FormAutocomplete';
import Modal from '@components/Modal/Modal';
// import { diff } from 'semver'; // maybe needed for step-6

export default {
  name: 'Guarantees',
  components: {
    TemplateDefault,
    ContentHeader,
    TableInteractive,
    FilterList,
    FormDatePicker,
    FormSelectbox,
    Search,
    FormAutocomplete,
    Modal
  },
  data() {
    return {
      minSearchTermLength: 3,
      paginationOptions: {
        enabled: true,
        mode: 'pages',
        perPage: 25,
        perPageDropdown: [25, 50, 75, 100],
        position: 'bottom',
        dropdownAllowAll: false,
        nextLabel: 'Vor',
        prevLabel: 'Zurück',
        rowsPerPageLabel: 'Ergebnisse pro Seite',
        ofLabel: 'von',
        pageLabel: 'Seite', // for 'pages' mode
        allLabel: 'Alle'
      },
      sortOptions: {
        enabled: true
      }
    };
  },
  computed: {
    columns() {
      return [
        {
          label: 'label.state',
          field: 'state',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.reference_number',
          field: 'reference_number',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.beneficiary',
          field: 'beneficiary',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.currency',
          field: 'currency',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.amount',
          field: 'amount',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.valid_from',
          field: 'valid_from',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.valid_until',
          field: 'valid_until',
          thClass: this.sortOptions.enabled ? 'sorting' : ''
        },
        {
          label: 'label.activated_at',
          field: 'activated_at',
          sortable: false
        },
        {
          label: '',
          field: 'actions',
          sortable: false
        }
      ];
    },
    ...mapState({
      isLoadingCountries: state => state.guarantee.countries.isLoading,
      isLoading: state => state.guarantee.table.isLoading,
      totalRecords: state => state.guarantee.table.total,
      rows: state => state.guarantee.table.tableBody,
      isLoadingBeneficiaries: state => state.guarantee.beneficiaries.isLoading,
      stateFilters: state => state.guarantee.table.stateFilters,
      filter: state => state.guarantee.table.filter,
      pagination: state => state.guarantee.table.pagination,
      deletedGuarantee: state => state.guarantee.create.deletedGuarantee,
      searchSuggestions: state => state.guarantee.table.searchSuggestions
    }),
    ...mapGetters({
      beneficiaries: 'guarantee/beneficiaries/beneficiaries',
      activeBeneficiary: 'guarantee/beneficiaries/activeBeneficiary',
      isEmptyFilter: 'guarantee/table/isEmptyFilter',
      countries: 'guarantee/countries/countries'
    }),
    createBeneficiaryId() {
      return 'beneficiary-' + (this.filter && this.filter.beneficiary_id) ? this.filter.beneficiary_id : 0;
    },
    beneficiaryValue() {
      if (!(this.filter && this.filter.beneficiary_id)) {
        return null;
      }

      const beneficiary = this.activeBeneficiary(this.filter.beneficiary_id);
      if (Object.keys(beneficiary).length === 0) {
        return null;
      }

      const country = this.countries.find(item => item.id === beneficiary.country_id);
      let label = (typeof (beneficiary.id) === 'undefined' || beneficiary.id < 0) ? beneficiary.company_name : `<b>${beneficiary.company_name} </b>`;
      if (beneficiary.street || beneficiary.zip_code || beneficiary.city) label += '<small>';

      if (beneficiary.street) label += `, ${beneficiary.street}`;
      if (beneficiary.zip_code) label += `, ${beneficiary.zip_code}`;
      if (beneficiary.city) label += ` ${beneficiary.city}`;
      if (country) label += `, ${country.label}`;

      if (beneficiary.street || beneficiary.zip_code || beneficiary.city || beneficiary.country_id) label += '</small>';

      return {
        label: label,
        value: beneficiary.company_name,
        id: beneficiary.id
      };
    },
    stateFiltersOptions() {
      const options = [];

      if (this.stateFilters && this.stateFilters.length) {
        this.stateFilters.forEach(item => {
          options.push({
            value: item,
            label: this.$t(`states.${item}`)
          });
        });
      }

      return options;
    },
    minDateIs() {
      return this.filter && this.filter.start_date_is ? dateApi(this.filter.start_date_is) : null;
    }
  },
  mounted() {
    this.onFilterReset();
    this.$store.dispatch('guarantee/countries/getCountries');
    this.$store.dispatch('guarantee/table/getData');

    if (this.$route.params) {
      if (this.$route.params.notificationText) {
        this.$notify({
          text: this.$route.params.notificationText ? this.$route.params.notificationText : '',
          data: {
            type: this.$route.params.notificationType ? this.$route.params.notificationType : 'success'
          }
        });
      }

      if (this.$route.params.redirectedFrom && this.$route.params.redirectedFrom === 'approval_requested') {
        this.$store.dispatch('guarantee/create/resetData');
      }
    }
  },
  methods: {
    rowStyleClass(row) {
      if (row.state === 'states.draft') {
        return 'table-interactive__row--draft';
      }
    },

    onCloseDeleteDialog() {
      this.$store.commit('guarantee/create/setDeletedGuarantee', null);
    },

    onDeleteGuarantee() {
      const deleted = this.deletedGuarantee;
      this.$store.commit('guarantee/create/setDeletedGuarantee', null);
      this.$store.dispatch('guarantee/create/delete', deleted.id).then(() => {
        this.$store.dispatch('guarantee/table/getData').then(() => {
          this.$notify({
            text: this.$t('approve_guarantee.messages.delete_text', { referenceNumber: deleted.referenceNumber }),
            data: {
              type: 'success'
            }
          });
        });
      });
    },

    onClickGuarantee() {
      this.$store.dispatch('guarantee/create/resetData');

      this.$router.push({
        name: 'guaranteeId',
        params: {
          id: 'new',
          step: 'step-1'
        }
      });
    },

    onSearch(searchTerm) {
      this.$store.commit('guarantee/table/setPage', 1);
      this.$store.commit('guarantee/table/setFilter', { search: searchTerm });
      this.$store.dispatch('guarantee/table/getData');
    },

    onSearchReset() {
      this.$store.commit('guarantee/table/setPage', 1);
      this.$store.dispatch('guarantee/table/clearSingleFilter', 'search');
      this.$store.dispatch('guarantee/table/getData');
    },

    onChangeBeneficiaries(payload) {
      if (payload.id > 0) {
        this.$store.commit('guarantee/table/setPage', 1);
        this.$store.commit('guarantee/table/setFilter', { beneficiary_id: payload.id });
        this.$store.dispatch('guarantee/table/getData');
      }
    },

    onClearBeneficiaries() {
      this.$store.commit('guarantee/table/setPage', 1);
      this.$store.dispatch('guarantee/table/clearSingleFilter', 'beneficiary_id');
      this.$store.dispatch('guarantee/table/getSuggestions', '');
      this.$store.dispatch('guarantee/table/getData');
    },

    onChangeStatus(params) {
      this.$store.commit('guarantee/table/setPage', 1);

      if (!params || params.val.value === 'all') {
        this.$store.commit('guarantee/table/clearFilter', 'state');
      } else {
        this.$store.commit('guarantee/table/setFilter', { state: params.val.value });
      }

      this.$store.dispatch('guarantee/table/getData');
    },

    onStartDateIsOpen() {
      this.$refs.dueDateIs.close();
    },

    onDueDateIsOpen() {
      this.$refs.startDateIs.close();
    },

    onStartDateIsChanged(value) {
      if (value) {
        this.$store.commit('guarantee/table/setFilter', { start_date_is: value });
      } else {
        this.$store.dispatch('guarantee/table/clearSingleFilter', 'start_date_is');
      }

      this.$store.commit('guarantee/table/setPage', 1);
      this.$store.dispatch('guarantee/table/getData');
    },

    onDueDateIsChanged(value) {
      if (value) {
        this.$store.commit('guarantee/table/setFilter', { end_date_is: value });
      } else {
        this.$store.dispatch('guarantee/table/clearSingleFilter', 'end_date_is');
      }

      this.$store.commit('guarantee/table/setPage', 1);
      this.$store.dispatch('guarantee/table/getData');
    },

    /**
     * Updates the search field suggestions or resets them.
     * @event onSearchInput
     * @type {Input}
     */
    onSearchInput: debounce(
      function(searchTerm) {
        if (searchTerm.length < this.minSearchTermLength) {
          this.$store.commit('guarantee/table/resetSearchSuggestions');
        } else {
          this.$store.dispatch('guarantee/table/getSuggestions', searchTerm);
        }
      },
      100
    ),

    /**
     * autocomplete in beneficiaries
     * @event onSearchBeneficiaries
     * @param {string} term - search term
     */
    onSearchBeneficiaries(term) {
      this.$store.dispatch('guarantee/beneficiaries/sendQuery', { searchQuery: term, onlyOwn: true });
    },

    /**
     * Handle sort change event
     * @event onSortChange
     * @type {Click}
     */
    onSortChange(params) {
      params = params.map((val) => {
        return Object.assign({}, val);
      });
      const index = params.findIndex(value => value.field === 'beneficiary');

      if (index !== -1) params[index].field = 'company_name';

      this.$store.dispatch('guarantee/table/setSorting', { params });
      this.$store.dispatch('guarantee/table/getData');
    },

    /**
     * Handle the change page event
     * @event onPageChange
     * @type {Click}
     */
    onPageChange(params) {
      this.$store.commit('guarantee/table/setPage', params.currentPage);
      this.$store.dispatch('guarantee/table/getData');
    },
    /**
     * Handle per page event
     * @event onPerPageChange
     * @type {Click}
     */
    onPerPageChange(params) {
      if (this.isLoading) return;

      this.$store.commit('guarantee/table/setPage', 1);
      this.$store.commit('guarantee/table/setPerPage', params.currentPerPage);
      this.$store.dispatch('guarantee/table/getData');
    },

    /**
     * Handle row event
     * @event onRowClick
     * @type {Click}
     */
    onRowClick(params) {
      // Early return if it is a draft or the Dropdownitem is a Link (-> handled elsewhere)
      if (params.row.state === 'states.draft' || params.event.target.className.indexOf('actions-dropdown__item') !== -1) return;

      this.$router.push({
        name: 'guaranteeDetail',
        params: {
          id: params.row.id
        }
      });
    },

    clearFilter() {
      this.$refs.startDateIs.clear();
      this.$refs.dueDateIs.clear();
      this.$refs.searchFilter.clear();
      this.$refs.statusFilter.clear();
      this.$refs.beneficiaryFilter.clear();
    },

    /**
     * Handle reset button click event
     * @event onFilterReset
     * @type {Reset}
     */
    onFilterReset() {
      if (!this.isEmptyFilter) {
        this.clearFilter();
        this.$store.dispatch('guarantee/table/clearFilter')
          .then(() => this.$store.dispatch('guarantee/table/getData'));
      }
    }
  }
};
</script>

<style lang='scss' scoped>
  @import 'Guarantees';
</style>
