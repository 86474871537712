import { render, staticRenderFns } from "./PasswordForgottenForm.vue?vue&type=template&id=48da15c5&"
import script from "./PasswordForgottenForm.vue?vue&type=script&lang=js&"
export * from "./PasswordForgottenForm.vue?vue&type=script&lang=js&"
import style0 from "./PasswordForgottenForm.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports