import api from '@core/api/axios';
import router from '@core/router';

export default {
  /*
  * error handling - show error page
  * @param { object|string } error
  */
  apiError(error, route) {
    /* eslint-disable no-console */
    console.error('ERROR API:', error);

    if (typeof (route) !== 'undefined') router.replace(route);
  },
  /*
  * get request to api
  * @param {string} url - path to api endpoint
  * @returns { promise } axios get promise
  */
  getApiData(url, data) {
    const payload = { ...data };
    return api.get(url, payload);
  },
  /*
  * patch request to api
  * @param {string} url - path to api endpoint
  * @param {object} data
  * @returns { promise } axios patch promise
  */
  patchApiData(url, data, params) {
    const payload = { ...data };
    return api.patch(url, payload, params);
  },
  /*
  * post request to api
  * @param {string} url - path to api endpoint
  * @param {object} data
  * @returns { promise } axios post promise
  */
  postApiData(url, data, params) {
    const payload = { ...data };
    return api.post(url, payload, params);
  },
  /*
  * delete request to api
  * @param {string} url - path to api endpoint
  * @returns { promise } axios delete promise
  */
  deleteApiData(url, data) {
    const payload = { ...data };

    return api.delete(url, payload);
  },
  /*
  * set api base url (e.g. for translation handling)
  * @returns { string } api defaults base urls
  */
  setApiUrl(url) {
    api.defaults.baseURL = url;

    return api.defaults.baseURL;
  }
};
