<template>
  <template-default>
    <article class="template template--navbar-fixed">
      <content-header>
        {{ $t("headline.guarantee_detail") }}
        <div slot="buttons" class="content-header__buttons">
          <btn variant="secondary" @click="onClickBackButton">
            {{ $t("headline.back") }}
          </btn>
        </div>
      </content-header>
      <div v-if="!isLoading && detail" class="detail content container-fluid">
        <template v-if="detail.steps">
          <legend
            class="detail__legend"
            v-text="$t('headline.summary') + ' ' + referenceNumber"
          />
          <table-summary
            v-for="(step, index) in detail.steps"
            :key="index"
            :rows="step"
            class="detail__block"
          >
            <template slot="download" slot-scope="stepData">
              <a
                href="javascript:undefined"
                @click="
                  onClickDownload(stepData.data.id, stepData.data.file_name)
                "
                v-text="stepData.data.file_name"
              />
            </template>
            <header class="detail__title">
              <h3 v-text="getStepTitle(index)" />
            </header>
          </table-summary>
        </template>
        <table-summary
          v-if="notes"
          :rows="{ note: notes }"
          class="detail__block"
        >
          <header class="detail__title">
            <h3 v-text="$t('headline.note')" />
          </header>
        </table-summary>
        <table-summary
          v-if="detail.guarantee_copy"
          :rows="{ guarantee: detail.guarantee_copy }"
          class="detail__block"
        >
          <header class="detail__title">
            <h3 v-text="$t('headline.download_guarantee')" />
          </header>
          <template slot="download">
            <a
              href="javascript:void(0)"
              @click="
                onClickDownload(
                  detail.guarantee_copy.id,
                  detail.guarantee_copy.file_name
                )
              "
              v-text="detail.guarantee_copy.file_name"
            />
          </template>
        </table-summary>
        <summary-additional :detail="detail" />
        <div v-if="comments && comments.length" class="detail__comments">
          <header class="detail__title">
            <h3 v-text="$t('headline.comments')" />
          </header>
          <multistep-summary-comments
            :comments="comments"
            class="detail__comments-items"
          />
        </div>
      </div>
    </article>
  </template-default>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import MultistepSummaryComments from '@theme/components/Multistep/MultistepSummaryComments';
import SummaryAdditional from '@theme/components/Detail/SummaryAdditional';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import TableSummary from '@components/TableSummary/TableSummary';

export default {
  name: 'GuaranteeDetail',
  components: {
    TemplateDefault,
    MultistepSummaryComments,
    ContentHeader,
    TableSummary,
    SummaryAdditional
  },
  data() {
    return {
      guaranteeId: -1
    };
  },
  computed: {
    ...mapGetters({
      referenceNumber: 'guarantee/numbers/referenceNumber',
      hasOffices: 'offices/hasOffices'
    }),
    ...mapState({
      detail: (state) => state.guarantee.detail.detail,
      notes: (state) => state.guarantee.detail.detail.notes,
      comments: (state) => state.guarantee.comment.comments,
      isLoading: (state) => state.guarantee.detail.isLoading
    })
  },
  mounted() {
    this.guaranteeId = this.$route.params.id;
    this.$store.dispatch('guarantee/detail/getDetail', this.guaranteeId);
    this.$store.dispatch(
      'guarantee/numbers/getReferenceAndGuaranteeNumber',
      this.guaranteeId
    );
    this.$store.dispatch('guarantee/comment/getComments', this.guaranteeId);

    if (!this.hasOffices) this.$store.dispatch('offices/getOffices');
    if (
      this.$route.params &&
      this.$route.params.redirectedFromDecline &&
      this.$route.params.guaranteeId
    ) {
      this.$notify({
        text: this.$t('approve_guarantee.messages.decline_text', {
          referenceNumber: this.referenceNumber
        }),
        data: {
          type: 'success'
        }
      });
    }
  },
  methods: {
    onClickDownload(attachmentId, filename) {
      this.$store.dispatch('guarantee/detail/downloadAttachment', {
        guaranteeId: this.$route.params.id,
        attachmentId,
        filename
      });
    },
    /**
     * get the title of the steps
     *
     * @method getStepTitle
     * @param {Number} index
     */
    getStepTitle(index) {
      return this.$t(this.$store.state.guarantee.create.steps[index].title);
    },
    /**
     * go to all guarantees page
     *
     * @event onClickBackButton
     * @type {Click}
     */
    onClickBackButton() {
      this.$router.push({
        name: 'myguarantees'
      });
    }
  }
};
</script>

<style lang='scss' scoped>
@import "Detail";
</style>
