import api from '@core/api/';
import router from '@core/router/index';
import { set, toggle } from '@core/store/utils';
import { dateApi } from '@theme/utils/datefns';
import { i18n } from '@core/plugins/i18n/index';

const fieldsData = [
  {
    office_id: null,
    template_id: null,
    guarantee_id: null,
    account_id: null,
    form_step: null
  },
  {
    purpose: '',
    amount: '',
    currency: '',
    valid_from: dateApi(new Date()),
    valid_until: null,
    product_id: null,
    account_id: null
  },
  {
    company_name: '',
    street: '',
    city: '',
    zip_code: '',
    country_id: null,
    account_id: null,
    form_step: null
  },
  {
    default_model_text: true,
    documents: [],
    account_id: null,
    form_step: null
  },
  {
    account_id: null,
    documents: [],
    form_step: null
  },
  {
    original_letter: 'beneficiary',
    original_letter_address_text: '',
    copy_original_letter_beneficiary: false,
    copy_original_letter_customer: false,
    copy_original_letter_address: false,
    copy_original_letter_address_text: '',
    notes: null,
    accept_terms: false,
    account_id: null,
    supervisor_id: null,
    steps: [],
    form_step: null
  }
];

const state = {
  guaranteeId: null,
  officeId: null,
  locale: null,
  isLoading: false,
  isLoadingGuarantee: false,
  isRedirected: false,
  steps: [
    {
      title: 'headline.configure_guarantee',
      menuTitle: 'multistep_menu.configure_guarantee',
      component: 'MultistepTemplate',
      slug: 'step-1',
      active: true,
      completed: false,
      disabled: false,
      fields: { ...fieldsData[0] }
    },
    {
      title: 'headline.guarantee_information',
      menuTitle: 'multistep_menu.guarantee_information',
      component: 'MultistepGuarantee',
      slug: 'step-2',
      active: false,
      completed: false,
      disabled: false,
      fields: { ...fieldsData[1] }
    },
    {
      title: 'headline.beneficiary_information',
      menuTitle: 'multistep_menu.beneficiary_information',
      component: 'MultistepBeneficiary',
      slug: 'step-3',
      active: false,
      completed: false,
      disabled: false,
      fields: { ...fieldsData[2] }
    },
    {
      title: 'headline.model_texts',
      menuTitle: 'multistep_menu.model_texts',
      component: 'MultistepModelText',
      slug: 'step-4',
      active: false,
      completed: false,
      disabled: false,
      fields: { ...fieldsData[3] }
    },
    {
      title: 'headline.documents',
      menuTitle: 'multistep_menu.documents',
      component: 'MultistepDocuments',
      slug: 'step-5',
      active: false,
      completed: false,
      disabled: false,
      fields: { ...fieldsData[4] }
    },
    {
      title: 'headline.summary',
      menuTitle: 'multistep_menu.summary',
      component: 'MultistepSummary',
      slug: 'step-6',
      active: false,
      completed: false,
      disabled: false,
      fields: { ...fieldsData[5] }
    }
  ],
  errors: [],
  notification: [],
  deletedGuarantee: null
};

const getters = {
  currentState: state => state.steps[5].fields.state,
  activeStep: state => state.steps.find(s => s.active) || state.steps[0],
  activeIndex: state => state.steps.findIndex(s => s.active) || 0,
  activeFields: state => (state.steps.find(s => s.active)) ? state.steps.find(s => s.active).fields : {},
  activeFieldsKey: state => (state.steps.find(s => s.active)) ? Object.keys(state.steps.find(s => s.active).fields) : [],
  officeId: state => state.officeId,
  currentSlugIndex: state => slug => state.steps.findIndex(s => s.slug === slug) || 0,
  isLoadingFields: state => state.isLoading,
  hasGuaranteeId: state => !!state.guaranteeId,
  getFormNavigation: state => {
    return state.steps.map((val, i) => {
      return {
        title: val.menuTitle,
        active: val.active,
        completed: val.completed,
        disabled: val.disabled
      };
    });
  }
};

const actions = {
  adjustFormNavigation({ commit, getters }) {
    const activeIndex = getters.activeIndex;
    const activeStep = getters.activeStep;

    state.steps.forEach((val, i) => {
      commit('setDisabledProp', {
        index: i,
        val: i > activeIndex
      });
      commit('setCompletedProp', {
        index: i,
        val: activeIndex > i ? true : activeStep.fields.form_step > i + 1
      });
    });
  },
  setDeletedGuarantee({ commit }, guarantee) {
    commit('setDeletedGuarantee', guarantee);
  },
  /**
   * @method Delete existing guarantee
   * @param commit
   * @param guaranteeId
   */
  delete({ commit }, guaranteeId) {
    commit('toggleLoading');

    return api.deleteApiData('guarantees/' + guaranteeId).then(({ data: responseData }) => {
      commit('setGuaranteeId', null);
      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error, '/error');
      commit('toggleLoading');
    });
  },
  /**
  * set user_id, account_id and locale initially according to current user
  * @method setUserCred
  */
  setUserCred({ commit, getters, rootState }) {
    commit('setField', {
      index: getters.activeIndex,
      key: 'user_id',
      val: rootState.user.user.id
    });

    commit('setField', {
      index: getters.activeIndex,
      key: 'account_id',
      val: rootState.user.user.account ? rootState.user.user.account.id : null
    });

    commit('setLocale', rootState.user.currentLocale);
  },
  /**
  * get api data and save it to fields
  * @method getData
  */
  getData({ commit, dispatch, getters, state }, payload) {
    const redirected = true;
    const edit = payload && payload.edit ? payload.edit : false;
    if (!state.guaranteeId) return;

    return new Promise((resolve, reject) => {
      commit('toggleLoading');
      const currentIndex = parseInt(getters.activeIndex, 10);

      api.getApiData('guarantees/' + state.guaranteeId + '/step-' + (currentIndex + 1)).then(({ data: responseData }) => {
        const formData = responseData.data ? Object.assign({}, state.steps[currentIndex].fields, responseData.data) : {};

        commit('setOfficeId', responseData.data.office_id);

        if ((currentIndex + 1) > formData.step) {
          commit('toggleLoading');
          reject({ step: formData.step - 1 });
        } else {
          switch (currentIndex) {
            case 1:
              if (formData.valid_from === null) {
                formData.valid_from = dateApi(new Date());
                formData.valid_until = null;
              }
              break;
            case 3:
              commit('guarantee/media/setDefaultTemplate', { defaultTemplate: formData.default_model_text }, { root: true });

              dispatch('guarantee/media/getExistingTemplates', null, { root: true }).then(() => {
                dispatch('guarantee/media/setTemplates', {
                  files: formData.documents
                }, { root: true });
              }).catch((error) => {
                /* eslint-disable no-console */
                if (process.env.NODE_ENV === 'development') console.error(error);
                /* eslint-enable no-console */
              });
              break;
            case 4:
              dispatch('guarantee/media/getExistingDocuments', null, { root: true }).then(() => {
                dispatch('guarantee/media/setDocuments', { files: formData.documents }, { root: true });
              }).catch((error) => {
                /* eslint-disable no-console */
                if (process.env.NODE_ENV === 'development') console.error(error);
                /* eslint-enable no-console */
              });
              break;
          }

          commit('setFields', {
            index: currentIndex,
            data: formData
          });

          commit('toggleLoading');
          commit('setErrors', []);
          resolve(responseData);

          if (edit) {
            api.getApiData('guarantees?guaranteeId=' + state.guaranteeId).then(({ data: responseData }) => {
              dispatch('setActive', { currentIndex: responseData.data[0].form_step - 1 });
            }).catch((error) => {
              if (error && error.response && error.response.data && error.response.data.current_step) {
                dispatch('setActive', { currentIndex: error.response.data.current_step - 1 });
              } else {
                reject(error);
              };
            });
          }
        }
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.current_step) {
          dispatch('setActive', { currentIndex: error.response.data.current_step - 1 });
          commit('setRedirected', redirected);
          commit('toggleLoading');
        } else {
          commit('toggleLoading');
          reject(error);
        }
      });
    });
  },
  /**
  * get specific guarantee with guaranteeId
  * @method getGuarantee
  * @param commit
  * @param guaranteeId
  */
  getGuarantee({ commit }, guaranteeId) {
    if (!guaranteeId) return;

    commit('setLoadingGuarantee', true);

    return api.getApiData('guarantees?guaranteeId=' + guaranteeId).then(({ data: responseData }) => {
      const data = responseData.data;

      if (data && data.length > 0) {
        const state = data[0].state;

        if (state === 'states.approval_pending') {
          router.push({
            name: 'myguarantees',
            params: {
              notificationTitle: i18n.t(
                'history.messages.guarantee_not_allowed_pending'
              ),
              notificationText: i18n.t(
                'history.messages.guarantee_not_allowed_pending'
              ),
              notificationType: 'error'
            }
          });
          return '';
        }

        commit('setLoadingGuarantee', false);
      }
    }).catch((error) => {
      commit('setLoadingGuarantee', false);
      api.apiError(error, '/error');
    });
  },

  /**
  * post Data to API onSubmit - save APIErrors to store
  * @method postData
  */
  postData({ getters, commit, dispatch, rootState }, currentIndex) {
    if (currentIndex < 0) return;

    // TODO: Refactor this method!
    const nr = parseInt(getters.activeIndex, 10) + 1;
    const apiUrl = (nr === 1) ? 'guarantees' : 'guarantees/' + state.guaranteeId + '/step-' + nr;

    const payload = {
      apiUrl: apiUrl,
      currentIndex: currentIndex
    };

    commit('toggleLoading');

    if (currentIndex === 4) {
      payload.formData = {
        default_model_text: rootState.guarantee.media.defaultModelText,
        files: rootState.guarantee.media.templates
      };

      dispatch('postApiData', payload);
    } else if (currentIndex === 5) {
      payload.formData = {
        files: rootState.guarantee.media.documents
      };

      dispatch('postApiData', payload);
    } else if (currentIndex === 6) {
      const formData = Object.assign({}, getters.activeFields, {
        account_id: rootState.user.user.account.id
      });

      if (!formData.original_letter_address_text) delete formData.original_letter_address_text;
      if (!formData.copy_original_letter_address_text) delete formData.copy_original_letter_address_text;

      delete formData.steps;
      delete formData.user_id;
      delete formData.step;
      delete formData.state;
      payload.formData = formData;

      dispatch('postApiData', payload);
    } else {
      let formData = Object.assign({}, getters.activeFields, {
        account_id: rootState.user.user.account.id
      });

      if (currentIndex === 1) {
        const formDataNew = { office_id: formData.office_id };

        if (state.guaranteeId) formDataNew.guarantee_id = state.guaranteeId;
        if (formData.template_id) formDataNew.template_id = formData.template_id;

        formData = formDataNew;
      }

      payload.formData = formData;

      dispatch('postApiData', payload);
    }
  },

  /**
  * post Data to API onSubmit - save APIErrors to store
  * @method postApiData
  */
  postApiData({ commit, dispatch, state, rootState, rootGetters }, { apiUrl, currentIndex, formData }) {
    const payload = Object.assign({}, formData);

    return api.postApiData(apiUrl, payload).then(({ data }) => {
      if (data.data && data.data.id) {
        commit('setGuaranteeId', data.data.id);
      }

      if (data.data.notification) {
        commit('setNotification', data.data.notification);
      }

      if (!rootGetters['guarantee/numbers/guaranteeNumber']) {
        dispatch('guarantee/numbers/getReferenceAndGuaranteeNumber', null, { root: true });
      }

      if (currentIndex > 5) {
        if (data.data.state !== 'draft') {
          commit('guarantee/table/clearAllFilters', null, { root: true });

          router.push({
            name: 'myguarantees',
            params: {
              redirectedFrom: 'approval_requested',
              notificationText: i18n.t('approve_guarantee.messages.approval_requested_text', {
                referenceNumber: rootState.guarantee.numbers.items.referenceNumber,
                beneficiary: state.steps[2].fields.company_name
              })
            }
          });
        } else {
          commit('toggleLoading');
        }
      } else {
        dispatch('setActive', { currentIndex: currentIndex });
        commit('toggleLoading');
      }

      commit('setErrors', []);
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.message) {
        if (error && error.response && error.response.data) {
          commit('setErrors', error.response.data);
        }

        commit('toggleLoading');
      } else {
        api.apiError(error);
      }
    });
  },

  /**
  * reset whole form
  * @method resetData
  */
  resetData({ commit, getters, state, rootState }) {
    for (let i = 0; i < state.steps.length; i++) {
      commit('setFields', {
        index: i,
        data: { ...fieldsData[i] }
      });
    }

    commit('setLocale', rootState.user.currentLocale);
    commit('setGuaranteeId', null);

    commit('setActiveProp', {
      index: getters.activeIndex,
      val: false
    });

    commit('setActiveProp', {
      index: 0,
      val: true
    });

    commit('setLoading', false);
  },

  /**
  * change active property in steps Array
  * @method setActive
  */
  setActive({ commit, getters }, { currentIndex, routerMethod = 'push' }) {
    if (currentIndex < 0) return;
    if (currentIndex === state.steps.length) currentIndex = 0;
    commit('setRedirected', false);

    commit('setActiveProp', {
      index: getters.activeIndex,
      val: false
    });

    commit('setActiveProp', {
      index: currentIndex,
      val: true
    });

    router[routerMethod]({
      name: 'guaranteeId',
      params: {
        id: (state.guaranteeId) ? state.guaranteeId : 'new',
        step: getters.activeStep.slug
      }
    }).catch(err => {
      if (process.env.NODE_ENV === 'development') console.error(err);
    });
  },

  /**
  * overwrite fields property of current activeStep with input value
  * @method setField
  */
  setField({ commit, getters }, param) {
    if (!param) return;

    let val = (param.val && typeof (param.val) === 'object') ? param.val.value : (typeof (param.val) !== 'undefined' ? param.val : null);

    if (param.key.indexOf('valid') !== -1 && param.val !== '') val = dateApi(param.val);

    commit('setField', {
      index: getters.activeIndex,
      key: param.key,
      val: val
    });
  },

  /**
  * overwrite fields property of current activeStep and set current form locale
  * @method setOffice
  */
  setOffice({ commit, getters }, office) {
    if (office.val && office.val.locale) commit('setLocale', office.val.locale);

    commit('setField', {
      index: getters.activeIndex,
      key: office.key,
      val: (office.val && office.val.value) ? office.val.value : null
    });
  },

  createFromTemplate({ commit, dispatch }, id) {
    commit('toggleLoading');
    commit('setField', {
      index: 0,
      key: 'template_id',
      val: id
    });
    const payload = {
      apiUrl: 'guarantees',
      currentIndex: 1,
      formData: {
        template_id: id
      }
    };
    dispatch('postApiData', payload);
  },

  edit({ commit, dispatch }, id) {
    commit('setGuaranteeId', id);

    dispatch('getData', {
      index: 5,
      redirected: false,
      edit: true
    });
  }
};

const mutations = {
  setData: set('steps'),
  setGuaranteeId: set('guaranteeId'),
  setRedirected: set('isRedirected'),
  toggleLoading: toggle('isLoading'),
  setLoading: set('isLoading'),
  setLoadingGuarantee: set('isLoadingGuarantee'),
  setDeletedGuarantee: set('deletedGuarantee'),
  setErrors: set('errors'),
  setNotification: set('notification'),
  setLocale: set('locale'),
  setOfficeId: set('officeId'),
  setFields(state, { index, data }) {
    state.steps[index].fields = data;
  },
  setField(state, { index, key, val }) {
    state.steps[index].fields[key] = val;
    state.steps[index].completed = false;
  },
  setActiveProp(state, { index, val }) {
    state.steps[index].active = val;
  },
  setCompletedProp(state, { index, val }) {
    state.steps[index].completed = val;
  },
  setDisabledProp(state, { index, val }) {
    state.steps[index].disabled = val;
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
