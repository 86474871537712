import Vue from 'vue';
import Responsive from './responsive';

Vue.use(Responsive, {
  computed: {
    xs() {
      return this.width < 480;
    },
    sm() {
      return this.width < 768;
    },
    md() {
      return this.width < 1024;
    },
    lg() {
      return this.width < 1280;
    },
    xl() {
      return this.width < 1440;
    },
    maxwidth() {
      return this.width < 1680;
    }
  }
});
