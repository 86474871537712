<template>
  <div
    :class="{ 'preloader--overlay': showOverlay }"
    class="preloader"
  >
    <div class="preloader__circle"/>
  </div>
</template>

<script>
/**
 * Preloader
 */
export default {
  name: 'Preloader',
  props: {
    /**
     * Specifies if an overlay over the parent element is shown.
     */
    showOverlay: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './Preloader';
</style>
