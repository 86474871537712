<template>
  <validation-provider
    :name="id"
    :rules="validation"
    #default="{ errors }"
    slim
  >
    <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      label-class="form-selectbox__label"
      :state="errors.length === 0"
      :invalid-feedback="errors[0]"
      class="form-selectbox"
    >
      <multiselect
        ref="formSelectbox"
        v-model="model"
        :class="{ 'is-invalid': errors.length !== 0, 'multiselect__content--countrySelect': countryComponent }"
        :name="id"
        :options="options"
        :searchable="searchable"
        :show-labels="false"
        :placeholder="placeholder"
        :disabled="disabled"
        :data-testing="dataTesting"
        v-bind="tabIndex"
        track-by="value"
        label="label"
        @input="onChangeModel"
      >
        <span
          slot="noResult"
          v-text="$t('label.no_result')"
        />

        <span
          slot="noOptions"
          v-text="$t('label.no_entry')"
        />
      </multiselect>
    </b-form-group>
  </validation-provider>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { BFormGroup } from 'bootstrap-vue';

/**
* Form Selectbox with Validation
*/
export default {
  name: 'FormSelectbox',
  components: {
    Multiselect,
    'b-form-group': BFormGroup
  },
  props: {
    /**
    * selectbox id - it's used also as name attribute for multiselect and error handling
    */
    id: {
      type: String,
      default: ''
    },
    /**
    * label for the bootstrap form group
    */
    label: {
      type: String,
      default: ''
    },
    /**
    * placeholder for multiselect
    */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * custom validation rules if needed
     */
    validation: {
      type: String,
      default: ''
    },
    /**
    * value to fill in data from the api
    */
    value: {
      type: [String, Number],
      default: null
    },
    /**
     * Options to set by the api
     */
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    /**
     * disable selectbox
    */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * enable search functionality of vue-multiselect
    */
    searchable: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    },

    countryComponent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /**
      * @model
      */
      model: []
    };
  },
  computed: {
    /**
     *  Overwrites the tab index for multiselect components
     *  Is necessary to make multiselects tabbable
     */
    tabIndex() {
      return {
        tabIndex: 0
      };
    }
  },
  watch: {
    // detect changes in store step data
    value(newVal) {
      this.createModel(newVal);
    }
  },
  mounted() {
    const vm = this;
    this.$nextTick(() => vm.createModel(this.value));
  },
  methods: {
    onChangeModel(value) {
      this.model = value || this.options[0];

      /**
        * update model and validate hidden field - this is a special case for vue-multiselect
        * @event onchange
        * @property {object} value - key, val
        */
      this.$emit('onchange', { key: this.id, val: this.model });
    },
    /**
    * create model from current value
    * @event createModel
    * @param {number} val - current value
    */
    createModel(val) {
      this.model = typeof this.options[0] === 'object' ? this.options.find((o) => o.value === val) : [];
    },
    clear() {
      this.model = this.options[0];
    }
  }
};
</script>

<style lang="scss">
  @import '~vue-multiselect/dist/vue-multiselect.min.css';
  @import './../../styles/multiselect';
  @import './FormSelectbox';
</style>
