<template>
  <div class="collapse-block">
    <div
      :class="{ 'collapse-block__title--opened': show }"
      class="collapse-block__title"
      @click="show = !show"
      v-text="title"
    />
    <transition name="fade">
      <div
        v-show="show"
        :class="{ 'collapse-block__content--show': show }"
        class="collapse-block__content mt-2"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Collapse',
  props: {
    /**
       * Collapse title
       */
    title: {
      type: String,
      default: ''
    },
    /**
       * Set visibility of collapse
       */
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    };
  },
  watch: {
    visible (newVal) {
      if (newVal !== this.show) {
        this.show = newVal;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './Collapse.scss';
</style>
