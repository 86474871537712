<template>
  <validation-provider
    :name="id"
    :rules="validation"
    #default="{ errors }"
    slim
    ref="provider"
  >
    <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :state="errors.length === 0"
      :invalid-feedback="errors[0]"
      class="form-checkbox"
      label-class="form-checkbox__label"
    >
      <input
        ref="form-checkbox"
        v-model="checked"
        :id="id"
        :name="id"
        :class="{ 'form-checkbox__checkbox': true, 'checked': checked }"
        :disabled="disabled"
        :data-testing="dataTesting"
        type="checkbox"
        @change="onChange"
      >
      <label
        :for="id"
        :class="{ 'form-checkbox__check-label': true, 'form-checkbox__check-label--disabled': disabled }"
      >
        <!-- @slot for Label Text -->
        <slot/>
      </label>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';

/**
 * Standard Form Checkbox Field
 */
export default {
  name: 'FormCheckbox',
  components: {
    'b-form-group': BFormGroup
  },
  props: {
    /**
    * input id
    */
    id: {
      type: String,
      required: true
    },
    /**
    * label for the checkbox
    */
    label: {
      type: String,
      default: ''
    },
    /**
    * value to fill in data from the api
    */
    value: {
      type: Boolean,
      default: null
    },
    /**
     * custom validation rules if needed
     */
    validation: [String, Object],
    /**
     * disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      /**
       * @model
       */
      checked: false
    };
  },
  // detect changes in store step data
  watch: {
    value(newVal) {
      if (this.checked !== newVal) this.checked = newVal;
    }
  },
  mounted() {
    if (this.value) this.checked = this.value;
  },
  methods: {
    onChange() {
      // quickfix for styleguide
      if (!this.id) return;

      /**
      * update model
      * @event change
      * @property {object} {key, val}
      */
      this.$emit('onchange', {
        key: this.id,
        val: this.checked
      });
    }
  }
};

</script>

<style lang="scss" scoped>
  @import './FormCheckbox';
</style>
