<template>
  <template-default>
    <div class="template template--navbar-fixed">
      <content-header
        slot="preview"
        class="content-header--bg"
        type="h1"
      >
        {{ $t('headline.edit_profile') }}
        <div
          slot="buttons"
          class="content-header__buttons"
        >
          <btn
            variant="primary"
            icon="add"
            class="content-header__btn btn--icon"
            @click="onClickCreateGuarantee"
          >
            {{ $t('button.create_guarantee') }}
          </btn>
        </div>
      </content-header>
      <div class="multistep container-fluid profile">
        <user-form
          :data="user"
          :external-errors="apiGeneralInfoErrors"
          @submit="onProfileSubmit"
        />
        <reset-password-form
          ref="resetPasswordForm"
          :external-errors="apiResetPasswordErrors"
          @submit="onResetPasswordSubmit"
        />
      </div>
    </div>
  </template-default>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import UserForm from '@theme/components/Profile/UserForm';
import ResetPasswordForm from '@theme/components/Profile/ResetPasswordForm';

export default {
  name: 'Profile',
  components: {
    TemplateDefault,
    ContentHeader,
    UserForm,
    ResetPasswordForm
  },
  computed: {
    ...mapGetters({
      apiResetPasswordErrors: 'user/getResetPasswordErrors',
      apiGeneralInfoErrors: 'user/getGeneralInfoErrors'
    }),
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    /**
       * @event onClickCreateGuarantee
       * @type {click}
       */
    onClickCreateGuarantee() {
      this.$store.dispatch('guarantee/create/resetData');

      this.$router.push({
        name: 'guaranteeId',
        params: {
          id: 'new',
          step: 'step-1'
        }
      });
    },

    /**
       * @event onProfileSubmit
       * @type {click}
       */
    onProfileSubmit(data) {
      const payload = {
        title: data.title,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        email: data.email,
        mobile: data.mobilePhone
      };

      this.$store.dispatch('user/update', payload).then(() => {
        this.$notify({
          text: this.$t('create_user.messages.profile_updated_text'),
          data: {
            type: 'success'
          }
        });
      });
    },

    /**
       * @event onResetPasswordSubmit
       * @type {click}
       */
    onResetPasswordSubmit(data) {
      const payload = {
        current_password: data.currentPassword,
        password: data.password,
        password_confirmation: data.passwordConfirmation
      };

      this.$store.dispatch('user/updatePassword', payload).then(() => {
        this.$refs.resetPasswordForm.clearForm();
        this.$notify({
          text: this.$t('create_user.messages.password_updated_text'),
          data: {
            type: 'success'
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './Edit.scss';
</style>
