<template>
  <b-dropdown
    :items="actionItems"
    :data-testing="dataTesting"
    class="user-preview"
  >
    <template slot="button-content">
      <p class="user-preview__info">
        <span
          class="user-preview__username"
          v-text="user.full_name"
        />
        <span
          class="user-preview__organisation"
          v-text="user.account.company_name"
        />
      </p>
    </template>
    <b-dropdown-item-button
      v-for="(item, index) in actionItems"
      :key="index"
      class="user-preview__action"
      @click="onClickAction(item.action)"
    >{{ $t(item.label) }}</b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItemButton } from 'bootstrap-vue';

export default {
  name: 'UserPreview',
  components: {
    'b-dropdown': BDropdown,
    'b-dropdown-item-button': BDropdownItemButton
  },
  props: {
    /**
     * Array of action action items
     */
    actionItems: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      default: null,
      required: true
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  methods: {
    onClickAction(action) {
      /**
       * Emits actions to parent component.
       * @event action
       */
      this.$emit(action);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './UserPreview';
</style>
