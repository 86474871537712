<template>
  <footer class="footer">

    <div class="footer__wrapper container-fluid">
      <ul class="footer__nav">
        <template
          v-for="(item, index) in footer.menuItemsLeft"
        >
          <li
            v-if="item.external"
            :key="index"
            class="footer__item nav-item external"
          >
            <a
              :href="item.external"
              class="nav-link"
              target="_blank"
              v-text="$t(item.item_name)"
            />
          </li>
          <b-nav-item
            v-else
            :key="index"
            :to="`/page/${item.alias}`"
            class="footer__item"
          >
            {{ $t(item.item_name) }}
          </b-nav-item>
        </template>
      </ul>

      <ul class="footer__links">
        <template
          v-for="(item, index) in menuItemsRight"
        >
          <b-nav-item
            :key="index"
            :to="`/page/${item.alias}`"
            class="footer__item"
          >
            {{ $t(item.item_name) }}
          </b-nav-item>
        </template>
      </ul>

    </div>
  </footer>
</template>

<script>
// eslint-disable no-unused-components
import { BNavItem } from 'bootstrap-vue';
import { mapState } from 'vuex';

export default {
  name: 'Footerbar',
  components: {
    'b-nav-item': BNavItem
  },
  computed: {
    ...mapState({
      footer: state => state.footer,
      menuItemsLeft: state => state.footer.menuItemsLeft,
      menuItemsRight: state => state.footer.menuItemsRight
    })
  },
  mounted() {
    this.$store.dispatch('footer/getLinks');
  }
};
</script>

<style lang="scss" scoped>
  @import './Footerbar';
</style>
