<template>
  <div
    class="multistep-additional--summary"
  >
    <div class="multistep__letter">
      <div class="multistep__letter-original">
        <h4
          class="multistep__letter-original--title"
          v-text="$t('headline.send_letter_original')"
        />
        <radio-buttons
          :items="originalLetterItems"
          :value="activeFields[activeFieldsKey[0]]"
          name="radioButtonsOriginalLetter"
          class="multistep__letter-original--radio-buttons"
          data-testing="radio_original_letter"
          @change="onChangeOriginalLetter"
        />
        <transition name="fade">
          <form-textarea
            v-if="activeFields[activeFieldsKey[0]] === 'address'"
            :id="activeFieldsKey[1]"
            :value="activeFields[activeFieldsKey[1]]"
            :placeholder="$t('placeholder.enter_address')"
            validation="required"
            data-testing="textarea_address"
            @onchange="onChangeField"
          />
        </transition>
      </div>

      <div class="multistep__letter-copy">
        <h4
          class="multistep__letter-copy--title"
          v-text="$t('headline.send_letter_copy')"
        />
        <form-checkbox
          :id="activeFieldsKey[2]"
          :value="activeFields[activeFieldsKey[2]]"
          class="multistep__letter-copy--checkbox"
          data-testing="checkbox_send_letter_copy"
          @onchange="onChangeField"
        >
          <span v-text="$t('label.to_beneficiary')" />
        </form-checkbox>
        <form-checkbox
          :id="activeFieldsKey[3]"
          :value="activeFields[activeFieldsKey[3]]"
          class="multistep__letter-copy--checkbox"
          data-testing="checkbox_send_letter_copy_to_beneficiary"
          @onchange="onChangeField"
        >
          <span v-text="$t('label.to_customer')" />
        </form-checkbox>
        <form-checkbox
          :id="activeFieldsKey[4]"
          :value="activeFields[activeFieldsKey[4]]"
          class="multistep__letter-copy--checkbox"
          data-testing="checkbox_send_letter_copy_to_customer"
          @onchange="onChangeField"
        >
          <span v-text="$t('label.to_address')" />
        </form-checkbox>
        <transition name="fade">
          <form-textarea
            v-if="activeFields[activeFieldsKey[4]]"
            :id="activeFieldsKey[5]"
            :value="activeFields[activeFieldsKey[5]]"
            :placeholder="$t('placeholder.enter_address')"
            validation="required"
            data-testing="textarea_send_letter_copy_to_address"
            @onchange="onChangeField"
          />
        </transition>
      </div>
    </div>

    <form-textarea
      :id="activeFieldsKey[6]"
      :label="$t('label.notes')"
      :value="activeFields[activeFieldsKey[6]]"
      :placeholder="$t('placeholder.enter_notes')"
      validation="max:3000"
      class="form-textarea--h300"
      data-testing="textarea_notes"
      @onchange="onChangeField"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RadioButtons from '@components/RadioButtons/RadioButtons';
import FormCheckbox from '@components/FormCheckbox/FormCheckbox';
import FormTextarea from '@components/FormTextarea/FormTextarea';

/**
 * Additional Content in Step Summary in Multistep Form
 */
export default {
  name: 'MultistepSummaryAdditional',
  components: {
    RadioButtons,
    FormCheckbox,
    FormTextarea
  },
  data() {
    return {
      originalLetterItems: [
        { label: this.$t('label.to_beneficiary'), id: 'beneficiary' },
        { label: this.$t('label.to_customer'), id: 'customer' },
        { label: this.$t('label.to_address'), id: 'address' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      activeFields: 'guarantee/create/activeFields',
      activeFieldsKey: 'guarantee/create/activeFieldsKey',
      isLoadingFields: 'guarantee/create/isLoadingFields',
      isLoadingCountries: 'guarantee/countries/isLoading'
    }),
    isLoading() {
      return this.isLoadingCountries || this.isLoadingFields;
    }
  },
  watch: {
    activeFields (newActiveFields, oldActiveFields) {
      if (newActiveFields.accept_terms !== oldActiveFields.accept_terms && newActiveFields.accept_terms) {
        this.$emit('ondisable', false);
      }
    }
  },
  destroyed() {
    this.$emit('ondisable', false);
  },
  methods: {
    /**
     * Changes the values for sending the original
     * @event onChangeOriginalLetter
     * @type {Change}
     */
    onChangeOriginalLetter(param) {
      const field = {
        key: 'original_letter',
        val: param
      };

      this.$store.dispatch('guarantee/create/setField', field);
    },

    /**
    * change Input Field Value in Store (Model)
    * @event onChangeField
    * @type {event}
    */
    onChangeField(param) {
      this.$store.dispatch('guarantee/create/setField', param);
    },

    /**
    * change Checkbox Value in Store (Model)
    * @event onChangeCheckbox
    * @type {event}
    */
    onChangeCheckbox(param) {
      this.$store.dispatch('guarantee/create/setField', param);
      this.$emit('ondisable', !param.val);
    }
  }
};
</script>
