<template>
  <div :class="'form-dp form-dp--' + variant">
    <global-events
      target="window"
      @resize="onWindowResize"
      @click="onOutsideClick"
      @keydown.enter.capture="onKeydownEnter"
      @keydown.esc.exact="close"
    />
    <div
      ref="wrapper"
      class="form-dp__wrapper"
      @click="onInsideClick"
    >
      <form-input
        ref="input"
        :id="id"
        :value="dateApplied"
        :placeholder="isIndefinite ? $t('terms.indefinite') : placeholder"
        :label="label"
        :validation="validationRules"
        :data-testing="dataTesting"
        type="date"
        :read-only="dateApplied !== null && !isIndefinite"
        @onblur.capture="(e) => onBlur(e)"
        @onfocus="open"
        @oninput="onInput"
      >
        <template slot="additional">
          <icon
            icon="date"
            color="red"
            color-hover="red"
            size="19"
            emits-click-event
            class="form-dp__reset"
            @click="open"
          />
        </template>
      </form-input>

      <transition name="fade">
        <div
          v-show="isOpen"
          ref="dropdown"
          :class="'form-dp__dropdown form-dp__dropdown--' + positionDropdown + ' form-dp__dropdown--' + variant"
          @click="onDropdownClick"
        >
          <div
            class="form-dp__dropdown-inner"
            @click="onDropdownInnerClick"
          >
            <div class="form-dp__close-wrapper">
              <icon
                icon="close"
                color="red"
                color-hover="grey"
                size="12"
                emits-click-event
                class="form-dp__close"
                @click="close()"
              />
            </div>
            <b-date-picker
              v-model="date"
              :config="options"
              :class="{
                'form-dp__picker': true,
                'form-dp__picker--disabled': isIndefinite
              }"
              name="date"
            />
            <form-checkbox
              v-if="variant === 'indefinite'"
              :id="`${id}_checkbox`"
              :value="isIndefinite"
              class="form-dp__checkbox"
              @onchange="onChangeIsIndefinite"
            >
              <span v-text="$t('terms.indefinite')" />
            </form-checkbox>
            <btn
              :disabled="isApplyDisabled"
              variant="primary"
              class="form-dp__btn"
              @click="apply"
              v-text="$t('button.apply')"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GlobalEvents from 'vue-global-events';
import BDatePicker from 'vue-bootstrap-datetimepicker';
import { dateCreateMoment, dateFn, dateToday, dateAddOneYear, dateFromString, dateYesterday } from '@theme/utils/datefns';
import FormCheckbox from '@components/FormCheckbox/FormCheckbox';
import Icon from '@atoms/Icon/Icon';
import FormInput from '@components/FormInput/FormInput';
/**
 * Form datepicker field
 */
export default {
  name: 'FormDatePicker',
  components: {
    'b-date-picker': BDatePicker,
    GlobalEvents,
    FormCheckbox,
    Icon,
    FormInput
  },
  props: {
    /**
     * Id of form component
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Sets the variant of the component
     */
    variant: {
      type: String,
      default: 'default',
      validator: value => ['default', 'filter', 'indefinite', 'year'].includes(value)
    },
    /**
     * Date value that is set initially
     */
    value: {
      type: Date,
      default: null
    },
    /**
     * Minimum date to be valid
     */
    minDate: {
      type: [Date, String],
      default: null
    },
    /**
     * Label for the form group
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Placeholder set initially
     */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * Required attribute
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    },
    /**
     * Checks if the date is in the past
     */
    isCreateDatePicker: {
      type: Boolean,
      default: false
    },
    /**
     * References valid_from
     */
    validFrom: {
      type: [Date, String],
      default: ''
    }
  },
  data() {
    return {
      isOpen: false,
      date: null,
      dateOld: null,
      dateApplied: null,
      isIndefinite: false,
      options: {
        format: 'DD.MM.YYYY',
        locale: 'de',
        minDate: this.minDate ? dateCreateMoment(this.minDate) : dateToday(),
        keepOpen: true,
        inline: true
      },
      positionDropdown: '',
      hasFocus: false
    };
  },

  computed: {
    isApplyDisabled() {
      return !this.isIndefinite && (!this.date || this.date === this.dateOld);
    },
    lastDate() {
      return this.validFrom !== '' ? dateFn(this.validFrom) : dateYesterday();
    },
    validationRules() {
      let rule = '';

      if (this.required) rule = 'required|';
      if (this.isCreateDatePicker) rule += `dateAfter:${this.lastDate}|`;
      if (this.variant === 'indefinite') rule += 'dateIndefinite|';
      if (this.variant !== 'indefinite' && !this.isCreateDatePicker) rule += 'dateFormat|';

      return rule;
    }
  },
  watch: {
    minDate(value) {
      if (value) {
        this.options.minDate = dateCreateMoment(value);

        if (this.dateApplied) {
          if (dateFromString(this.dateApplied).isBefore(dateCreateMoment(value))) {
            this.reset();
          }
        }
      } else {
        this.options.minDate = false;
      }
    }
  },
  mounted() {
    switch (this.variant) {
      case 'filter':
        this.options.minDate = false;
        break;
      case 'year':
        if (!this.value) {
          this.date = dateAddOneYear(this.options.minDate);
          this.dateApplied = dateFn(this.date);
        }
    }

    if (this.value) {
      if (dateCreateMoment(this.value).isAfter(this.options.minDate)) {
        this.date = this.value;
        this.dateApplied = dateFn(this.value);
      } else {
        this.date = this.options.minDate;
        this.dateApplied = dateFn(this.date);
      }
    }
  },
  methods: {
    /**
     * Re-calculates the positioning on resizing the window
     * @event onWindowResize
     * @type {Resize}
     */
    onWindowResize() {
      this.calcPosition();
    },

    /**
     * Stops the event propagation if the user clicks inside the dropdown div
     * @event onInsideClick
     * @type {Click}
     */
    onInsideClick(event) {
      event.stopPropagation();
    },

    /**
     * Closes the dropdown if the user clicks outside the component
     * @event onOutsideClick
     * @type {Click}
     */
    onOutsideClick(event) {
      this.apply();
    },

    /**
     * Has the same effect as onOutsideClick() if the dropdown is a modal
     * @event onDropdownClick
     * @type {Click}
     */
    onDropdownClick() {
      if (this.positionDropdown === 'modal') {
        this.apply();
      }
    },

    /**
     * Stops event propagation if the dropdown is a modal
     * @event onDropdownClick
     * @type {Click}
     */
    onDropdownInnerClick(event) {
      if (this.positionDropdown === 'modal') {
        event.stopPropagation();
      }
    },

    /**
     * Changes the isIndefinite variable
     * @event onChangeIsIndefinite
     * @type {Change}
     */
    onChangeIsIndefinite(param) {
      this.isIndefinite = param.val;

      if (this.isIndefinite) this.date = null;
    },

    /**
     * Calls apply if isOpen or opens the dropdown if not.
     * @event onKeydownEnter
     * @type {Keydown}
     */
    onKeydownEnter(event) {
      if (this.isOpen) {
        event.stopPropagation();
        event.preventDefault();
        this.apply();
      } else if (this.hasFocus) {
        event.stopPropagation();
        event.preventDefault();
        this.open();
      }
    },

    open() {
      this.calcPosition();
      this.isOpen = true;
      this.date = this.dateApplied;
      this.dateOld = this.dateApplied;

      /**
       * Opens the datepicker dropdown
       * @event open
       */
      this.$emit('open');
    },

    /**
     * Calculates the positioning of the dropdown
     * @method calcPosition
     */
    calcPosition() {
      const inputRect = this.$refs.input.$refs.input.getBoundingClientRect();
      const wrapperWidth = this.$refs.wrapper.offsetWidth;
      const datepickerHeight = Math.max(wrapperWidth - 40, 270) * (6 / 7);
      const dropdownHeight = this.variant === 'indefinite' ? 270 + datepickerHeight : 217 + datepickerHeight;

      if (wrapperWidth < 310) {
        const offsetLeft = (wrapperWidth - 310) / 2;
        this.$refs.dropdown.style.left = offsetLeft + 'px';
      } else {
        this.$refs.dropdown.style.left = 0;
      }

      if (window.innerHeight - inputRect.bottom >= dropdownHeight) {
        this.positionDropdown = '';
      } else if (window.innerHeight - inputRect.bottom < dropdownHeight && inputRect.top > dropdownHeight) {
        this.positionDropdown = 'above';
      } else {
        this.positionDropdown = 'modal';
        this.$refs.dropdown.style.left = 0;
      }
    },

    clear() {
      if (this.variant === 'default') {
        this.date = dateFn(this.options.minDate);
        this.dateApplied = this.date;
      } else if (this.variant === 'indefinite') {
        this.date = null;
        this.dateApplied = null;
      } else if (this.variant === 'year') {
        this.date = dateAddOneYear(this.options.minDate);
        this.dateApplied = dateFn(this.date);
      } else {
        this.date = null;
        this.dateApplied = null;
      }
    },

    /**
     * Resets the component and sets the date to null or the min-date, if the variant is default
     * @method reset
     */
    reset() {
      this.clear();

      this.$emit('change', this.dateApplied);
    },

    /**
     * Applies the selected date to the input field
     * @method apply
     */
    apply() {
      if (this.isOpen) {
        this.isOpen = false;
        this.dateApplied = this.isIndefinite ? null : this.date;

        /**
         * sends the new Date
         * @event change
         * @property {String} dateApplied
         */
        this.$emit('change', this.dateApplied);
      }
    },

    /**
     * Closes the dropdown
     */
    close() {
      this.isOpen = false;
    },

    /**
     * Handle date blur event
     * @event onBlur
     * @type {onBlur}
     */
    onBlur(e) {
      if (!this.isOpen) {
        const value = e.target.value;
        this.dateApplied = value;
        this.date = value;

        if (this.variant === 'indefinite' && value !== '') {
          this.isIndefinite = value === '';
        }

        this.$emit('change', value);
      }
    },
    /**
     * Handle date input event
     * @event onInput
     * @type {onInput}
     */
    onInput(e) {
      if (this.isOpen) {
        this.isOpen = false;
      }
    }
  }
};

</script>

<style lang="scss">
  @import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
  @import './FormDatePicker';
</style>
