<template>
  <div class="table-summary">
    <!-- @slot Header of the Table -->
    <slot />

    <table
      v-if="tableRow"
      class="table-summary__table"
    >
      <tr
        v-for="(value, key, index) in tableRow"
        :key="key"
        class="table-summary__row"
      >
        <template v-if="value && typeof(value) === 'object' && value.file_name">
          <td v-text="$t('label.attachement', {nr: setNr(index, key) })" />
          <td>
            <slot
              :data="value"
              name="download"
            >
              <a
                @click="clickDownload"
                v-text="value.file_name"
              />
            </slot>
          </td>
        </template>
        <template v-else-if="value && typeof(value) === 'object'">
          <td v-text="$t('label.' + key)"/>
          <td v-text="$t('file_upload.no_files')" />
        </template>
        <template v-else>
          <td v-text="$t('label.' + key)"/>
          <td
            v-if="key === 'office_id'"
            v-text="getOffice(value)"
          />
          <td
            v-else-if="key === 'country_id'"
            v-text="getCountry(Number(value))"
          />
          <td
            v-else-if="key === 'amount'"
            v-text="value"
          />
          <td
            v-else-if="key === 'valid_until' && !value"
            v-text="$t('terms.indefinite')"
          />
          <td
            v-else-if="key === 'valid_from' || (key === 'valid_until' && value)"
            v-text="createDate(value)"
          />
          <td
            v-else-if="key === 'default_model_text'"
            v-text="$t(setStandardModelText(value))"
          />
          <td
            v-else
            class="table-summary__text"
            v-text="value"
          />
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import { dateFn } from '@theme/utils/datefns';
import { mapState } from 'vuex';
/**
 * Table for Multistep Summary
*/
export default {
  name: 'TableSummary',
  props: {
    /**
    * rows of the table
    */
    rows: {
      type: Object,
      required: true
    },
    clickDownload: {
      type: Function,
      default: null
    }
  },
  computed: {
    tableRow() {
      const row = (this.rows.documents && this.rows.documents.length > 0) ? [...this.rows.documents] : { ...this.rows };

      if (Object.keys(row).find((k) => k === 'standard_model_texts')) delete row.documents;
      if (row.step) delete row.step;

      return row;
    },
    ...mapState({
      userId: state => state.user.user.id
    })
  },
  methods: {
    /**
     * Returns a formatted date.
     * @param {Date} date
     */
    createDate(date) {
      return date ? dateFn(date) : '';
    },
    /**
     * current office
     * @param {Number} id
     */
    getOffice(id) {
      const office = this.$store.getters['offices/currentOffice'](id);

      return office ? office.label : '';
    },
    /**
     * returns the country with given id
     * @param {Number} id
     */
    getCountry(id) {
      const country = this.$store.getters['guarantee/countries/currentCountry'](id);

      return country ? country.label : '';
    },
    /**
     * set Current Number - to use as index
     * @param {Number} index
     * @param {Number} key
    */
    setNr(index, key) {
      return (typeof (index) !== 'undefined') ? index + 1 : key + 1;
    },
    /**
     * set Standard Model Texts
     * @param {String} hasText
    */
    setStandardModelText(hasText) {
      return (hasText) ? 'file_upload.standard_model_text_in_use' : 'file_upload.standard_model_text_not_in_use';
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './TableSummary';
</style>
