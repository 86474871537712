<template>
  <div
    v-if="detail"
    class="multistep__letter"
  >
    <div class="multistep__letter-original">
      <h4
        class="multistep__letter-original--title"
        v-text="$t('headline.send_letter_original')"
      />
      <radio-buttons
        :items="originalLetterItems"
        :value="detail.original_letter"
        name="radioButtonsOriginalLetter"
        class="multistep__letter-original--radio-buttons"
        data-testing="radio_original_letter"
      />
      <transition name="fade">
        <form-textarea
          v-if="detail.original_letter === 'address'"
          id="original_letter"
          :value="detail.original_letter_address_text"
          :placeholder="$t('placeholder.enter_address')"
          disabled
          data-testing="textarea_original_letter_address"
        />
      </transition>
    </div>
    <div class="multistep__letter-copy">
      <h4
        class="multistep__letter-copy--title"
        v-text="$t('headline.send_letter_copy')"
      />
      <form-checkbox
        id="copy_original_letter_beneficiary"
        :value="detail.copy_original_letter_beneficiary"
        class="multistep__letter-copy--checkbox"
        disabled
        data-testing="checkbox_letter_copy"
      >
        <span v-text="$t('label.to_beneficiary')" />
      </form-checkbox>
      <form-checkbox
        id="copy_original_letter_customer"
        :value="detail.copy_original_letter_customer"
        class="multistep__letter-copy--checkbox"
        disabled
        data-testing="checkbox_letter_copy_to_beneficiary"
      >
        <span v-text="$t('label.to_customer')" />
      </form-checkbox>
      <form-checkbox
        id="copy_original_letter_address"
        :value="detail.copy_original_letter_address"
        class="multistep__letter-copy--checkbox"
        disabled
        data-testing="checkbox_letter_copy_to_customer"
      >
        <span v-text="$t('label.to_address')" />
      </form-checkbox>
      <transition name="fade">
        <form-textarea
          v-if="detail.copy_original_letter_address"
          id="copy_original_letter_address_text"
          :value="detail.copy_original_letter_address_text"
          :placeholder="$t('placeholder.enter_address')"
          disabled
          data-testing="textarea_letter_copy_to_address"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import RadioButtons from '@components/RadioButtons/RadioButtons';
import FormCheckbox from '@components/FormCheckbox/FormCheckbox';
import FormTextarea from '@components/FormTextarea/FormTextarea';

export default {
  name: 'SummaryAdditional',
  components: {
    RadioButtons,
    FormCheckbox,
    FormTextarea
  },
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      originalLetterItems: [
        { label: this.$t('label.to_beneficiary'), id: 'beneficiary', disabled: true },
        { label: this.$t('label.to_customer'), id: 'customer', disabled: true },
        { label: this.$t('label.to_address'), id: 'address', disabled: true }
      ]
    };
  }
};
</script>
