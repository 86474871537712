import api from '@core/api/';
import router from '@core/router/index';
import cookies from '@theme/utils/cookies';
import { setI18nLanguage, validCurrentLocale, fallbackLocale, allLocales } from '@core/plugins/i18n';
import { set, toggle } from '@core/store/utils';
import { getItemLocalStorage } from '@theme/utils/localstorage';

const status = ['loggedout', 'loggedin', 'error', 'expired'];

const state = {
  isLoading: false,
  user: {},
  redirected: null,
  accessToken: cookies.getCookie('access_token') || '',
  refreshToken: cookies.getCookie('refresh_token') || '',
  status: {
    type: cookies.hasCookie('access_token') ? status[1] : status[0],
    msg: 'default'
  },
  locales: allLocales,
  currentLocale: validCurrentLocale,
  errors: {}
};

const getters = {
  isAuthenticated: state => !!state.accessToken,
  getError: state => state.status.type === status[2] && state.accessToken === '' ? state.status : null,
  getPermissions: state => state.user && state.user.permissions ? state.user.permissions.map(p => p.name) : null,
  getResetPasswordErrors: state => {
    const errors = state.errors;
    const fields = ['current_password', 'password', 'password_confirmation'];
    return Object.keys(errors)
      .filter(field => fields.indexOf(field) !== -1)
      .reduce((newErrors, key) => {
        newErrors[key] = errors[key];
        return newErrors;
      }, {});
  },
  getGeneralInfoErrors: state => {
    const errors = state.errors;
    const fields = ['first_name', 'last_name', 'phone', 'email', 'mobile'];
    return Object.keys(errors)
      .filter(field => fields.indexOf(field) !== -1)
      .reduce((newErrors, key) => {
        newErrors[key] = errors[key];
        return newErrors;
      }, {});
  }
};

const actions = {
  /**
   * post user login data to API
   * @method postLogin
   */
  postLogin({ commit, dispatch }, loginData) {
    const payload = {
      ...loginData
    };

    commit('toggleLoading');

    return api.getApiData('users/current').then(() => {
      router.replace({
        name: 'myguarantees'
      });

      commit('toggleLoading');
    }).catch(error => {
      if (process.env.NODE_ENV === 'development') console.error(error);
      return api.postApiData('/login', payload).then(({ data }) => {
        const currentToken = cookies.getCookie('access_token');

        if (!currentToken || currentToken !== data.access_token) {
          commit('setAccessToken', data.access_token);
          commit('setRefreshToken', data.refresh_token);
          const refreshTokenAttr = {
            expires: data.expires_in,
            secure: true,
            samesite: 'strict '
          };
          const accessTokenAttr = Object.assign({}, refreshTokenAttr);
          cookies.setCookie('refresh_token', data.refresh_token, refreshTokenAttr);
          cookies.setCookie('access_token', data.access_token, accessTokenAttr);
        }

        commit('setStatus', { type: status[1], msg: 'default' });

        dispatch('getUser').then(() => dispatch('guarantee/create/setUserCred', null, { root: true }));

        const url = new URL(window.location.href);
        const redirectTo = url.searchParams.get('redirected');

        if (redirectTo) {
          router.push({ path: redirectTo });
        } else {
          router.replace({
            name: 'myguarantees'
          });
        }

        commit('toggleLoading');
      }).catch(error => {
        cookies.removeCookie('access_token');
        cookies.removeCookie('refresh_token');

        let errorMsg = '';

        if (error?.response?.status === 401) {
          errorMsg = 'invalid_credentials';
        } else {
          errorMsg = error?.response?.data?.error ? error.response.data.error : 'default';
        }

        commit('setAccessToken', '');
        commit('setStatus', {
          type: status[2],
          msg: errorMsg
        });

        commit('toggleLoading');

        api.apiError(error);
      });
    });
  },

  /**
   * POST request to reset password.
   * @method resetPassword
   */
  resetPassword({ commit }, data) {
    commit('toggleLoading');

    return new Promise((resolve, reject) => {
      api.postApiData('/password/reset', data).then(() => {
        commit('toggleLoading');
        resolve();
      }).catch(error => {
        commit('setStatus', {
          type: status[2],
          msg: 'default'
        });
        api.apiError(error);
        reject();
      });
    });
  },

  /**
   * log user out
   * @method logout
   */
  logout({ state, commit }) {
    const payload = {
      locale: state.user.locale
    };

    commit('toggleLoading');

    return api.postApiData('/logout', payload).then(() => {
      cookies.removeCookie('access_token');
      cookies.removeCookie('refresh_token');

      commit('setAccessToken', '');
      commit('setRefreshToken', '');
      commit('setStatus', { type: status[0], msg: 'default' });

      router.replace({
        name: 'login'
      });

      window.location.reload();
    }).catch(error => {
      commit('toggleLoading');
      api.apiError(error);
    });
  },

  /**
   * Update current logged in user
   * @method update
   */
  update({ commit, state }, data) {
    commit('toggleLoading');
    return new Promise((resolve, reject) => {
      api.patchApiData('/profile', data).then(() => {
        const user = { ...state.user, ...{ first_name: data.first_name, last_name: data.last_name, full_name: `${data.first_name} ${data.last_name}` } };
        commit('setUser', user);
        commit('clearErrors');
        commit('toggleLoading');

        resolve();
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          commit('setErrors', error.response.data.errors);
        }

        commit('toggleLoading');
        reject(error);
      });
    });
  },

  /**
   * Update password
   * @method updatePassword
   */
  updatePassword({ commit }, data) {
    commit('toggleLoading');

    return new Promise((resolve, reject) => {
      api.patchApiData('/profile/password', data).then(() => {
        commit('clearErrors');
        commit('toggleLoading');

        resolve();
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          commit('setErrors', error.response.data.errors);
        }

        commit('toggleLoading');
        reject(error);
      });
    });
  },

  /**
  * get data from api
  * @method getUser
  */
  getUser({ commit, dispatch }) {
    commit('toggleLoading');
    return api.getApiData('users/current').then(({ data }) => {
      commit('setUser', data.data);

      if (!getItemLocalStorage('currentLocale') && data.data.locale != null) dispatch('setLocale', data.data.locale);

      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      cookies.removeCookie('access_token');
      cookies.removeCookie('refresh_token');

      commit('toggleLoading');
      commit('setAccessToken', '');
      commit('setRefreshToken', '');

      router.replace({
        name: 'login'
      });
    });
  },

  /**
  * Sets the locale in store
  * @method setLocale
  */
  setLocale({ commit }, lang) {
    const locale = lang || fallbackLocale;
    commit('setCurrentLocale', locale);
    setI18nLanguage(lang);
  },

  /**
   * Confirms the invitation
   * @method confirmInvitation
   */
  confirmInvitation({ commit }, { password, repeatPassword, route }) {
    const payload = {
      password: password,
      password_confirmation: repeatPassword
    };

    return new Promise((resolve) => {
      api.postApiData(route, payload).then(() => {
        resolve();
      }).catch((response) => {
        commit('setStatus', {
          type: status[2],
          msg: 'default'
        });

        api.apiError(response);
      });
    });
  },

  /**
    * Send resetting password link
    * @method sendResetPasswordLink
  */
  sendResetPasswordLink({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.postApiData('password/email', payload).then(() => {
        commit('setStatus', {
          type: status[0],
          msg: 'default'
        });

        resolve();
      }).catch((error) => {
        commit('setStatus', {
          type: status[2],
          msg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'default'
        });

        reject();
      });
    });
  },

  /**
   * Checks if the invitation is expired (expires after 2 days)
   * @method redirectIfInvitationExpired
   */
  redirectIfInvitationExpired({ commit }, { route }) {
    return api.getApiData(route).then(({ data }) => {
      if (data.status === 403) {
        router.replace({
          name: 'login',
          params: {
            redirectedFromExpiredInvitation: true
          }
        });
      }
    }).catch((error) => {
      api.apiError(error);

      router.replace({
        name: 'login',
        params: {
          redirectedFromExpiredInvitation: true
        }
      });
    });
  }
};

const mutations = {
  setUser: set('user'),
  setRedirected: set('redirected'),
  setCurrentLocale: set('currentLocale'),
  setAccessToken: set('accessToken'),
  setRefreshToken: set('refreshToken'),
  setStatus: set('status'),
  toggleLoading: toggle('isLoading'),
  setErrors: (state, value) => state.errors = { ...state.errors, ...value },
  clearErrors: state => state.errors = {}
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
