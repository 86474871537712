<template>
  <header class="content-header">
    <div class="content-header__container container-fluid">
      <h1
        v-if="type === 'h1'"
        class="content-header__title"
      >
        <!-- @slot default slot for text -->
        <slot />
      </h1>

      <h2
        v-else
        class="content-header__title"
      >
        <!-- @slot slot for the title -->
        <slot />
      </h2>

      <!-- @slot numbers -->
      <slot name="numbers" />

      <!-- @slot button -->
      <slot name="buttons" />

    </div>
  </header>
</template>

<script>

export default {
  name: 'ContentHeader',
  props: {
    type: {
      type: String,
      default: 'h1'
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './ContentHeader';
</style>
