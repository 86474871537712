<template>
  <b-form
    novalidate
    autocomplete="off"
    class="filters"
  >
    <ul
      :class="['filters__list', listClass]"
    >
      <li
        v-for="(id, index) in items"
        :key="index"
        :class="['filters__block', itemClass]"
      >
        <slot :name="id"/>
      </li>
    </ul>
    <div class="filters__reset">
      <btn
        transparent
        variant="link"
        class="filters__reset-button"
        @click="onClickReset"
        v-text="$t('button.reset')"
      />
    </div>
  </b-form>
</template>

<script>
import { BForm } from 'bootstrap-vue';

export default {
  name: 'FilterList',
  components: {
    'b-form': BForm
  },
  props: {
    /**
       * Array of filters
       */
    items: {
      type: Array,
      default: () => []
    },
    /**
       * Class for list tag
       */
    listClass: {
      type: String,
      default: ''
    },
    /**
       * Class for item tag
       */
    itemClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClickReset() {
      /**
       * Emits the reset event
       * @event reset
       */
      this.$emit('reset');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './FilterList';
</style>
