<template>
  <b-dropdown
    ref="actionsDropdown"
    :right="right"
    block
    menu-class="actions-dropdown__menu"
    class="actions-dropdown"
  >
    <template slot="button-content">
      <!-- @slot button content -->
      <slot />
    </template>
    <ul class="actions-dropdown__list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="actions-dropdown__list-item"
      >
        <b-link
          v-if="item.type === 'link' && !(item.label === 'button.edit' && disableEditButton)"
          :href="item.action"
          :class="itemClass(item.label)"
          class="actions-dropdown__item"
        >{{ $t(item.label) }}</b-link>

        <btn
          v-if="item.type === 'button' && !(item.label === 'button.edit' && disableEditButton)"
          :class="itemClass(item.label)"
          class="actions-dropdown__item"
          @click.stop="onClickAction(item.action, item.value)"
        >{{ $t(item.label) }}</btn>
      </li>
    </ul>
  </b-dropdown>
</template>

<script>
import { BDropdown, BLink } from 'bootstrap-vue';

/**
 * Dropdown with Actions
 */
export default {
  name: 'ActionsDropdown',
  components: {
    'b-dropdown': BDropdown,
    'b-link': BLink
  },
  props: {
    /**
     * dropdown items
    */
    items: {
      type: Array,
      required: true
    },
    /**
     * position of the Dropdown Menu
    */
    right: {
      type: Boolean,
      default: false
    },

    /**
     * disable the editButton e.g. when state == states.approval_pending
    */
    disableEditButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Return item className
     * @method itemClass
     * @param {string} item
    */
    itemClass(item) {
      const className = item.split('.')[1].replace('_', '-');

      return (!className) ? false : 'actions-dropdown__item--' + className;
    },
    /**
     * Emit Action
     * @method onClickAction
     * @param {string} id
    */
    onClickAction(action, value) {
      if (action) {
        // Needed, as actions in the store are in camel-case and actions are delivered in kebap case
        const actionCamelCased = action.replace(/-([a-z])/g, (g) => { return g[1].toUpperCase(); });
        this.$store.dispatch(actionCamelCased, value);
      }

      this.$refs.actionsDropdown.hide();
    }
  }
};

</script>

<style lang="scss">
  @import './ActionsDropdown';
</style>
