
import contentTemplate from '@theme/store/modules/content';
import navigation from '@theme/store/modules/navigation';
import guarantee from '@theme/store/modules/guarantee/';
import offices from '@theme/store/modules/offices';
import user from '@theme/store/modules/user';
import enums from '@theme/store/modules/enums';
import footer from '@theme/store/modules/footer';
import faqs from '@theme/store/modules/faqs';
import employees from '@theme/store/modules/employees';
import history from '@theme/store/modules/history';

const store = {
  modules: {
    contentTemplate,
    navigation,
    guarantee,
    employees,
    offices,
    user,
    enums,
    footer,
    faqs,
    history
  }
};

if (module.hot) {
  module.hot.accept([
    './modules/content',
    './modules/guarantee/index',
    './modules/offices',
    './modules/user',
    './modules/employees',
    './modules/enums'
  ], () => {
    store.hotUpdate({
      modules: {
        contentTemplate: require('./modules/content').default,
        createGuarantee: require('./modules/guarantee/index').default,
        offices: require('./modules/offices').default,
        user: require('./modules/user').default,
        employees: require('./modules/employees').default,
        enums: require('./modules/enums').default
      }
    });
  });
}

export { store };
