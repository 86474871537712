<template>
  <template-default>
    <article class="template template--bg-gray template--navbar-fixed">
      <content-header
        slot="preview"
        class="content-header--bg"
        type="h1"
      >
        {{ $t('headline.faqs') }}
      </content-header>
      <div class="content content--margin container-fluid">
        <collapse
          v-for="(item, key) in items"
          :key="key"
          :id="item.id"
          :title="item.title"
        >
          <div
            slot="content"
            v-html="item.body"
          />
        </collapse>
      </div>
    </article>
  </template-default>
</template>

<script>
import { mapState } from 'vuex';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import Collapse from '@components/Collapse/Collapse';

export default {
  name: 'Documents',
  components: {
    TemplateDefault,
    ContentHeader,
    Collapse
  },
  computed: {
    ...mapState({
      items: state => state.faqs.items
    })
  },
  mounted() {
    this.$store.dispatch('faqs/getFaqs');
  }
};
</script>
