import Vue from 'vue';
import Vuex from 'vuex';

// Custom Theme
import { store as StoreConfigTheme } from '@theme/store/';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ...StoreConfigTheme.modules
  },
  strict: process.env.NODE_ENV !== 'production'
});
