<template>
  <fieldset class="multistep--guarantee">
    <legend class="multistep__legend">
      <!-- @slot current step title -->
      <slot />
    </legend>

    <template>
      <div class="multistep__row row">
        <form-input
          :id="activeFieldsKey[0]"
          :value="activeFields[activeFieldsKey[0]]"
          :label="$t('label.purpose')"
          :placeholder="$t('placeholder.enter_purpose')"
          type="text"
          validation="required"
          class="multistep__col col-12"
          data-testing="input_purpose"
          @onchange="onChangeField"
        />
      </div>
      <div class="multistep__row row">
        <div class="multistep__col col-12 col-md-3">
          <form-selectbox
            v-if="currencyItems && currencyItems.length > 0"
            :id="activeFieldsKey[2]"
            :value="parseInt(activeFields[activeFieldsKey[2]])"
            :options="currencyItems"
            :label="$t('label.currency')"
            :placeholder="$t('placeholder.enter_select')"
            searchable
            validation="required"
            data-testing="select_currency"
            @onchange="onChangeField"
          />
        </div>
        <div class="multistep__col col-12 col-md-3">
          <form-amount
            :id="activeFieldsKey[1]"
            :value="Number(activeFields[activeFieldsKey[1]])"
            :label="$t('label.amount')"
            :placeholder="$t('placeholder.enter_amount')"
            type="amount"
            validation="required|amount"
            data-testing="input_amount"
            @onchange="onChangeField"
          />
        </div>
        <div class="multistep__col col-12 col-md-3">
          <form-date-picker
            ref="validFrom"
            :id="activeFieldsKey[3]"
            :value="validFrom"
            :label="$t('label.runtime_from')"
            :placeholder="$t('placeholder.enter_from')"
            is-create-date-picker
            required
            data-testing="date_valid_from"
            @open="onValidFromOpen"
            @change="onValidFromChanged"
          />
        </div>
        <div class="multistep__col col-12 col-md-3">
          <form-date-picker
            ref="validUntil"
            :id="activeFieldsKey[4]"
            :value="validUntil"
            :valid-from="validFrom"
            :label="$t('label.runtime_until')"
            :placeholder="$t('placeholder.enter_until')"
            is-create-date-picker
            variant="indefinite"
            data-testing="date_valid_until"
            @open="onValidUntilOpen"
            @change="onValidUntilChanged"
          />
        </div>
      </div>

      <div class="multistep__row row">
        <form-selectbox
          v-if="productItems && productItems.length > 0"
          :id="activeFieldsKey[5]"
          :value="selectedProduct"
          :options="productItems"
          :label="$t('label.product_id')"
          :placeholder="$t('placeholder.enter_product_id')"
          searchable
          validation="required"
          class="multistep__col col-12"
          data-testing="select_product"
          @onchange="onChangeField"
        />
      </div>
    </template>
  </fieldset>
</template>

<script>
import { dateCreate } from '@theme/utils/datefns';
import FormInput from '@components/FormInput/FormInput';
import FormAmount from '@components/FormAmount/FormAmount';
import FormSelectbox from '@components/FormSelectbox/FormSelectbox';
import FormDatePicker from '@components/FormDatePicker/FormDatePicker';
import { mapGetters } from 'vuex';

export default {
  name: 'MultistepGuarantee',
  components: {
    FormInput,
    FormAmount,
    FormSelectbox,
    FormDatePicker
  },
  data() {
    return {
      selectedProduct: null
    };
  },
  computed: {
    ...mapGetters({
      activeFieldsKey: 'guarantee/create/activeFieldsKey',
      activeFields: 'guarantee/create/activeFields',
      officeId: 'guarantee/create/officeId',
      isLoadingFields: 'guarantee/create/isLoadingFields',
      currencyItems: 'enums/currencies/getItems',
      productItems: 'enums/products/getItems'
    }),
    products() {
      return this.productItems;
    },
    validFrom() {
      if (this.activeFields[this.activeFieldsKey[3]] && this.activeFields[this.activeFieldsKey[3]] !== '') {
        return dateCreate(this.activeFields[this.activeFieldsKey[3]]);
      }

      return null;
    },
    validUntil() {
      if (this.activeFields[this.activeFieldsKey[4]] && this.activeFields[this.activeFieldsKey[4]] !== '') {
        return dateCreate(this.activeFields[this.activeFieldsKey[4]]);
      }

      return null;
    }
  },
  watch: {
    products(newVal) {
      this.selectedProduct = parseInt(this.activeFields[this.activeFieldsKey[5]]);
    }
  },
  mounted() {
    this.$store.dispatch('guarantee/create/getData').then(function(responseData) {
      this.selectedProduct = parseInt(responseData.data.product_id);
      this.$store.commit('enums/products/setOfficeId', this.officeId);
      this.$store.dispatch('enums/currencies/getCurrencies');
      this.$store.dispatch('enums/products/getProducts');
      this.$store.dispatch('guarantee/create/adjustFormNavigation');
    }.bind(this));
  },
  methods: {
    /**
    * change Input Field Value in Store (Model)
    * @event onChangeField
    * @type {event}
    */
    onChangeField(param) {
      this.$store.dispatch('guarantee/create/setField', param);
    },

    onDateRangePickerChanged(startDate, endDate) {
      if (startDate) {
        const params = {
          key: 'valid_from',
          val: startDate
        };

        this.$store.dispatch('guarantee/create/setField', params);
      }

      const endDateStore = endDate || '';

      const params = {
        key: 'valid_until',
        val: endDateStore
      };

      this.$store.dispatch('guarantee/create/setField', params);
    },

    onValidFromOpen() {
      this.$refs.validUntil.close();
    },

    onValidUntilOpen() {
      this.$refs.validFrom.close();
    },

    onValidFromChanged(value) {
      const param = {
        key: 'valid_from',
        val: value || ''
      };

      this.$store.dispatch('guarantee/create/setField', param);
    },

    onValidUntilChanged(value) {
      const param = {
        key: 'valid_until',
        val: value && value !== this.$t('terms.indefinite') ? value : ''
      };

      this.$store.dispatch('guarantee/create/setField', param);
    }
  }
};
</script>
