import api from '@core/api/';
import { set, toggle } from '@core/store/utils';

const state = {
  isLoading: false,
  beneficiariesFilter: null,
  data: []
};

const getters = {
  isLoading: state => state.isLoading,
  beneficiaries: (state, getters, rootState) => (state.data && state.data.length > 0)
    ? state.data.map((o) => {
      let label = (typeof (o.id) === 'undefined' || o.id < 0) ? o.company_name : `<b>${o.company_name} </b>`;
      const country = rootState.guarantee.countries.list.find(item => item.id === o.country_id);

      if (o.street || o.zip_code || o.city) label += '<small>';
      if (o.street) label += `, ${o.street}`;
      if (o.zip_code) label += `, ${o.zip_code}`;
      if (o.city) label += ` ${o.city}`;
      if (country) label += `, ${country.label}`;

      if (o.street || o.zip_code || o.city || o.country_id) label += '</small>';

      return {
        label: label,
        value: o.company_name,
        id: o.id
      };
    })
    : [],
  activeBeneficiary: state => (id) => state.data.find(o => o.id === id) || {}
};

const actions = {
  /**
   * send query to API
   * @method sendQuery
   */
  sendQuery({ commit, state, rootState }, { searchQuery, onlyOwn }) {
    if (searchQuery.length < 3) return;

    commit('toggleLoading');

    const query = {
      params: {
        company_name: searchQuery
      }
    };

    api.getApiData('accounts/' + rootState.user.user.account.id + '/beneficiaries', query).then(({ data: responseData }) => {
      let beneficiaries = responseData.data;

      // on the guarantees overview, regular employees
      // don't have autocomplete for other user's beneficiaries
      // except for supervisors. They will see all users.
      // only use use beneficiaries from guarantees state
      // & throw out all others
      if (beneficiaries.length &&
        state.beneficiariesFilter &&
        onlyOwn &&
        rootState.user.user.role !== 'supervisor') {
        beneficiaries = beneficiaries.filter((beneficiary) => {
          return state.beneficiariesFilter.includes(beneficiary.id.toString());
        });
      }

      commit('setData', beneficiaries);
      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      commit('toggleLoading');
    });
  }
};

const mutations = {
  setData: set('data'),
  setBeneficiariesFilter: set('beneficiariesFilter'),
  toggleLoading: toggle('isLoading'),
  clear: state => state.data = []
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
