<template>
  <header
    role="header"
    class="header"
  >
    <b-navbar
      :class="{ expanded: showMobileMenu }"
      class="container-fluid"
      fixed="top"
      type="dark"
    >
      <icon
        v-if="$responsive.lg"
        :icon="iconMobileMenu"
        :size="iconMobileMenuSize"
        color="white"
        color-hover="grey"
        emits-click-event
        class="navbar__mobile-menu-toggle"
        @click="toggleMobileMenu"
      />
      <div
        v-if="$responsive.lg"
        ref="mobileMenu"
        class="navbar__mobile-menu"
      >
        <ul
          v-if="showMobileMenu"
          class="navbar__items-mobile"
        >
          <b-nav-item
            v-for="(item, index) in navigation"
            :key="index"
            :to="item.link"
            exact
            class="navbar__item-mobile"
            @click="onClickMobileMenuItem"
          >
            {{ $t(item.title) }}
          </b-nav-item>
        </ul>

        <div
          v-if="$responsive.md && showMobileMenu"
          class="navbar__create-guarantee-mobile"
        >
          <btn
            variant="primary"
            @click="onClickGuarantee"
            v-text="$t('button.create_guarantee')"
          />
        </div>
      </div>

      <user-preview
        :action-items="userActions"
        :user="user"
        class="navbar__user-preview"
        data-testing="select_user_preview"
        @logout="onLogout"
        @employees="onEmployees"
        @profile="onProfile"
      />

      <language-switcher
        :locales="locales"
        :current="currentLocale"
        class="navbar__lang-switcher"
        data-testing="select_frontend_language"
        @change="onChangeLocale"
      />

      <div
        v-if="!$responsive.sm"
        class="navbar__create-guarantee"
      >
        <btn
          variant="primary"
          data-testing="button_navbar_create_guarantee"
          @click="onClickGuarantee"
          v-text="$t('headline.create_guarantee')"
        />
      </div>

      <ul
        v-if="!$responsive.lg"
        class="navbar__items"
        data-testing="list_navbar_menu_navigation"
      >
        <b-nav-item
          v-for="(item, index) in navigation"
          :key="index"
          :to="item.link"
          class="navbar__item"
          exact
        >
          {{ $t(item.title) }}
        </b-nav-item>
      </ul>

      <b-navbar-brand
        to="/"
        class="navbar__brand"
      >
        <img
          src="@img/logo.svg"
          alt="BTV Vier Länder Bank"
          width="112"
          height="28"
        >
      </b-navbar-brand>
    </b-navbar>
  </header>
</template>

<script>
import { BNavbar, BNavbarBrand } from 'bootstrap-vue';

import LanguageSwitcher from '@components/LanguageSwitcher/LanguageSwitcher';
import UserPreview from '@components/UserPreview/UserPreview';
import Icon from '@atoms/Icon/Icon';
import { mapState } from 'vuex';

export default {
  name: 'Navbar',
  components: {
    'b-navbar': BNavbar,
    'b-navbar-brand': BNavbarBrand,
    LanguageSwitcher,
    UserPreview,
    Icon
  },
  data() {
    return {
      showMobileMenu: false,
      iconMobileMenu: 'burger',
      iconMobileMenuSize: '32'
    };
  },
  computed: {
    ...mapState({
      locales: state => state.user.locales,
      currentLocale: state => state.user.currentLocale,
      navigation: state => state.navigation.items,
      user: state => state.user.user
    }),

    userActions() {
      let actions = [
        {
          label: 'label.profile',
          action: 'profile'
        },
        {
          label: 'label.logout',
          action: 'logout'
        }
      ];

      if (this.user.role === 'supervisor') {
        const supervisorUserActions = [
          {
            label: 'label.employees',
            action: 'employees'
          }
        ];
        actions = [...supervisorUserActions, ...actions];
      }

      return actions;
    }
  },
  methods: {
    /**
     * link to guarantee formular
     * @event click
     * @type {event}
     */
    onClickGuarantee(event) {
      this.$router.push({
        name: 'guaranteeId',
        params: {
          id: 'new',
          step: 'step-1'
        }
      });

      this.$store.dispatch('guarantee/create/resetData');

      this.showMobileMenu = false;
      document.body.classList.remove('hide-scroll');
    },

    /**
     * Triggers the menu toggle.
     *
     * @event click
     * @type {event}
     */
    onClickMobileMenuItem() {
      this.toggleMobileMenu();
    },

    /**
     * Triggers the logout
     * @event onLogout
     * @type {Logout}
     */
    onLogout() {
      this.$store.dispatch('user/logout');
    },

    /**
     * Triggers event that load employees
     * @event onLogout
     * @type {Employees}
     */
    onEmployees() {
      this.$router.push({
        name: 'employees'
      });
    },

    /**
     * Triggers event that load employees
     * @event onpProfile
     * @type {click}
     */
    onProfile() {
      this.$router.push({
        name: 'profile'
      });
    },

    /**
     * Toggles the menu and changes the burger-menu icon.
     *
     * @event click
     * @type {event}
     */
    toggleMobileMenu() {
      if (this.showMobileMenu) {
        this.iconMobileMenu = 'burger';
        this.iconMobileMenuSize = '32';
        this.$refs.mobileMenu.classList.add('fading-out');
        document.body.classList.remove('hide-scroll');

        // Timeout has to correspond with the animation duration specified in _animations.scss
        setTimeout(() => {
          this.showMobileMenu = false;
        }, 100);
      } else {
        this.iconMobileMenu = 'close';
        this.iconMobileMenuSize = '25';
        this.showMobileMenu = true;
        document.body.classList.add('hide-scroll');
      }
    },

    /**
     * Listen changing of locale
     *
     * @event onChangeLocale
     * @type {click}
     */
    onChangeLocale(value) {
      this.$store.dispatch('user/setLocale', value).then(() => {
        this.$router.go();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./Navbar";
</style>
