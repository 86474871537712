const publicRoutes = [
  'login',
  'confirmInvitation',
  'passwordForgotten',
  'resetPassword'
];

export function isPublicRoute(route) {
  return publicRoutes.indexOf(route) !== -1;
}

export function isHttps() {
  return location.protocol === 'https:';
}
