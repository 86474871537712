import throttle from 'lodash/throttle';

export let responsive;

export default {
  install(Vue, options) {
    const finalOptions = Object.assign({}, {
      computed: {}
    }, options);

    responsive = new Vue({
      data() {
        return {
          width: window.innerWidth,
          height: window.innerHeight,
          widthCheck: window.innerWidth < 1024 ? 'tablet' : 'desktop'
        };
      },
      computed: finalOptions.computed
    });

    Object.defineProperty(Vue.prototype, '$responsive', {
      get: () => responsive
    });

    window.addEventListener('resize', throttle(function () {
      // if mobile to desktop
      if (responsive.widthCheck === 'tablet' && window.innerWidth >= 1024 && responsive.width < window.innerWidth) {
        responsive.widthCheck = 'dekstop';
        window.location.reload();
      }
      // if desktop to mobile
      if (responsive.widthCheck === 'desktop' && window.innerWidth < 1024 && responsive.width > window.innerWidth) {
        responsive.widthCheck = 'tablet';
        window.location.reload();
      }

      responsive.width = window.innerWidth;
      responsive.height = window.innerHeight;
    }, 250));
  }
};
