<template>
  <div class="multistep--documents">
    <legend class="multistep__legend">
      <slot />
    </legend>

    <tabbed-pane
      :items="tabbedPaneItems"
      class="multistep__documents">
      <div
        slot="fileUpload"
        class="multistep__file-upload">
        <file-upload
          :access-token="accessToken"
          :upload-url="uploadUrl"
          :errors="uploadErrors"
          collection="documents"
          data-testing="upload_documents"
          @error="onFileUploadError"
          @filesAdded="onFilesAdded"
        >
          <div
            slot="info"
            class="file-upload__info">
            <span v-text="$t('file_upload.validation.max_size')" />
            <span v-text="$t('file_upload.validation.valid_types')" />
          </div>
        </file-upload>
      </div>

      <div
        slot="filesExisting"
        class="multistep__files-existing">
        <table-interactive
          ref="tableInteractive"
          :total-records="existingFilesTotalRecords"
          :columns="existingFilesColumns"
          :rows="existingFilesRows"
          :search-options="existingFilesSearchOptions"
          :mode="null"
          max-height="300px"
          fixed-header
          class="multistep__table-interactive"
          @choose="onExistingFileChosen"
        />
      </div>
    </tabbed-pane>
    <div
      v-show="hasAttachments"
      class="multistep__display-files">
      <h4
        class="multistep__display-files--title"
        v-text="$t('file_upload.attachments')"
      />
      <notification
        v-for="(file, index) in files"
        :key="index"
        :text="file.label"
        :title="formatAttachmentLabel(index + 1)"
        removable
        status="default"
        class="multistep__display-files--file"
        @remove="onFileDelete(file.id, file.hash)"
      />
    </div>
    <div ref="documentUploadPanelEndSpacer"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TabbedPane from '@components/TabbedPane/TabbedPane';
import FileUpload from '@components/FileUpload/FileUpload';
import TableInteractive from '@components/TableInteractive/TableInteractive';
import Notification from '@components/Notification/Notification';

export default {
  name: 'MultistepDocuments',
  components: {
    TabbedPane,
    FileUpload,
    TableInteractive,
    Notification
  },
  data() {
    return {
      tabbedPaneItems: [
        { label: this.$t('file_upload.add_files'), id: 'fileUpload' },
        {
          label: this.$t('file_upload.choose_existing_files'),
          id: 'filesExisting'
        }
      ],
      existingFilesSearchOptions: {
        enabled: true,
        searchFn: this.existingFilesSearchFn,
        placeholder: this.$t('file_upload.search_for_file_name')
      },
      existingFilesColumns: [
        {
          field: 'file_name',
          label: 'file_upload.table.file_name',
          typeDef: Object
        },
        {
          field: 'created_at',
          label: 'file_upload.table.created_at',
          typeDef: Object
        },
        { field: 'choose', label: '', typeDef: Object }
      ],
      uploadErrors: []
    };
  },
  computed: {
    ...mapState({
      accessToken: state => state.user.accessToken,
      guaranteeId: state => state.guarantee.create.guaranteeId,
      isLoadingMedia: state => state.guarantee.media.isLoading,
      isLoadingFields: state => state.guarantee.create.isLoading,
      existingFilesTotalRecords: state =>
        state.guarantee.media.existingDocumentsTotal,
      existingFilesRows: state => state.guarantee.media.existingDocuments,
      files: state => state.guarantee.media.documents,
      mediaErrors: state => state.guarantee.media.errors
    }),

    errors() {
      return this.mediaErrors.concat(this.uploadErrors);
    },

    uploadUrl() {
      return (
        process.env.VUE_APP_API_URL +
        'guarantees/' +
        this.guaranteeId +
        '/media'
      );
    },

    hasAttachments() {
      let hasAttachments = false;

      if (this.files && this.files.length) {
        this.files.forEach(file => {
          if (!file.deleted) {
            hasAttachments = true;
          }
        });
      }

      return hasAttachments;
    },
    isLoading() {
      return this.isLoadingMedia || this.isLoadingFields;
    }
  },
  mounted() {
    this.$store
      .dispatch('guarantee/create/getData')
      .then(() => {
        this.$store.dispatch('guarantee/create/adjustFormNavigation');
      }
      );
  },
  methods: {
    /**
     * Scrolls to the attachments container
     */
    scrollToAttachments() {
      this.$nextTick().then(() => {
        const scrollHeight = this.$refs.documentUploadPanelEndSpacer.getBoundingClientRect().bottom + window.pageYOffset;
        const isIE = window.navigator.userAgent.indexOf('Trident/');

        isIE ? window.scrollTo(0, scrollHeight) : window.scrollTo({ left: 0, top: scrollHeight, behavior: 'smooth' });
      });
    },
    /**
     * Gets called when a file gets added by the FileUpload component.
     * @event onFilesAdded
     * @type {FilesAdded}
     */
    onFilesAdded(items) {
      const text = this.$t('file_upload.successfully_added', { fileName: items[items.length - 1].label });
      this.$store.commit('guarantee/media/addDocuments', { items });
      this.$notify({
        duration: 5000,
        position: 'top',
        width: '100%',
        data: {
          type: 'success'
        },
        text
      });
      this.scrollToAttachments();
    },

    /**
     * Gets called when an error occurs during the file-upload.
     * @event onFileUploadError
     * @type {Error}
     */
    onFileUploadError(error) {
      if (error && error.message && !error.message.errors) {
        const text = this.$t(`file_upload.validation.${error.message}`);
        this.$notify({
          duration: 5000,
          position: 'top',
          width: '100%',
          data: {
            type: 'error'
          },
          text
        });
      }
    },

    /**
     * Gets called when the User clicks on remove on an item from the attached-files list.
     * @event onFileDelete
     * @type {Click}
     */
    onFileDelete(id, hash) {
      const currentFile = this.files.find(item => item.id === id);
      this.$store.dispatch('guarantee/media/deleteDocument', { id, hash });

      const text = this.$t('file_upload.successfully_deleted', { fileName: currentFile.label });
      this.$notify({
        duration: 5000,
        position: 'top',
        width: '100%',
        data: {
          type: 'success'
        },
        text
      });
    },

    /**
     * Gets called when an already uploaded file from the file-list gets selected.
     * @event onExistingFileChosen
     * @type {Choose}
     */
    onExistingFileChosen(file) {
      this.$store.dispatch('guarantee/media/addExistingDocument', { file });
    },

    /**
     * Returns a formatted label for an attachment.
     * @param {Number} nr
     */
    formatAttachmentLabel(nr) {
      return this.$t('file_upload.attachment') + ' ' + nr;
    },

    /**
     * Custom search function for searching only for the file-names.
     * @param {Object} row
     * @param {String} searchTerm
     */
    existingFilesSearchFn(row, col, cellValue, searchTerm) {
      return row.file_name.includes(searchTerm);
    }
  }
};
</script>
