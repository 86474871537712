/**
 * Provides the can and canAny methods globally, to check if the user has specific permissions
 */
export default {
  install(Vue) {
    Vue.prototype.$user = {
      setPermissions: permissions => this.permissions = permissions,

      can: permission => this.permissions ? this.permissions.includes(permission) : false,
      canAny: any => this.permissions ? any.some(p => this.permissions.includes(p)) : false
    };
  }
};
