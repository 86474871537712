<template>
  <template-default>
    <div class="template template--navbar-fixed">
      <content-header
        slot="preview"
        class="content-header--bg"
        type="h1"
      >
        {{ $t('headline.edit_employee') }}
        <div
          slot="buttons"
          class="content-header__buttons"
        >
          <btn
            variant="primary"
            icon="add"
            class="content-header__btn btn--icon"
            data-testing="button_create_employee"
            @click="onClickCreateEmployee"
          >
            {{ $t('button.create_employee') }}
          </btn>
        </div>
      </content-header>
      <employee-form
        :data="employee"
        :api-errors="apiErrors"
        :button-text="$t('button.update')"
        type="edit"
        @submit="onSubmit"
      />
    </div>
  </template-default>
</template>

<script>
import { mapState } from 'vuex';
import TemplateDefault from '@theme/router/views/templates/TemplateDefault';
import ContentHeader from '@components/ContentHeader/ContentHeader';
import privatePageMixin from '@theme/components/mixins/privatePageMixin';
import EmployeeForm from '@theme/components/Employee/EmployeeForm';

export default {
  name: 'Edit',
  components: {
    TemplateDefault,
    ContentHeader,
    EmployeeForm
  },
  mixins: [privatePageMixin],
  computed: {
    ...mapState({
      isLoading: state => state.employees.isLoading,
      user: state => state.user.user,
      apiErrors: state => state.employees.errors,
      employee: state => state.employees.employee
    })
  },
  mounted() {
    this.$store.dispatch('employees/getEmployee', this.$route.params.id);
  },
  methods: {
    /**
       * Create new employee
       * @event onClickCreateEmployee
       * @type {Click}
       */
    onClickCreateEmployee() {
      this.$router.push({
        name: 'newEmployee'
      });
    },

    /**
       * Submit the form
       * @event onSubmit
       * @type {Submit}
       */
    onSubmit(data) {
      this.$store.dispatch('employees/editEmployee', { id: this.$route.params.id, data }).then(() => {
        this.$router.push({
          name: 'employees',
          params: {
            redirectedFrom: 'edit',
            firstName: data.first_name,
            lastName: data.last_name
          }
        });
      });
    }
  }
};
</script>
