import api from '@core/api/';
import { set, toggle } from '@core/store/utils';

const state = {
  isLoading: false,
  items: [],
  errors: [],
  officeId: null
};

const getters = {
  getItems: state => state.items
};

const actions = {
  /**
  * get api data and save it to fields
  * @method getProducts
  */
  getProducts({ commit }) {
    commit('toggleLoading');

    api.getApiData(`products?office_id=${state.officeId}`).then(({ data }) => {
      commit('setProducts', data.data);
      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
    });
  }
};

const mutations = {
  toggleLoading: toggle('isLoading'),
  setProducts: set('items'),
  setOfficeId: set('officeId')
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
