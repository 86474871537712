import currencies from '@theme/store/modules/enums/currencies';
import products from '@theme/store/modules/enums/products';

export default {
  namespaced: true,
  modules: {
    currencies,
    products
  }
};
