/**
  * get item from localeStorage
  * @method getItemLocalStorage
  * @param {string} STORAGE_KEY - param to get data from localStorage
*/
export function getItemLocalStorage(STORAGE_KEY) {
  return window.localStorage.getItem(STORAGE_KEY);
}
/**
  * set item in localeStorage
  * @method setItemLocalStorage
  * @param {string} STORAGE_KEY - param from localStorage
  * @param {string} value - that is set in the localStorage
*/
export function setItemLocalStorage(STORAGE_KEY, value) {
  window.localStorage.setItem(STORAGE_KEY, value);
}
