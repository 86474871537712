<template>
  <b-modal
    ref="modal"
    :id="id"
    :title="$t(title)"
    :ok-title="$t(okTitle)"
    :cancel-title="$t(cancelTitle)"
    :header-bg-variant="type"
    :header-text-variant="type"
    :size="size"
    :ok-disabled="okDisabled"
    :cancel-disabled="cancelDisabled"
    :ok-variant="okVariant"
    :visible="visible"
    :data-testing="dataTesting"
    no-close-on-backdrop
    centered
    title-tag="h3"
    :modal-class="customClass"
    @ok="onOk($event)"
    @cancel="onCancel"
  >
    <global-events
      v-if="isVisible"
      @keyup.enter.capture="onOk($event)"
    />
    <template slot="modal-header">
      <h3
        class="modal-wrapper__title"
        v-text="$t(title)"
      />
      <icon
        icon="close"
        color-hover="grey"
        size="12"
        emits-click-event
        @click="onClose"
      />
    </template>
    <slot />
  </b-modal>
</template>

<script>

import { BModal } from 'bootstrap-vue';
import Icon from '@atoms/Icon/Icon';
import GlobalEvents from 'vue-global-events';

/**
 * Component for displaying and editing a guarantee number.
 */
export default {
  name: 'Modal',
  components: {
    'b-modal': BModal,
    Icon,
    GlobalEvents
  },
  props: {
    /**
     * ID property of the modal
     */
    id: {
      type: String,
      required: true
    },
    /**
     * custom modal class for styling
     */
    modalClass: {
      type: String,
      default: null
    },
    /**
     * Type of modal (e.g. success, error)
     */
    type: {
      type: String,
      default: 'primary'
    },
    /**
     * Size of modal
     */
    size: {
      type: String,
      default: 'md'
    },
    /**
     * Title property of modal
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Label for the OK button
     */
    okTitle: {
      type: String,
      default: 'button.ok'
    },
    /**
     * Label for the cancel button
     */
    cancelTitle: {
      type: String,
      default: 'button.cancel'
    },
    /**
     * Sets the ok button state to disabled
     */
    okDisabled: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the cancel button to disabled
     */
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    /**
     * Type for the OK button
     */
    okVariant: {
      type: String,
      default: 'success'
    },
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * Variable for cypress testing
     */
    dataTesting: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    /**
     * custom modal class for styling, default is modal-wrapper
     */
    customClass() {
      let customClass = 'modal-wrapper';
      if (this.modalClass) customClass += ` ${this.modalClass}`;

      return customClass;
    }
  },
  watch: {
    visible (newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this[newVal ? 'show' : 'hide']();
    }
  },
  mounted() {
    if (this.visible) this.show();
  },
  methods: {
    show() {
      this.$refs.modal.show();
      this.isVisible = true;
    },

    hide() {
      this.$refs.modal.hide();
      this.isVisible = false;
    },

    onOk(event) {
      if (this.isVisible) {
        /**
         * Emits ok event to parent component if modal is visible
         * @event ok
         * @property {object} event
         */
        this.$emit('ok', event);
      }
    },

    onCancel(event) {
      /**
       * Emits cancel event to parent component
       * @event cancel
       * @property {object} event
       */
      this.$emit('cancel', event);
    },

    /**
     * Close modal
     *
     * @event onClose
     * @type {BvEvt}
     */
    onClose(event) {
      this.hide();
      this.$emit('cancel', event);
    }
  }
};

</script>

<style lang="scss">
  @import './Modal';
</style>
