import api from '@core/api/';
import { set, toggle } from '@core/store/utils';

const state = {
  isLoading: false,
  items: {
    referenceNumber: null,
    guaranteeNumber: null
  },
  errors: []
};

const getters = {
  isLoadingFields: state => state.isLoading,
  referenceNumber: state => state.items.referenceNumber ? state.items.referenceNumber : null,
  guaranteeNumber: state => state.items.guaranteeNumber ? state.items.guaranteeNumber : null
};

const actions = {
  /**
   * Gets the guarantee- and reference number of the current guarantee and saves it to the guaranteeNumbers object.
   * @method getGuranteeNumbers
   */
  getReferenceAndGuaranteeNumber({ commit, rootState }, guaranteeId) {
    guaranteeId = guaranteeId || rootState.guarantee.create.guaranteeId;
    if (!guaranteeId) return;

    commit('toggleLoading');

    api.getApiData('guarantees/' + guaranteeId + '/numbers').then(({ data }) => {
      const referenceNr = data.data.reference_number || data.data.guarantee_number || null;

      if (referenceNr) {
        commit('setGuaranteeNumber', {
          key: 'referenceNumber',
          val: referenceNr
        });
      }

      if (data.data.guarantee_number) {
        commit('setGuaranteeNumber', {
          key: 'guaranteeNumber',
          val: data.data.guarantee_number
        });
      }

      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      commit('toggleLoading');
    });
  },

  /**
   * Posts reference number to API, where it is validated and updated. If the format is wrong,
   * API responds with an error and the number value is not updated.
   * @method postReferenceNumber
   */
  postReferenceNumber({ commit, rootState }, value) {
    const requestBody = {
      reference_number: value
    };

    api.postApiData('guarantees/' + rootState.guarantee.create.guaranteeId + '/reference-number', requestBody).then(({ data }) => {
      commit('setGuaranteeNumber', {
        key: 'referenceNumber',
        val: value
      });
    }).catch((error) => {
      if (error.response) {
        const err = [error.response.data.message];
        commit('setErrors', err);
      } else {
        api.apiError(error);
      }
    });
  }
};

const mutations = {
  toggleLoading: toggle('isLoading'),
  setErrors: set('errors'),
  setGuaranteeNumber(state, { key, val }) {
    state.items[key] = val;
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
