import api from '@core/api/';
import { set, toggle } from '@core/store/utils';
import { i18n } from '@core/plugins/i18n/index';
import { dateFn } from '@theme/utils/datefns';

const state = {
  isLoading: false,
  data: []
};

const getters = {
  isLoading: state => state.isLoading,
  guarantees: state => (state.data && state.data.length > 0)
    ? state.data.map((o) => {
      let label = '';
      if (typeof (o.guarantee_number) !== 'undefined') label += `<b>${i18n.t('numbers.guarantee_number')}:</b> ${o.guarantee_number}`;
      if (typeof (o.reference_number) !== 'undefined') label += `, <b>${i18n.t('numbers.reference_number')}:</b> ${o.reference_number}`;
      if (typeof (o.company_name) !== 'undefined') label += `, <b>${i18n.t('label.company_name')}:</b> ${o.company_name}`;
      if (typeof (o.amount) !== 'undefined') label += `, <b>${i18n.t('label.amount')}:</b> ${o.amount}`;
      if (typeof (o.created_at) !== 'undefined') label += `, <b>${i18n.t('label.created_at')}:</b> ${dateFn(o.created_at)}`;

      const obj = {
        label: label,
        value: label,
        id: o.id
      };

      return obj;
    })
    : [],
  activeTemplate: (state, getters) => (id) => getters.guarantees.find(o => o.id === id) || {}
};

const actions = {
  /**
  * send query to API
  * @method sendQuery
  */
  sendQuery({ commit }, searchQuery) {
    if (searchQuery.length < 3) return;

    commit('toggleLoading');

    const requestBody = {
      params: {
        autocomplete: true,
        'filter[search]': searchQuery
      }
    };

    api.getApiData('guarantees', requestBody).then(({ data: responseData }) => {
      if (responseData && responseData.data) {
        commit('setData', responseData.data);
      }

      commit('toggleLoading');
    }).catch((error) => {
      api.apiError(error);
      commit('toggleLoading');
    });
  }
};

const mutations = {
  setData: set('data'),
  toggleLoading: toggle('isLoading')
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
